<template>
<v-container>
   <v-col lg='10'  class=".d-md-none .d-md-flex" >
            <v-card >
              <v-card-text  class="pb-0"  >
               <p class="card-title font-20 text--darken-3">
                  Operators
                </p>
                <v-data-table 
          dense
          :headers="headers"
          :items="operators"
            >
          <template v-slot:body="{ items }">
            <tr v-for="(item, key) in items" :key="key">
              <td align="left">{{ item.name }} </td>
              <td align="left" class="pl-6"> 
                <v-icon v-if="!item.is_Admin"> 
                  check
                </v-icon> 
                <v-icon v-else>
                  close
                </v-icon>
            
                </td>
                 <td align="left" class="pl-12"> 
                <v-icon v-if="item.is_team_leader"> 
                  check
                </v-icon> 
                <v-icon v-else>
                  close
                </v-icon>
            
                </td>
              <td align="left"> {{item.segment.name}} </td>
            
            </tr>
          </template>
        </v-data-table> 
              </v-card-text>
             
            </v-card>
          </v-col>
             <v-col lg='10'  class=".d-md-none .d-md-flex" >
            <v-card >
              <v-card-text style="wihgt:10px" class="pb-0"  >
               <p class="card-title font-20 text--darken-3">
                  Teams
                </p>
                <v-data-table 
                 class=" darken-1 "
          dense
          :headers="headersTeams"
          :items="teams"
            >
          <template v-slot:body="{ items }">
            <tr v-for="(item, key) in items" :key="key">
              <td align="left">{{ item.name }} </td>
              <td align="left"> 
                <v-icon v-if="item.is_active"> check</v-icon> 
               <v-icon v-else>close </v-icon>
              </td>
                 <td align="left"> 
                <v-icon v-if="item.is_main"> check  </v-icon> 
                <v-icon v-else>  close </v-icon>   
                 </td>
        
            </tr>
          </template>
        </v-data-table> 
              </v-card-text>
             
            </v-card>
          </v-col>
</v-container>
    
</template>
<script>
export default {
    data(){
        return{
            operators:[],
            teams:[],
             headers: [
          {
            text: 'Name',
            align: 'left',
           sortable: false,
            value: 'code',
            class: 'tableTitle'
          },
          
          { text: 'Is Admin', value: 'Is_Admin', sortable: false, align: 'left', class: 'tableTitle' },
          { text: 'Is Team Leader', value: 'is_team_leader', sortable: false, align: 'left ', class: 'tableTitle' },
          { text: 'Teams', value: 'Teams', sortable: false, align: 'left ', class: 'tableTitle' }
          
        ],
           headersTeams: [
          {
            text: 'Name',
            align: 'left',
           sortable: false,
            value: 'code',
            class: 'tableTitle'
          },
          
          { text: 'Is Active', value: 'is_active', sortable: false, align: 'left', class: 'tableTitle' },
          { text: 'Is Main', value: 'is_main', sortable: false, align: 'left ', class: 'tableTitle' }
        ],
       }
        
    },
    methods:{
        getOperators(){
              this.$http.get(this.$store.getters.pubsServer + '/api/publishers/operators/', { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
         this.operators=response.body
          console.log(response.body)
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
        })
           
        },
         getTeams(){
              this.$http.get(this.$store.getters.pubsServer + '/api/publishers/teams/', { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
         this.teams=response.body
          console.log(response.body)
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
        })
        //    this.$http.get(this.$store.getters.pubsServer + '/api/publishers/teams/profile/', { headers: {
        //     Authorization: 'Token ' + this.$store.getters.token
        //   }
        // }).then(response => {
       
        //   console.log(response.body)
        // }, error => {
        //   this.isLoading = false
        //   if (error.status === this.unauthorized) {
            
        //   }
        // })
        
           
        }
    

    },
    created(){
        this.getOperators()
        this.getTeams()
    }
    
    
   

}
</script>