<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col
        cols="12"
        sm="7"
        md="5"
        lg="3"
      >
        <v-card class="elevation-10">
          <v-card-text style="padding: 30px;">
            <v-container>
              <v-form @submit.prevent="onSubmit">
                <v-row justify="center">
                  <router-link to="/"><img src="@/assets/oscreens_logo.png" height="40"></router-link>
                </v-row>
                <v-row class="mt-2" justify="center">
                  <div class="text-center">
                    <div style="font-weight: bold; font-size: 20px;">
                      {{ signIn }}
                    </div>
                    <div v-if="!emailInserted">to oScreens</div>
                    <div class="mt-2" v-else>
                      <v-btn color="grey" style="text-transform: none;" outlined rounded block @click="resetEmail">
                        {{ email }}
                        <v-icon right color="grey">arrow-drop-down</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-row>
                <v-row class="mt-2">
                  <v-col cols="12">
                    <v-text-field name="email" label="Email" id="email" type="email" v-model="email" autofocus :rules="[isValid, userExists]" v-if="!emailInserted" required></v-text-field>
                    <v-text-field name="password" label="Enter your password" id="password" type="password" v-model="password" :rules="[isPasswordValid, isPasswordCorrect]" v-else required></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mt-2" justify="center">
                  <v-btn type="submit" color="primary" :loading="loading" :disabled="loading" outlined rounded block>
                    Next
                  </v-btn>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
        email: '',
        password: '',
        pubsServer: null,
        userExistsVar: true,
        emailInserted: false,
        passwordCorrectVar: true,
        isLoading: false
      }
    },
    computed: {
      isValid () {
        return this.email !== '' ? true : 'Email is required'
      },
      isPasswordValid () {
        return this.password !== '' ? true : 'Password is required'
      },
      isPasswordCorrect () {
        return this.passwordCorrectVar ? true : 'Wrong Password. Click Forgot Password to reset'
      },
      userExists () {
        return this.userExistsVar ? true : 'Could not find your oScreens account'
      },
      signIn () {
        if (this.emailInserted) {
          return 'Welcome'
        } else {
          return 'Sign in'
        }
      },
      user_token () {
        return this.$store.getters.token
      },
      loading () {
        return this.isLoading
      }
    },
    watch: {
      user_token (value) {
        this.$router.push('/')
      }
    },
    methods: {
      onSubmit () {
        this.userExistsVar = true
        if (!(this.isValid === true)) {
          return
        }
        if (!this.emailInserted) {
          this.isLoading = true
          this.$http.get(this.gateway + 'api/frontends/user_publishers_server/?email=' + this.email, {headers: this.api_key_header})
            .then(
              (res) => {
                this.isLoading = false
                this.pubsServer = res.data.publishers_server
                if (this.pubsServer == null) {
                  this.userExistsVar = false
                } else {
                  this.emailInserted = true
                }
              }
            )
            .catch((error) => {
              this.isLoading = false
              this.userExistsVar = false
              console.log(error)
            })
        } else {
          this.loginUser()
        }
      },
      loginUser () {
        this.isLoading = true
        this.passwordCorrectVar = true
        this.$http.post(this.pubsServer + '/api/operators/login', {
          email: this.email,
          password: this.password
        }, {headers: this.api_key_header})
          .then(
            (res) => {
              this.isLoading = false
              this.$store.dispatch('onSignIn', res.data)
              this.$store.dispatch('setPubsServer', this.pubsServer)
            }
          )
          .catch((error) => {
            this.passwordCorrectVar = false
            this.isLoading = false
            console.log(error)
          })
      },
      resetEmail () {
        this.emailInserted = false
        this.password = ''
      }
    }
  }
</script>
