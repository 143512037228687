<template>
  <v-content>
    <v-card flat>
      <v-row justify="center">
        <v-col cols="12" class="justify-center">
          <v-layout style="height: 30px;" align-center>
            <v-btn @click="onRemoveClicked(pos)" color="red" fab x-small dark>
              <v-icon>clear</v-icon>
            </v-btn>
            <div class="mt-4 ml-4 font-14"><p>Foreground {{ pos + 1 }}</p></div>
          </v-layout>
        </v-col>
        <v-col cols="12">
          <u-btn class="font-12 bold spacing-02" title="Select Image" mimeType="image/png, image/jpeg" :selectedCallback="imageSelected">
              <span><v-icon>backup</v-icon></span>
              <span class="px-2 btn-act">Choose Color</span>
          </u-btn>

        </v-col>
        <v-col cols="12" class="ml-2 mr-2">
          <v-slider
            label="X"
            dense
            v-model="foreground.foregroundX"
            :max="width"
            :min="0 - width"
            :step="5"
          ></v-slider>
          <v-slider
            label="Y"
            dense
            v-model="foreground.foregroundY"
            :max="height"
            :min="0 - height"
            :step="5"
          ></v-slider>
          <v-slider
            label="Scale"
            dense
            v-model="foreground.foregroundScale"
            :max="3.0"
            :min="0.1"
            :step="0.1"
          ></v-slider>
        </v-col>
      </v-row>
    </v-card>
  </v-content>
</template>

<script>
  export default {
    props: {
      value: Object,
      changedCallback: Function,
      pos: Number,
      height: Number,
      width: Number,
      onRemoveClicked: Function
    },
    computed: {
      foreground: {
        get () {
          return this.value
        },
        set (foreground) {
          this.$emit('input', foreground)
        }
      }
    },
    methods: {
      imageSelected (file) {
        var reader = new FileReader()
        var self = this
        reader.onloadend = function () {
          console.log('Image Readed ' + this.pos)
          var img = new Image()
          img.src = reader.result
          var self2 = self
          img.onload = function () {
            console.log('Image Loaded ' + this.pos)
            self2.foreground = {image: img, foregroundX: self2.width / 2 - img.width / 2, foregroundY: self2.height / 2 - img.height / 2, foregroundScale: 1.0}
          }
        }
        reader.readAsDataURL(file)
      }
    },
    watch: {
      foreground () {
        this.changedCallback()
      }
    }
  }
</script>
