<template>
  <v-content>
    <loading-dialog v-model="isLoading" ></loading-dialog>
    <v-snackbar
      class="white--text"
      :color="snackbarBackground"
      :timeout="3000"
      top
      v-model="snackbar">
      {{ snackbarTxt }}
    </v-snackbar>
    <v-dialog v-model="reportsDialog" max-width="600px">
      <v-card>
        <v-data-table
          dark
          class="grey darken-3"
          :headers="report_headers"
          :items="quote !== null ? quote.quote_report : []">
          <template v-slot:body="{ items }">
            <tr v-for="(item, key) in items" :key="key">
              <td>{{ item.date }}</td>
              <td class="text-center">{{ item.status !== undefined ? getReportStatus(item.status) : 'Unknown' }}</td>
              <td class="text-center">${{ item.rate }}</td>
              <td class="text-center">${{ item.tax !== undefined ? item.tax : ''}}</td>
              <td class="text-center">${{ item.full_rate !== undefined ? item.full_rate : '' }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isOpen" max-width="1000px">
      <v-card class="px-5 py-5">
        <!-- <v-layout class="green darken-2" style="height: 50px;" align-center>
          <span class="title white--text ms-4">Advertise</span>
        </v-layout> -->
        <v-card-title class="justify-center">
          <span class="dialogue-title blue-grey--text text--darken-3 bold">Create New Schedule</span>
        </v-card-title>
        <v-row class="mx-0 my-0">
          <v-col cols="12" md="6">
            <form @submit.prevent="getQuote">
              <v-row class="me-5">
                <!--v-col cols="12" class="py-1 my-1">
                  <v-checkbox dense label="Ensure Continuity" hide-details v-model="on_the_fly_object.ensure_continuity"></v-checkbox>
                </v-col-->
                <!--v-col cols="12" class="py-1 my-1">
                  <v-select label="Unavailable Date Action"
                  hide-details dense
                  v-model="on_the_fly_object.unavailable_date_action" :items="unavailable_date_actions"></v-select>
                </v-col-->
                <v-col cols="12" class="py-1">
                  <v-text-field label="Start Date" 
                  hide-details dense required
                  v-model="on_the_fly_object.reserve_start_date" type="date"></v-text-field>
                </v-col>
                <v-col cols="12" class="py-1 my-1">
                  <v-checkbox label="Use Fixed Number of Days" 
                  hide-details dense
                  v-model="on_the_fly_object.use_fixed_num_days"></v-checkbox>
                </v-col>
                <v-col cols="12" class="py-1 my-1" v-if="on_the_fly_object.use_fixed_num_days">
                  <v-text-field label="Number of Days" 
                  hide-details dense
                  :rules="[rules.minDays(on_the_fly_object.reserve_num_of_days,1), rules.maxDays(on_the_fly_object.reserve_num_of_days,30)]"
                  v-model="on_the_fly_object.reserve_num_of_days" type="number"></v-text-field>
                </v-col>
                <v-col cols="12" class="py-1 my-1">
                   <v-checkbox dense label="Use Max End Date" hide-details v-model="on_the_fly_object.use_max_end_date"></v-checkbox>
                </v-col>
                <v-col cols="12" class="py-1 my-1" v-if="on_the_fly_object.use_max_end_date">
                  <v-text-field label="Max End Date" 
                  hide-details dense
                  v-model="on_the_fly_object.reserve_end_date" type="date"></v-text-field>
                </v-col>
                <v-col cols="12" class="text-center">
                  <v-btn class="white--text dialogue-btn" color="primary" type="submit" rounded>
                    <span class="px-3">Get Quote</span>
                  </v-btn>
                </v-col>
              </v-row>
            </form>
          </v-col>
          <v-col cols="12" md="6" v-if="quote !== null">
            <table class="tableSpacing">
              <tr>
                <td align="right"><div class="titleTextColor">Total Cost:</div></td>
                <td><span class="secondTextColor">${{ quote.total_quote }}</span></td>
              </tr>
              <tr>
                <td align="right"><div class="titleTextColor">Available Balance:</div></td>
                <td><span class="secondTextColor">{{ quote.balance }}</span></td>
              </tr>
              <tr>
                <td align="right"><div class="titleTextColor">Can Execute:</div></td>
                <td><v-icon small class="secondTextColor" v-if="quote.can_execute">done</v-icon>
                <v-icon small class="secondTextColor" v-else>clear</v-icon></td>
              </tr>
              <tr>
                <td align="right"><div class="titleTextColor">Number of Slots:</div></td>
                <td><span class="secondTextColor">{{ quote.num_slots }}</span></td>
              </tr>
              <tr>
                <td align="right"><div class="titleTextColor">Num. of Days:</div></td>
                <td><span class="secondTextColor">{{ quote.num_days }}</span></td>
              </tr>
              <tr>
                <td align="right"><div class="titleTextColor">Start Date:</div></td>
                <td><span class="secondTextColor">{{ quote.quote_report[0].date }}</span></td>
              </tr>
              <tr>
                <td align="right"><div class="titleTextColor">End Date:</div></td>
                <td><span class="secondTextColor">{{ quote.quote_report[quote.quote_report.length-1].date }}</span></td>
              </tr>
            </table>
            <v-layout align-center justify-center>
              <div class="text-xs-center">
                <v-btn class="mt-3" color="secondary" small style="text-transform: none;" @click="showReportsDialog">
                  <v-icon>format_list_bulleted</v-icon>
                  <span class="px-2">Quote Report</span>
                </v-btn>
              </div>
            </v-layout>
            <v-btn class="mt-5" color="primary" type="submit" rounded block @click="executeQuote">
              <v-icon>check</v-icon>
              <span class="px-2 weight-600">Confirm Schedule</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-content>
</template>

<script>
  export default {
    name: 'campaign-wizard',
    props: {
      value: Boolean,
      scope: String,
      campaignHostId: Number,
      reloadCampaign: Function
    },
    data () {
      return {
        isLoading: false,
        snackbar: false,
        snackbarTxt: '',
        snackbarBackground: 'green darken-2',
        on_the_fly_object: {
          ensure_continuity: false,
          unavailable_date_action: 1,
          reserve_start_date: null,
          use_fixed_num_days: false,
          reserve_num_of_days: 1,
          use_max_end_date: false,
          reserve_end_date: null
        },
        quote: null,
        media: [],
        reportsDialog: false,
        rules: {
           minDays(value,min) {
                return (value || "") >= min || `Min number of days is {min}`;
           },
           maxDays(value,max) {
                return (value || "") <= max || `Max number of days is {max}`;
           }
        }
      }
    },
    watch: {
      isOpen (val) {
        if (!val) {
          this.quote = null
          this.on_the_fly_object = {
            ensure_continuity: false,
            unavailable_date_action: 1,
            reserve_start_date: null,
            use_fixed_num_days: false,
            reserve_num_of_days: 1,
            use_max_end_date: false,
            reserve_end_date: null
          }
        }
      }
    },
    computed: {
      isOpen: {
        get () {
          return this.value
        },
        set (isOpen) {
          this.$emit('input', isOpen)
        }
      },
      isLogged () {
        return this.$store.getters.logged_in_account !== null
      },
      campaignsRole () {
        if (this.isLogged) {
          if (this.$store.getters.logged_in_account.user_account.is_admin) {
            return true
          }
          return this.$store.getters.logged_in_account.user_account.config_role
        }
        return ''
      },
      publishersUrl () {
        if (this.isLogged) {
          return this.$store.getters.logged_in_account.server_dns
        }
        return ''
      },
      authToken () {
        if (this.isLogged) {
          return this.$store.getters.logged_in_account.account_token
        }
        return ''
      }
    },
    methods: {
      showReportsDialog () {
        if (this.quote === null) {
          return
        }
        this.reportsDialog = true
      },
      getQuote () {
        var url = this.publishersUrl
        if (this.scope === 'Partners') {
          url += '/api/web_client/partners/campaigns/hosts/schedule_quote/' + this.campaignHostId + '/'
        }
        this.isLoading = true
        this.$http.put(url, this.on_the_fly_object, {
            headers: {
              Authorization: 'Token ' + this.authToken
            }
          })
          .then(
            (res) => {
              this.isLoading = false
              this.quote = res.data
            }
          )
          .catch((error) => {
            this.isLoading = false
            console.log(error)
            this.snackbarBackground = 'red darken-2'
            this.snackbarTxt = this.getErrorFromResponse(error)
            this.snackbar = true
          })
      },
      executeQuote () {
        if (this.quote === null) {
          return
        }
        var url = this.publishersUrl
        if (this.scope === 'Partners') {
          url += '/api/web_client/partners/campaigns/hosts/schedules/'
        }
        this.isLoading = true
        this.$http.post(url, {quote_id: this.quote.quote_id, campaign_host_id: this.campaignHostId}, {
            headers: {
              Authorization: 'Token ' + this.authToken
            }
          })
          .then(
            (res) => {
              this.isLoading = false
              this.reloadCampaign()
              this.isOpen = false
              this.snackbarBackground = 'green darken-2'
              this.snackbarTxt = 'Schedule added successfully'
              this.snackbar = true
            }
          )
          .catch((error) => {
            this.isLoading = false
            console.log(error)
            this.snackbarBackground = 'red darken-2'
            this.snackbarTxt = this.getErrorFromResponse(error)
            this.snackbar = true
          })
      }
    },
    created () {
    }
  }
</script>

<style>
  .tableSpacing{
    border-spacing: 10px 0;
  }

  th.column.text-xs-left.tableTitle{
    color: #2E2E2E;
    font-size: 13px;
    font-weight: bold;
  }

  .singleline{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
</style>
