<template>
  <v-content>
    <loading-dialog v-model="isLoading" ></loading-dialog>
    <v-snackbar
      class="white--text"
      :color="snackbarBackground"
      :timeout="3000"
      top
      v-model="snackbar">
      {{ snackbarTxt }}
    </v-snackbar>
    <v-dialog v-model="isOpen" max-width="1000px">
      <v-card>
        <v-layout class="blue darken-3" style="height: 50px;" align-center>
          <span class="title white--text ms-4">Campaign Creation Wizard</span>
        </v-layout>
        <v-stepper class="mt-4" v-model="step">
          <v-stepper-header>
            <v-stepper-step :complete="step > 1" step="1">Select Media</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="2">Campaign Info</v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card class="grid">
                <!-- <v-card-title>
                  <span class="headline">Select Media</span>
                </v-card-title> -->
                <v-container fluid>
                  <media-row :scope="scope" :mediaSelectedCallback="submitMedia"></media-row>
                </v-container>
              </v-card>
            </v-stepper-content>
            <v-stepper-content step="2">
              <form @submit.prevent="submitCampaign">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="campaign_object.name"
                      label="Campaign Name"
                      required
                      type="text"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                  <v-text-field
                    v-model="campaign_object.hint_start_date"
                    label="Hint Start Date"
                    required
                    type="date"></v-text-field>
                  </v-col>
                  <v-col cols="12" class="text-center">
                    <v-btn class="white--text" color="primary" type="submit" rounded>Confirm</v-btn>
                  </v-col>
                </v-row>
              </form>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
  </v-content>
</template>

<script>
  export default {
    name: 'campaign-wizard',
    props: {
      value: Boolean,
      scope: String
    },
    data () {
      return {
        isLoading: false,
        snackbar: false,
        snackbarTxt: '',
        snackbarBackground: 'red darken-2',
        step: 1,
        campaign_object: {
          media: null,
          name: '',
          hint_start_date: ''
        }
      }
    },
    watch: {
      isOpen (val) {
        if (!val) {
          this.step = 1
          this.campaign_object = {
            media: null,
            name: '',
            hint_start_date: ''
          }
        }
      }
    },
    computed: {
      isOpen: {
        get () {
          return this.value
        },
        set (isOpen) {
          this.$emit('input', isOpen)
        }
      },
      isLogged () {
        return this.$store.getters.logged_in_account !== null
      },
      campaignsRole () {
        if (this.isLogged) {
          if (this.$store.getters.logged_in_account.user_account.is_admin) {
            return true
          }
          return this.$store.getters.logged_in_account.user_account.config_role
        }
        return ''
      },
      publishersUrl () {
        if (this.isLogged) {
          return this.$store.getters.logged_in_account.server_dns
        }
        return ''
      },
      authToken () {
        if (this.isLogged) {
          return this.$store.getters.logged_in_account.account_token
        }
        return ''
      }
    },
    methods: {
      closeDialogs () {
        this.kDialog = false
        this.isOpen = false
        this.$router.push('/premium/control_panel')
      },
      submitMedia (media_id) {
        this.campaign_object.media = media_id
        this.step = 2
      },
      submitCampaign () {
        if (this.campaign_object.name === '' || this.campaign_object.hint_start_date === '') {
          return
        }
        var url = this.publishersUrl
        if (this.scope === 'Partners') {
          url += '/api/web_client/partners/campaigns/'
        }
        this.isLoading = true
        this.$http.post(url, this.campaign_object, {
            headers: {
              Authorization: 'Token ' + this.authToken
            }
          })
          .then(
            (res) => {
              this.isLoading = false
              this.isOpen = false
              this.snackbarBackground = 'green darken-2'
              this.snackbarTxt = 'Camapign created successfully'
              this.snackbar = true
            }
          )
          .catch((error) => {
            this.isLoading = false
            console.log(error)
            this.snackbarBackground = 'red darken-2'
            this.snackbarTxt = this.getErrorFromResponse(error)
            this.snackbar = true
          })
      }
    }
  }
</script>

<style>
  .tableSpacing{
    border-spacing: 10px 0;
  }

  th.column.text-xs-left.tableTitle{
    color: #2E2E2E;
    font-size: 13px;
    font-weight: bold;
  }

  .singleline{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
</style>
