var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-dialog',{attrs:{"persistent":"","max-width":"500px","scrollable":""},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('v-card',[_c('v-card-title',{staticClass:"font-14"},[_vm._v("New User")]),_c('v-card-text',{staticStyle:{"height":"400px"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"dense":"","label":"First Name","required":""},model:{value:(_vm.defaultUser.first_name),callback:function ($$v) {_vm.$set(_vm.defaultUser, "first_name", $$v)},expression:"defaultUser.first_name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"dense":"","label":"Last Name"},model:{value:(_vm.defaultUser.last_name),callback:function ($$v) {_vm.$set(_vm.defaultUser, "last_name", $$v)},expression:"defaultUser.last_name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"dense":"","label":"Email","type":"email","required":""},model:{value:(_vm.defaultUser.email),callback:function ($$v) {_vm.$set(_vm.defaultUser, "email", $$v)},expression:"defaultUser.email"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{staticClass:"font-14",attrs:{"items":_vm.user_scopes,"label":"User Scope","dense":"","hide-details":""},model:{value:(_vm.defaultUser.user_scope),callback:function ($$v) {_vm.$set(_vm.defaultUser, "user_scope", $$v)},expression:"defaultUser.user_scope"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"compact-form",attrs:{"dense":"","label":"Is Admin"},model:{value:(_vm.defaultUser.is_admin),callback:function ($$v) {_vm.$set(_vm.defaultUser, "is_admin", $$v)},expression:"defaultUser.is_admin"}},on))]}}])},[_c('span',[_vm._v("An admin has all other roles and can also configure users")])])],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"compact-form",attrs:{"dense":"","label":"Playlists Role","disabled":_vm.defaultUser.is_admin},model:{value:(_vm.defaultUser.playlists_role),callback:function ($$v) {_vm.$set(_vm.defaultUser, "playlists_role", $$v)},expression:"defaultUser.playlists_role"}},on))]}}])},[_c('span',[_vm._v("Anyone with playlists role can add, edit and configure playlists")])])],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"compact-form",attrs:{"dense":"","label":"Config Role","disabled":_vm.defaultUser.is_admin},model:{value:(_vm.defaultUser.config_role),callback:function ($$v) {_vm.$set(_vm.defaultUser, "config_role", $$v)},expression:"defaultUser.config_role"}},on))]}}])},[_c('span',[_vm._v("Anyone with config role can configure hosts e.g. Update default playlist, Update Auxiliary Playlist")])])],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"compact-form",attrs:{"dense":"","label":"Accounting Role","disabled":_vm.defaultUser.is_admin},model:{value:(_vm.defaultUser.accounting_role),callback:function ($$v) {_vm.$set(_vm.defaultUser, "accounting_role", $$v)},expression:"defaultUser.accounting_role"}},on))]}}])},[_c('span',[_vm._v("Anyone with accounting role, can view current balance, make a claim request and access accounting page")])])],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-checkbox',_vm._g({staticClass:"compact-form",attrs:{"dense":"","label":"Campaigns Role","disabled":_vm.defaultUser.is_admin},model:{value:(_vm.defaultUser.campaigns_role),callback:function ($$v) {_vm.$set(_vm.defaultUser, "campaigns_role", $$v)},expression:"defaultUser.campaigns_role"}},on))]}}])},[_c('span',[_vm._v("Anyone with campaigns role, can create new campaigns and publish them")])])],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"dialogue-btn",attrs:{"color":"blue darken-3","text":""},on:{"click":function($event){_vm.isOpen= false}}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"dialogue-btn",attrs:{"color":"blue darken-3","text":""},nativeOn:{"click":function($event){return _vm.saveUser($event)}}},[_vm._v("Save")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }