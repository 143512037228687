<template>
  <v-content>
    <loading-dialog v-model="isLoading"></loading-dialog>
    <v-dialog v-model="isOpen" max-width="900px" scrollable>
      <v-card>
        <v-card-title class="font-16 bold">Add - center: Location Name</v-card-title>
        <v-card-title class="font-14 bold">Edit Site</v-card-title>
        <v-card-text style="height: 500px;">

          <v-row justify="center">
            <v-col cols="12" md="6">
              <v-text-field
                class="mt-2"
                label="Name"
                v-model="site_edit_object.name"
                outlined
                dense
                type="text">
              </v-text-field>
              <v-textarea
                label="Marketing Message"
                v-model="site_edit_object.marketing_message"
                outlined
                hide-details
                type="text">
              </v-textarea>
              <v-checkbox
                label="Listed Online"
                v-model="site_edit_object.is_listed_site"
                dense>
              </v-checkbox>
              <v-text-field
                class="mt-2"
                label="Number of Screens"
                v-model="site_edit_object.manual_num_screens"
                outlined
                dense
                type="text">
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                :items="categories"
                v-model="site_edit_object.category"
                label="Category"
                @input="getSubCategories"
                class="font-14"
                prepend-inner-icon="flag"
                item-text="name"
                item-value="category_id"
                outlined
                dense
                required
              ></v-autocomplete>
               <v-autocomplete
                :items="sub_categories"
                v-model="site_edit_object.sub_category"
                label="Sub Category"
                class="font-14"
                prepend-inner-icon="location_city"
                item-text="name"
                item-value="sub_category_id"
                outlined
                dense
              ></v-autocomplete>
             <v-text-field
                v-model="site_edit_object.visitors_reach_percentage"
                label="Visitors Reach Percentage"
                min="5"
                max="100"
                outlined
                dense
                type="Number">
              </v-text-field>
              <v-checkbox
                label="Uses Exposure Percentage"
                v-model="site_edit_object.use_visitors_exposure_percentage"
                dense>
              </v-checkbox>

              <v-text-field
                class="mt-2"
                label="Exposure Percentage"
                v-model="site_edit_object.visitors_exposure_percentage"
                :disabled="!site_edit_object.use_visitors_exposure_percentage"
                outlined
                dense
                type="text">
              </v-text-field>

              <v-text-field
                v-model="site_edit_object.average_weekday_visitors"
                label="Avg. Weekday Visitors"
                :disabled="site_edit_object.use_visitors_exposure_percentage"
                outlined
                dense
                type="text">
              </v-text-field>
              <v-text-field
                v-model="site_edit_object.average_weekend_visitors"
                label="Avg. Weekday Visitors"
                :disabled="site_edit_object.use_visitors_exposure_percentage"
                outlined
                dense
                type="text">
              </v-text-field>
            </v-col>
          </v-row>

              <v-expansion-panels class="mt-3">
                <v-expansion-panel>
                  <v-expansion-panel-header color="accent">
                    Site Images
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                      <u-location-btn
                        color="secondary"
                        class="mx-auto mt-2"
                        title="Upload Image"
                        mimeType="image/*"
                        :selectedCallback="uploadSiteImage"
                        :selected_item_id="site_edit_object.site_id">
                      </u-location-btn>
                    <v-row>
                      <v-col cols="12" class="text-center" v-for="(image, key) in site_images" :key="key">
                        <v-img
                          :src="image.thumbnail_m"
                          height="200"
                          contain>
                        </v-img>
                        <v-btn class="mt-2" x-small outlined color="red darken-1" @click='deleteSiteImage(image.image_id)'>
                          Delete
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header color="accent">
                    Field & Share Controls
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col cols="6" md="4"> 
                        <!-- <v-switch
                          v-model="service_request.service_actions.requires_training"
                          :label="`Requires Training: ${service_request.service_actions.requires_training.toString()}`"
                          @click='requestTraining'
                        ></v-switch> -->

                        <v-switch
                          v-model="service_request.service_actions.requires_validation"
                          class="mt-0 pt-0"
                          label="Requires Validation"
                          @click='requestValidation'
                        ></v-switch>
                        <v-switch
                          v-model="service_request.service_actions.requires_profiling"
                          class="mt-0 pt-0"
                          label="Requires Profiling"
                          @click='requestProfiling'
                        ></v-switch>
                        <v-switch
                          v-model="service_request.service_actions.requires_installation"
                          class="mt-0 pt-0"
                          label="Requires Installation"
                          @click='requestInstall'
                        ></v-switch>
                        <v-switch
                          v-model="service_request.service_actions.requires_sharing_check"
                          class="mt-0 pt-0"
                          label="Requires Share Validate"
                          @click='requestShareValidate'
                        ></v-switch>
                        <v-switch
                          v-model="service_request.service_actions.requires_training"
                          class="mt-0 pt-0"
                          label="Requires Training"
                          @click='requestTraining'
                        ></v-switch>
                        <v-switch
                          v-model="service_request.service_actions.requires_maintenance"
                          class="mt-0 pt-0"
                          label="Requires Maintenance"
                          @click='requestMaintenance'
                        ></v-switch>
                      </v-col>

                      <v-col cols="6" md="4" class="ms-6"> 
                        <!-- <v-switch
                          v-model="service_request.service_actions.requires_replace"
                          label="Request Device Replace"
                          @click='requestReplace'
                        ></v-switch> -->
                        <v-switch
                          v-model="service_request.service_actions.requires_uninstall"
                          class="mt-0 pt-0"
                          label="Requires Uninstall"
                          @click='requestUninstall'
                        ></v-switch>
                        <v-btn class="mt-0 me-2" small color="secondary" @click='deactivateShareCancelSchedules'>
                          Deactivate Share & Cancel All Schedules
                        </v-btn>
                        <v-btn class="mt-4 me-2" small color="secondary" @click='uninstallFieldVisit'>
                          Uninstalled In Current Field Visit
                        </v-btn>
                        <v-btn class="mt-4 me-2" small color="secondary" @click='hasUninstalled'>
                          Has Uninstalled
                        </v-btn>
                      </v-col>
                    </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header color="accent">
                    Operation Controls
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col cols="6" md="4">
                        <v-btn-toggle v-model="site_device.tilt" mandatory>
                          <v-btn text small :value=1 @click="setDeviceTilt(1)">
                            HORIZONTAL
                          </v-btn>
                          <v-btn text small :value=2 @click="setDeviceTilt(2)">
                            VERTICAL
                          </v-btn>
                        </v-btn-toggle>

                        <v-checkbox
                          label="Flip Vertical Orientation"
                          class="mt-1 pt-1"
                          v-model="site_device.flip_vertical_tilt"
                          dense
                          @click='setDeviceTilt(null)'
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>

             </v-expansion-panels>

         </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small @click="isOpen = false">
            Cancel
          </v-btn>
          <v-btn text small @click="submitEditSite">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-content>
</template>


<script>
  export default {
    props: {
      value: Boolean,
      site: Object,
      refresh_site_images: Boolean
    },
    computed: {
      isOpen: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      }
    },
    watch: {
      site (value) {
        if (value === null) {
          this.site_edit_object = Object.assign({}, this.default_site_edit_object)
          return
        }
        this.site_edit_object = Object.assign({}, {
          site_id: value.site_id,
          name: value.name,
          category: value.category,
          sub_category: value.sub_category,
          is_listed_site: value.is_listed_site,
          marketing_message: value.marketing_message,
          manual_num_screens: value.manual_num_screens,
          use_visitors_exposure_percentage: value.use_visitors_exposure_percentage,
          visitors_exposure_percentage: value.visitors_exposure_percentage,
          average_weekday_visitors: value.average_weekday_visitors,
          average_weekend_visitors: value.average_weekend_visitors,
          visitors_reach_percentage:value.visitors_reach_percentage
        })
        //this.setSiteImages(value.site_images)
        this.getServiceRequest()
        this.getSiteImages()
        this.getCategories()
        this.getSubCategories()
        this.getDeviceConfig()
      },
      isOpen (value) {
        if (!value) {
          this.site_edit_object = Object.assign({}, this.default_site_edit_object)
        }
      }
    },
    data () {
      return {
        isLoading : false,
        site_images: [],
        venues: [],
        categories: [],
        selected_category_index: null,
        selected_category_id: null,
        sub_categories: [],
        site_edit_object: {
          site_id: null,
          name: null,
          marketing_message: null,
          category: null,
          sub_category: null,
          is_listed_site: null,
          manual_num_screens: null,
          use_visitors_exposure_percentage: null,
          visitors_exposure_percentage: null,
          average_weekday_visitors: null,
          average_weekend_visitors: null,
          visitors_reach_percentage:null
        },
        default_site_edit_object: {
          site_id: null,
          name: null,
          marketing_message: null,
          category: null,
          sub_category: null,
          is_listed_site: null,
          manual_num_screens: null,
          use_visitors_exposure_percentage: null,
          visitors_exposure_percentage: null,
          average_weekday_visitors: null,
          average_weekend_visitors: null,
          visitors_reach_percentage:null
        },
        has_loaded_service_requests: false,
        service_request: {
          service_actions: {
            requires_validation: false,
            requires_installation: false,
            requires_profiling: false,
            requires_sharing_check: false,
            requires_maintenance: false,
            requires_training: false,
            requires_uninstall: false,
            install_data: {
                install_reference: null
            }
          },
          service_status: {
              validation_status: 5,
              installation_status: 5,
              profiling_status: 5,
              share_check_status: 5,
              training_status: 5,
              uninstall_status: 5
          },
          field_visits: [],
        },

        site_device: {
         tilt: 1,
         flip_vertical_tilt: false,
        },

      }
    },
    methods: {
      submitEditSite () {
        //this.site_edit_object.cluster = this.site.site_id
        this.isLoading = true
        this.$http.put(this.$store.getters.pubsServer + '/api/hosts/sites/' + this.site.site_id + '/', this.site_edit_object, { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.isOpen = false
          this.$emit('saved')
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
          console.log(error)
        })
      },
      setSiteImages (images) {
        this.site_images = images
      },
      getSiteImages () {
        if (this.site_edit_object.site_id === null) {
          return
        }
        this.isLoading = true
        this.$http.get(this.$store.getters.pubsServer + '/api/hosts/site_images/?site_id=' + this.site_edit_object.site_id, { headers: { Authorization: 'Token ' + this.$store.getters.token }
        }).then(response => {
          this.isLoading = false
          this.site_images = response.body
          //return response.body
         
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
        })
      },
       getSubCategories () {
        //this.isLoading = true
        // I'm HERE --- get selected category and fill sub-categories
        var category = this.site_edit_object.category
        this.$http.get(this.$store.getters.pubsServer + '/api/common/categories/' + category + '/', { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.sub_categories = response.body.sub_categories
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
        })
      },
      getCategories () {
        this.isLoading = true
        this.$http.get(this.$store.getters.pubsServer + '/api/common/categories/', { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.categories = response.body
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
        })
      },
      getServiceRequest () {
        this.isLoading = true
        this.$http.get(this.$store.getters.pubsServer + '/api/publishers/partners/service_requests/' + this.site_edit_object.site_id, { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          //console.log(response.body)
          this.isLoading = false
          if (response.body.service_actions !== undefined && response.body.service_actions !== null) {
            this.service_request = response.body
          }
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
        })
      },
      getDeviceConfig () {
        this.isLoading = true
        this.$http.get(this.$store.getters.pubsServer + '/api/hosts/hosts_devices/' + this.site_edit_object.site_id, { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          console.log("response.body:", response.body)
          this.site_device = response.body
          //console.log("this.site_device:", this.site_device)
      }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
        })
      },

      uploadSiteImage (file, site_id) {
        if (file === null) {
          return
        }
        if (this.site_edit_object.site_id === null) {
          return
        }
        var data = new FormData()
        data.set('file', file)
        this.isLoading = true
        this.$http.post(this.$store.getters.pubsServer + '/api/hosts/site_images/?site_id=' + this.site_edit_object.site_id, data, { headers: { Authorization: 'Token ' + this.$store.getters.token }
        }).then(response => {
          this.isLoading = false
          this.getSiteImages()
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
          console.log(error)
        })
      },
      deleteSiteImage (image_id) {
        if (this.site_edit_object.site_id === null) {
          return
        }
        this.isLoading = true
        this.$http.delete(this.$store.getters.pubsServer + '/api/hosts/site_images/' + image_id + '/', { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.getSiteImages()
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
          console.log(error)
        })
      },
      requestInstall () {
        if (this.site_edit_object.site_id === null) {
          return
        }
        this.isLoading = true
        this.$http.put(this.$store.getters.pubsServer + '/api/publishers/partners/service_requests/issue_install/' + this.site.site_id + '/', this.site_edit_object, { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.isOpen = false
          this.$emit('saved')
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
          console.log(error)
        })
      },
      requestMaintenance () {
        console.log("Call for maintenance")
        if (this.site_edit_object.site_id === null) {
          return
        }
        this.isLoading = true
        this.$http.put(this.$store.getters.pubsServer + '/api/publishers/partners/service_requests/issue_maintenance/' + this.site.site_id + '/', this.site_edit_object, { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.getServiceRequest()
          //this.isOpen = false
          //this.$emit('saved')
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
          console.log(error)
        })
      },
      requestValidation () {
        console.log("Validation Request - Not implemented!")
        if (this.site_edit_object.site_id === null) {
          return
        }
      },
      requestProfiling () {
        console.log("Profiling Request - Not implemented!")
        if (this.site_edit_object.site_id === null) {
          return
        }
      },
      requestTraining () {
        if (this.site_edit_object.site_id === null) {
          return
        }
        this.isLoading = true
        this.$http.put(this.$store.getters.pubsServer + '/api/publishers/partners/service_requests/issue_training/' + this.site.site_id + '/', this.site_edit_object, { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          console.log(response.body)
          this.isLoading = false
          this.getServiceRequest()
          //this.isOpen = false
          //this.$emit('saved')
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
          console.log(error)
        })
      },
      requestShareValidate () {
        console.log("Share Validation Request - Not implemented!")
        if (this.site_edit_object.site_id === null) {
          return
        }
      },
      requestReplace () {
        if (this.site_edit_object.site_id === null) {
          return
        }
        this.isLoading = true
        this.$http.put(this.$store.getters.pubsServer + '/api/publishers/partners/service_requests/issue_device_replace/' + this.site.site_id + '/', this.site_edit_object, { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.getServiceRequest()
          //this.isOpen = false
          //this.$emit('saved')
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
          console.log(error)
        })
      },
      requestUninstall () {
        if (this.site_edit_object.site_id === null) {
          return
        }
        this.isLoading = true
        this.$http.put(this.$store.getters.pubsServer + '/api/publishers/partners/service_requests/issue_uninstall/' + this.site.site_id + '/', this.site_edit_object, { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.getServiceRequest()
          //this.isOpen = false
          //this.$emit('saved')
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
          console.log(error)
        })
      },
      deactivateShareCancelSchedules () {
        if (this.site_edit_object.site_id === null) {
          return
        }
        this.isLoading = true
        this.$http.put(this.$store.getters.pubsServer + '/api/publishers/partners/hosts/deactivate_share/' + this.site.site_id + '/', this.site_edit_object, { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.getServiceRequest()
          //this.isOpen = false
          //this.$emit('saved')
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
          console.log(error)
        })
      },
      uninstallFieldVisit () {
        if (this.site_edit_object.site_id === null) {
          return
        }
        var field_visit_id = null
        this.isLoading = true
        // Step 1: get active field_visit by request operator
        this.$http.get(this.$store.getters.pubsServer + '/api/publishers/partners/field_visits/active/', { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          //console.log('field_visit:', response.body)
          field_visit_id = response.body.visit_id

          if (field_visit_id === undefined || field_visit_id === null) {
            console.log('visit_id is null or undefined')
            this.isLoading = false
            return
          }
          // Step 2: cal PUT @ "api/publishers/partners/field_visits/uninstalled"
          //this.hasUninstalled(field_visit_id, false)

          this.$http.put(this.$store.getters.pubsServer + '/api/publishers/partners/field_visits/uninstalled/' + field_visit_id + '/', this.site_edit_object, { headers: {
              Authorization: 'Token ' + this.$store.getters.token
            }
          }).then(response => {
            this.isLoading = false
            this.getServiceRequest()
          }, error => {
            this.isLoading = false
            if (error.status === this.unauthorized) {
              
            }
            console.log(error)
          })


        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
          console.log(error)
        })
      },
      hasUninstalled (field_visit_id, force) {
        console.log("Call for FORCE UNINSTALL - FORCE Not implemented!")
        console.log("Host Share must be deactivated first.. !")
        if (this.site_edit_object.site_id === null) {
          return
        }
        if (field_visit_id === undefined || field_visit_id === null) {
          console.log('visit_id is null or undefined')
          this.isLoading = false
          return
        }
        this.$http.put(this.$store.getters.pubsServer + '/api/publishers/partners/field_visits/uninstalled/' + field_visit_id + '/', { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.getServiceRequest()
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
          console.log(error)
        })
      },

      setDeviceTilt (tilt) {
        //console.log(this.site_device)
        //console.log("NEW tilt", tilt)
        //return
        if (this.site_edit_object.site_id === null) {
          return
        }
        if (tilt === null) {
          // if tilt=null -> then this is a call to flip_vertical_tilt -> Don't change tilt
          if (this.site_device.tilt === 1) {
            // don't flip if this HORIZONTAL
            this.site_device.flip_vertical_tilt = false
            return
          }
        } else {
          if (this.site_device.tilt === tilt) {
            return
          }
          this.site_device.tilt = tilt
          if (tilt === 1) {
            this.site_device.flip_vertical_tilt = false
          }          
        }
        // var obj_orientation = {
        //   tilt: this.site_device.tilt,
        //   flip_vertical_tilt: this.site_device.flip_vertical_tilt,
        // }
        this.isLoading = true
        this.$http.put(this.$store.getters.pubsServer + '/api/hosts/hosts_devices/' + this.site.site_id + '/', this.site_device, { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          console.log(response.body)
          this.isLoading = false
          this.site_device = response.body
          //this.isOpen = false
          //this.$emit('saved')
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
          console.log(error)
        })
      },


    }
  }
</script>

<!--     
    ACTION_NOT_TAKEN = 1
    ACTION_SUCCESSFULLY_TAKEN = 2
    ACTION_FAILED = 3
    ACTION_IS_REQUIRED_AGAIN = 4
    ACTION_NOT_REQUIRED = 5
    service_status: {
        "validation_status": 2,
        "installation_status": 5,
        "profiling_status": 2,
        "share_check_status": 2,
        "training_status": 1,
        "uninstall_status": 1
    }
 -->