<template>
  <v-container fluid>
    <v-dialog v-model="reportsDialog" max-width="600px">
      <v-card>
        <v-data-table
          dark
          class="blue-grey darken-3 font-13"
          
          :items="reports">
          <template v-slot:body="{ items }">
            <tr v-for="(item, key) in items" :key="key">
              <td>{{ item.date }}</td>
              <td class="text-center">{{ item.status !== undefined ? getReportStatus(item.status) : 'Unknown' }}</td>
              <td class="text-center">${{ item.rate }}</td>
              <td class="text-center">${{ item.tax !== undefined ? item.tax : ''}}</td>
              <td class="text-center">${{ item.full_rate !== undefined ? item.full_rate : '' }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
<!-- {{headers}} -->
<!-- {{schedules}} -->
<!-- {{withLocation}}
{{withControls}}
{{headerTitle}}
{{withHeader}}
{{emptyText}}
{{host}} -->




    <v-data-table
      class=""
      :headers="headers || default_headers"
      :items="schedules"
      item-key="schedule_id">

      <template v-slot:top v-if="withHeader">
        <v-row align="center" style="height: 50px;" justify="center" class="mx-0">
          <div class="dialogue-title blue-grey--text text--darken-3">{{ headerTitle }}</div>
        </v-row>
      </template>

      <template v-slot:body="{ items }" v-if="schedules.length > 0">
        <tr v-for="item in items" :key="item.schedule_id" class="px-0">
          <td class="text-center font-13 mx-0 px-0">{{ getScheduleStatus(item.schedule_status) }}</td>
          <td class="text-center font-13 mx-0" v-if="withLocation">{{ item.location_name }}</td>
          <td class="text-center font-13 mx-0 px-0" v-if="withLocation">{{ item.site_name }}</td>
          <td class="text-center font-13 mx-0" v-if="withLocation">{{ item.tilt !== null ? (item.tilt === 1 ? 'Hor.' : 'Ver.') : 'n.a.' }}</td>
          <td class="text-center font-13 mx-0 px-0">
            <div>{{ item.reserve_start_date }}</div>
            <div>{{ item.reserve_end_date }}</div>
          </td>
          <td class="text-center font-13 mx-0">{{ item.reserve_num_of_days }}</td>
          <!-- <td class="text-center">
            <div><span>Tilt:</span>
              <v-icon small v-if="item.has_valid_aspect" style="margin-right: 2px">done</v-icon>
              <v-icon small v-else style="margin-right: 2px">clear</v-icon>
            </div>
            <div><span>Auth:</span>
              <v-icon small v-if="item.media_approval_status === 2" style="margin-right: 2px">done</v-icon>
              <v-icon small v-else style="margin-right: 2px">clear</v-icon>
            </div>
          </td> -->
          <td class="text-center font-13 mx-0">${{ item.last_quote !== null ? item.last_quote : '0.00' }}</td>
          <td class="text-center font-13 mx-0 px-0">${{ item.executed_amount !== null ? item.executed_amount : '0.00' }}</td>
          <td class="text-center font-13 mx-0">${{ item.scheduled_amount !== null ? item.scheduled_amount : '0.00' }}</td>
          <td class="justify-center layout mx-0 px-0" v-if="withControls">
            <v-btn icon class="mx-0" @click="showReports(item.execution_report)" title="Executed Schedules" v-if="item.schedule_status !== 1">
              <v-icon small color="secondary">history</v-icon>
            </v-btn>
            <v-btn icon class="mx-0" @click="showReports(item.last_quote_report)" title="Scheduled Schedules" v-if="item.schedule_status !== 1">
              <v-icon small color="secondary">schedule</v-icon>
            </v-btn>
            <v-btn icon class="mx-0" @click="onCancelClicked(item)" title="Scheduled Schedules" v-if="item.schedule_status === 2 || item.schedule_status === 1">
              <v-icon small color="red darken-3">cancel</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
      <template v-slot:no-data v-else>
        {{ emptyText }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
  export default {
    props: {
      headers: Array,
      schedules: Array,
      withLocation: Boolean,
      withControls: Boolean,
      withHeader: Boolean,
      headerTitle: String,
      emptyText: String,
      schedulesType: String,
      host: Object,
      cancelClicked: Function
    },
    data () {
      return {
        reportsDialog: false,
        reports: [],
        default_headers: [
          {
            text: 'Status',
            align: 'left',
            sortable: false,
            value: 'code',
            class: 'tableTitle'
          },
          { text: 'Location', value: 'media', sortable: false, align: 'center', class: 'tableTitle' },
          { text: 'Site', value: 'runTime', sortable: false, align: 'center', class: 'tableTitle' },
          { text: 'Orientation', value: 'repeats', sortable: false, align: 'center', class: 'tableTitle' },
          { text: 'Start/End Dates', value: 'hhd', sortable: false, align: 'center', class: 'tableTitle' },
          { text: 'Num. Days', value: 'vhd', sortable: false, align: 'center', class: 'tableTitle' },
          { text: 'Quote', value: 'vhd', sortable: false, align: 'center', class: 'tableTitle' },
          { text: 'Executed Sums', value: 'vhd', sortable: false, align: 'center', class: 'tableTitle' },
          { text: 'Scheduled Sums', value: 'vhd', sortable: false, align: 'center', class: 'tableTitle' }
        ]
      }
    },
    methods: {
      onRowEdit (item) {
        this.editClicked(this.host, item, this.schedulesType)
      },
      onExecuteClicked (item) {
        this.executeClicked(this.host, item, this.schedulesType)
      },
      onCancelClicked (item) {
        this.cancelClicked(this.host, item, this.schedulesType)
      },
      getScheduleStatus (status) {
        if (status === 1) {
          return 'Pending'
        } else if (status === 2) {
          return 'Active'
        } else if (status === 3) {
          return 'Finished'
        } else if (status === 4) {
          return 'Failed'
        } else if (status === 5) {
          return 'Cancelled'
        }
      },
      showReports (reports) {
        if (reports === null || reports === undefined) {
          this.reports = []
        } else {
          this.reports = reports
        }
        this.reportsDialog = true
      }
    }
  }
</script>
