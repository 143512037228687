<template>
  <v-content>
    <loading-dialog v-model="isLoading" ></loading-dialog>
    <v-dialog v-model="isOpen" width="600px" height="250px" scrollable >
      <v-card>
        <v-card-title class="font-15">new visitorType </v-card-title>
        <v-card-text >
           <v-col cols="12" sm="6" md="3" >
          <v-select
          :items="visitor_name"
          v-model="typeName"
          label="Type"
        ></v-select>  
          <v-text-field
            label="Percentage"
            v-model="percentage"
            max="100"
            suffix="%"
            type="Number"
            outlined
          ></v-text-field>
        </v-col>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small  @click="isOpen = false">
            Cancel
          </v-btn>
          <v-btn text small  @click="AddNewType"  color="indigo">
            Save
          </v-btn>
        </v-card-actions>

        </v-card-text>
</v-card>
    </v-dialog>
  </v-content>
</template>
<script>
  export default {
    props: {
      value: Boolean,
      location_id: Number,
      visitors_people_types:Array,
      visitor_name:Array
    },
   
    computed: {
      isOpen: {
        get () {
          return this.value
          
        },
        set (value) {
          this.$emit('input', value)
        }
      },
    },
    data () {
      return {
        isLoading: false,
        visitors_types:this.visitors_people_types,
        typeName:null,
        percentage:0 ,
        locationId:this.location_id
     
      }
    },
    methods: {
      AddNewType(){
        var data =this.visitors_people_types
        // console.log(data)
        for (const key in data) {
          if (data[key].name===this.typeName) {
            const vistiorNew={visitor_percentage:Number(this.percentage),visitor_type:data[key].people_type_id}
           const vistiorEdit = Object.assign({}, vistiorNew)
            this.$http.put(this.$store.getters.pubsServer + '/api/hosts/profile_visitors/'+this.location_id+'/',vistiorEdit, { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }}).then(response => {
            console.log(response)
            this.percentage=0
            this.typeName=null
              this.isLoading = false
            this.isOpen = false
            this.$emit('saved')
          }, error => {
            // this.isOpen = false
            console.log(error)
          })
           }
        }
        return
      }
      }

  }
</script>