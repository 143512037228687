<template>
  <v-dialog :fullscreen="fullscreen" v-model="isOpen" max-width="1200px">
    <v-card class="black white--text pb-3">
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <v-btn text @click.native="isOpen = false;" dark v-if="fullscreen">
        <v-icon>fullscreen_exit</v-icon>
      </v-btn>
      <v-btn text @click.native="fullscreen = true" dark v-else>
        <v-icon>fullscreen</v-icon>
      </v-btn>
      <v-img
        v-if="!video"
        :src="url"
        style="height: 80vh;"
        contain
      >
      </v-img>
      <video-player
       class="video-player-box text-center"
       ref="videoPlayer"
       v-else
       :options="playerOptions"
       :playsinline="true"
       customEventName="customstatechangedeventname">
      </video-player>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      value: Boolean,
      url: String,
      video: Boolean,
      title: String,
      mediaClosed: Function
    },
    data () {
      return {
        fullscreen: false,
        playerOptions: {
          muted: false,
          language: 'en',
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [{
            type: 'video/mp4',
            src: ''
          }]
        }
      }
    },
    watch: {
      url (val) {
        if (this.video) {
          this.playerOptions.sources[0].src = val
        }
      }
    },
    computed: {
      isOpen: {
        get () {
          return this.value
        },
        set (isOpen) {
          this.mediaClosed()
          this.fullscreen = false
          this.$emit('input', isOpen)
        }
      }
    },
      created(){
        if (this.video) {
        this.playerOptions.sources[0].src = this.url
          }
      }
  }
</script>
