<template>
  <v-content>
    <loading-dialog v-model="isLoading"></loading-dialog>
    <v-dialog v-model="isOpen" max-width="400px" scrollable>
      <v-card>
        <v-card-title class="font-30"> Balance :  </v-card-title>
        <v-card-text style="height: 400px;">
        <v-row justify="center">
      <v-col>
        <tbody> 
          <v-col>
              <tr>
              <v-col>
               <td class="bold"  align="left">Total Balance: </td>
              </v-col>
               <td>
                ${{Balance.total_balance}} 
               </td>        
               </tr>
                </v-col>
                      <v-col v-if="!sponsor">
                       <!--tr>
                         <v-col>
                        <td class="bold" align="left" >Cleared Amount:</td>
                         </v-col>
                        <td>
                            ${{Balance.cleared_amount}} 
                        </td>
                      </tr-->
                      </v-col>
                      <v-col v-if="!sponsor">
                       <tr>
                         <v-col>
                        <td class="bold" align="left">Scheduled Amount:  </td>
                         </v-col>
                        <td align= "center" >
                          ${{Balance.scheduled_amount }} 
                    
                        </td>
                      </tr>
                      </v-col>
                      <v-col>
                       <tr>
                         <v-col>
                        <td class="bold" align="left">Executed Slots Amount: </td>
                         </v-col>
                        <td align= "right" >
                          ${{Balance.executed_slots_amount}} 
        
                        </td>
                      </tr>
                      </v-col>
                       <v-col>
                       <tr>
                         <v-col>
                        <td class="bold" align="left">On Hold Amount: </td>
                         </v-col>
                        <td align= "right" >
                          ${{Balance.on_hold_amount}} 
                        </td>
                      </tr>
                      </v-col>
                     
                    </tbody>


            </v-col>
          </v-row>
         </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small @click="isOpen = false">
            Cancel
          </v-btn>
         
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-content>
</template>


<script>
  export default {
    props: {
      value: Boolean,
      Balance:Object  ,
      sponsor:Boolean
   
    },
    computed: {
      isOpen: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      }
    },
    watch: {
      
      isOpen (value) {
        if (!value) {
          this.site_edit_object = Object.assign({}, this.default_site_edit_object)
        }
      }
    },
    data () {
      return {
        isLoading : false,
     
      }
    }

  }
</script>