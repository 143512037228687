<template>
  <v-dialog v-model="isOpen" max-width="290">
    <v-card>
      <v-card-title class="headline">{{ title }}</v-card-title>
      <v-card-text>{{ text }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-2" text @click="onNoClicked">No</v-btn>
        <v-btn color="success" text @click="onYesClicked">Yes</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'loading-dialog',
    props: {
      value: Boolean,
      title: String,
      text: String,
      yesClicked: Function,
      noClicked: Function
    },
    computed: {
      isOpen: {
        get () {
          return this.value
        },
        set (isOpen) {
          this.$emit('input', isOpen)
        }
      }
    },
    methods: {
      onYesClicked () {
        this.yesClicked()
        this.isOpen = false
      },
      onNoClicked () {
        if (this.noClicked) {
          this.noClicked()
        }
        this.isOpen = false
      }
    }
  }
</script>

<style>
  .loadingText {
    font-size: 14px;
  }
  .noOverflow{
    overflow-y: visible !important;
  }
</style>
