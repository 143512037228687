<template>
  <v-dialog v-model="isOpen" max-width="100px" content-class="noOverflow" persistent hide-overlay>
    <v-card>
      <div class="text-center py-3 px-3">
        <v-progress-circular indeterminate :width="5" :size="50" color="primary"></v-progress-circular>
        <div class="mt-3">
          <span class="loadingText dark--text">Loading...</span>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'loading-dialog',
    props: {
      value: Boolean
    },
    computed: {
      isOpen: {
        get () {
          return this.value
        },
        set (isOpen) {
          this.$emit('input', isOpen)
        }
      }
    }
  }
</script>

<style>
  .loadingText {
    font-size: 14px;
  }
  .noOverflow{
    overflow-y: visible !important;
  }
</style>
