<template>
  <v-content>
        
    <loading-dialog v-model="isLoading"></loading-dialog>
        <v-dialog v-model="isOpens" min-width="100px" max-width="600px"  scrollable>
    <v-card>
    <v-card-text style="height: 250px; ">
       <v-card-title class="font-30 pl-0">collect Payment :  </v-card-title>
         <v-row justify="center">
       <v-col>
      <v-text-field
            v-model="collect"
           suffix="$"
            label="Collect"
            outlined
            dense
            color="blue"
            type="number">
          </v-text-field>
          <span style="color :red">{{errroMasage}}</span> 
       </v-col>
          </v-row>
         </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
           <v-btn text :disabled="isDisable"  @click="getCollectPayments">
               Save 
           </v-btn>
          <v-btn text small @click="isOpens = false">
            Cancel
          </v-btn>
         
        </v-card-actions>
      </v-card>

    </v-dialog>
    <v-dialog v-model="isOpen" min-width="50px" max-width="600px" persistent scrollable>
    
      <v-card>
        <v-card-title class="font-30"> Collects Payments :  </v-card-title>
        <v-card-text style="min-height: 50px; min-width:50px  ">
        <v-row justify="center">
      <v-data-table 
                 class=" darken-1 "
          dense
          hide-default-footer
          :headers="headers"
          :items="collect_payments"
            >
          
          <template v-slot:body="{ items }">
            <tr v-for="(item, key) in items" :key="key">
              <td align="left" style=" font-size: 12px " > $ {{ item.amount }} </td>
         
              <td align="left"> {{item.operator_name}} </td>
              <td align="center"> {{item.payment}} </td>
              <td align="center"> {{item.payment_date}} </td>
            
            </tr>
          </template>
        </v-data-table> 
      
          </v-row>
         </v-card-text>
        <v-card-actions>
          
           <v-btn @click="isOpens=true" > New Collect</v-btn>
           <v-spacer></v-spacer>
          <v-btn text small @click="isOpen = false">
            Cancel
          </v-btn>
         
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-content>
</template>


<script>
  export default {
    props: {
      value: Boolean,
      collect_payments:Array,
      id:Number,
      scope:String
   },
    computed: {
      isOpen: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      }
    },
    watch:{
      collect(val){
        if (val<5){
          this.errroMasage="this Collect are Less than  5$ "
          this.isDisable=true
        }else if(val>=1001){
         this.errroMasage="this Collect are Greater than 1000$ "
         this.isDisable=true
        }else{
          this.errroMasage=''
          this.isDisable=false
        }
      }
    },
 
    data () {
      return {
        isLoading : false,
        isOpens:false,
        collect:0,
        isDisable:true,
        errroMasage:'',
          headers: [
          {
            text: 'Amount',
            align: 'left',
           sortable: false,
            value: 'code',
            class: 'tableTitle'
          },
          
          { text: 'Operator Name', value: 'operator_name', sortable: false, align: 'left', class: 'tableTitle' },
          { text: 'Payment ID', value: 'payment_ID', sortable: false, align: 'right ', class: 'tableTitle' },
          { text: 'Payment Date', value: 'payment_date', sortable: false, align: 'center ', class: 'tableTitle' }
          
        ],
        
     
      }
    },
    methods:{
        getCollectPayments(){
          this.isOpens=true
          let url=''
          let Gift ={}
          if(this.scope==='Partner'){
            url+='/api/publishers/partners/collect_payments/'
             Gift  = {
             partner: this.id,
            amount: this.collect
            }
          }
            else if (this.scope === 'Sponsor'){
               url+='/api/publishers/sponsors/collect_payments/'
               Gift  = {
              sponsor: this.id,
              amount: this.collect
              }
        }
            this.$http.post(this.$store.getters.pubsServer + url,Gift, { headers: {
             Authorization: 'Token ' + this.$store.getters.token
          }
        })
          .then(
            (res) => {
            this.isOpens=false
            this.isOpen=false
            }
          )
          .catch((error) => {
            this.isDashboardLoading = false
           console.log('error')
           console.log(error)
            if (error.status === this.unauthorized) {
              this.showSessionExpired()
            }
          })
        }}

  }
</script>