import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: '#766C27',
        secondary: '#323232',
        accent: '#787063',
        btn_color: '#282828',
        less_dark_color: colors.blueGrey.darken3,
        primary_text: colors.grey.lighten5,
        secondary_text: colors.grey.lighten1
      }
    }
  }
})
