import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/pages/Login'
import Dashboard from '@/pages/Dashboard'
import Balance from '@/pages/Balance'
import Locations from '@/pages/Locations'
import MediaReviewRequests from '@/pages/ReviewRequests'
import AuthGuard from './guards/auth-guard'
import LoginGuard from './guards/login-guard'
import PartnerRegRequests from '@/pages/PartnerRegRequests'
import Partners from '@/pages/Partners'
import PartnersProfile from '@/pages/PartnersProfile'
import CampaignsDashboard from '@/pages/CampaignsDashboard'
import Media from "@/pages/Media"
import Setting from "@/pages/Setting"
import PremiumClients from "@/pages/PremiumClients"
import PublisherMedia from "@/pages/PublisherMedia"

// ---- NEW (OLD Approach from Users-WebApp) ---
import Alert from '@/components/alert.vue'
Vue.component('k-alert', Alert)
// -------- NEW --------
import Hosts from '@/pages/Hosts'
//----------------------------------------------


Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/login',
      name: 'Login',
      meta: { title: 'Login' },
      component: Login,
      beforeEnter: LoginGuard
    },
    {
      path: '/',
      name: 'Dashboard',
      meta: { title: 'Dashboard' },
      beforeEnter: AuthGuard,
      component: Dashboard
    },
    {
      path: '/locations',
      name: 'Locations',
      meta: { title: 'Locations' },
      component: Locations,
      beforeEnter: AuthGuard
    },
    {
      path: '/Setting',
      name: 'Setting',
      meta: { title: 'Setting' },
      component: Setting,
      beforeEnter: AuthGuard
    },
    {
      path: '/media',
      name: 'media',
      meta: { title: 'Locations' },
      component: Media,
      beforeEnter: AuthGuard
    },
    {
      path: '/review-requests',
      name: 'MediaReviewRequests',
      meta: { title: 'Media Review Requests' },
      component: MediaReviewRequests,
      beforeEnter: AuthGuard
    },
    {
      path: '/partners/view',
      name: 'Partners',
      meta: { title: 'Partners' },
      component: Partners,
      beforeEnter: AuthGuard
    },
    {
      path: '/partners/registration_requests',
      name: 'PartnerRegRequests',
      meta: { title: 'Partners Registration Requests' },
      component: PartnerRegRequests,
      beforeEnter: AuthGuard
    },
    {
      path:'/partners/PartnersProfile',
      name:"PartnersProfile",
      meta: { title: 'Partners Registration Requests' },
      component:PartnersProfile,
      beforeEnter:AuthGuard
    },
    {
      path:'/balance',
      name:"Balance",
      meta: { title: 'Partners Registration Requests' },
      component:Balance,
      beforeEnter:AuthGuard
    },
    {
      path:'/PremiumClients',
      name:"PremiumClients",
      meta: { title: 'Partners Registration Requests' },
      component:PremiumClients,
      beforeEnter:AuthGuard
    },
    {
      path:'/partners/CampaignsDashboard',
      name:"CampaignsDashboard",
      meta: { title: 'Partners Registration Requests' },
      component:CampaignsDashboard,
      beforeEnter:AuthGuard
    },
    {
      path: '/media-assets',
      name: 'PublisherMedia',
      meta: { title: 'Media' },
      component: PublisherMedia,
      beforeEnter: AuthGuard
    },
    {
      path: '/hosts',
      name: 'Hosts',
      meta: { title: 'Hosts' },
      component: Hosts,
      beforeEnter: AuthGuard
    },
  ],
  
  mode: 'history'
})