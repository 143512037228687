<template>
  <v-container fluid>
    <loading-dialog v-model="isLoading"></loading-dialog>
   <v-row justify="center">
      <v-col cols="12" md="11" lg="10" xl="9">
         <v-toolbar   >
          <v-col >
          <v-toolbar-title class="font-20">
            Media  Details
          </v-toolbar-title>
          </v-col>
           <v-col md="1"  >
         <router-link  style=" color:white;"  :to="{ path: '/partners/PartnersProfile'+'/' ,query: { id: partner_id }}"   > 
              <v-btn class="grey darken-2" fab x-small dark>
                 <v-icon    >
                 mdi-home-circle
               </v-icon>
              </v-btn>
         </router-link>
        </v-col>
           </v-toolbar>
           <v-dialog v-model="mediaDialog" v-if="selectedMedia" max-width="1000px">
              <v-card class="grid">
                <v-card-text v-if="selectedMedia.description !== null">
                  <div>
                    <span class="grey--text caption">{{ selectedMedia.description }}</span>
                  </div>
                </v-card-text>
                <media-viewer v-model="variantDialog" :url="selectedVariant.file"
                :video="selectedMedia.media_type === 2" :title="selectedMedia.name" :mediaClosed="closeVariant">
                </media-viewer>
                <v-container fluid>
                  <v-row>
                    <v-col cols="12" sm="6" md="4" v-for="(variant, key) in selectedMedia.variants" :key="key">
                      <v-card class="black--text blue-grey lighten-5">
                        <v-container fluid>
                          <v-row>
                            <v-col cols="12" @click="openVariant(variant)" style="cursor: pointer">
                              <v-img
                                :src="variant.thumbnail_m !== null ? variant.thumbnail_m : require('@/assets/no_image.jpg')"
                                height="100"
                                contain
                              ></v-img>
                              <v-card-text>
                                <div class="grey--text text--darken-3 font-13">{{ getVariantText(variant) }}</div>
                                <div class="grey--text text--darken-3 font-13">Resolution: {{ variant.resolution_x }} X {{ variant.resolution_y }}</div>
                                <div class="grey--text text--darken-3 font-13">Size: {{ variant.size_mb }} MB</div>
                                <div class="grey--text text--darken-3 font-13" v-if="variant.is_hhd || variant.is_vhd">
                                  Review Status: 
                                  {{ getReviewStatus(variant) }}
                                </div>
                                <div class="grey--text text--darken-3 font-13" v-if="variant.is_hhd || variant.is_vhd">
                                  Duration (sec.): 
                                  {{ variant.duration_sec }}
                                </div>
                              </v-card-text>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-dialog>
           <v-col class="pt-4 pb-0 pl-0 "  >
                   
       <v-card style="width :600px; height :200px " v-if="partner" >
         <v-row>
          <v-col >       
              <v-col class="pa-0 pt-0 pl-2" >
                 <v-row class=" grey--text font-weight-black text--lighten-2 pl-2 " >
                   
              <p  style="font-size: 24px;" > 
                Partner Name: {{partner.name}}</p>
              </v-row>
             <v-col class="pt-0 pb-1 pl-0 font-weight-medium " ><hr>
             </v-col>
             
              <v-row class="pa-0 pt-0 pl-2  font-weight-medium text--lighten-1">
          Horizontal Approved Media : {{hhdapprovedMedia}}
               </v-row>
                  <v-row class="pa-0 pl-2 red--text font-weight-medium text--lighten-1">
          Horizontal Rejected Media : {{hhdrejectedMedia}}
               </v-row>
             </v-col> 
            <v-col>
           <v-row class="pa-0 pl-2  font-weight-medium text--lighten-1">
          Vertical  Approved Media : {{vhdapprovedMedia}}
               </v-row>
                   
          <v-row class="pa-0 pl-2 red--text font-weight-medium text--lighten-1">
          Vertical  Rejected Media : {{vhdrejectedMedia}}
               </v-row>
            </v-col>
       </v-col>
               
           </v-row>
       </v-card>
           </v-col>
          <v-row v-if="partner_media">
          <v-col cols="12" sm="10" md="3" v-for="(request, key) in review_requests" :key="key">
            <media-details
            v-if="request.variants.length!==0"
              :value="request"
              :openMediaClicked="openMedia"
              >
            </media-details>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
        isLoading: false,
        review_requests: [],
        selected_review_id: 0,
        partner_media:false,
        media:[],
        partner_id:null,
        partner:null,
        hhdapprovedMedia:null,
        hhdrejectedMedia:null,
        vhdapprovedMedia:null,
        vhdrejectedMedia:null,
        selectedMediaPos:-1,
        selectedMedia:{},
        selectedVariant:{},
         mediaDialog:false,
         variantDialog:false,
         currentTab:0,
          playerOptions: {
          muted: false,
          language: 'en',
           playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [{
            type: 'video/mp4',
            src: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm'
          }]
        }
              }
    },

    methods: {

      getMedia (id) {
        this.isLoading = true
        this.partner_id=id
        this.$router.push({ query: "" }) 
        this.partner_media=true
        this.$http.get(this.$store.getters.pubsServer + '/api/publishers/partners/media/?partner_id='+ id , { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.review_requests = response.body
          this.getApprovedMedia(this.review_requests)
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
        })
            this.$http.get(this.$store.getters.pubsServer + '/api/publishers/partners/'+id, { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          
          this.partner = response.body
         
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
        })
      },

      onReviewSelected (review_request_id) {
        this.selected_review_id = review_request_id
      },
      onReviewClosed () {
        this.selected_review_id = 0
      },
      onReviewFinished () {
        this.getReviewRequests()
      },
      getApprovedMedia(List){
        let hhdapproved=0
        let hhdreject=0
        let vhdapproved=0
        let vhdreject=0
        
        for (const key in List) {
          if(List[key].hhd_approved===true){
            hhdapproved+=1
          }else   if(List[key].hhd_approved===false){
            hhdreject+=1
          }
           if(List[key].vhd_approved===true){
            vhdapproved+=1
          }else if(List[key].vhd_approved===false){
            vhdreject+=1
          }
          
        }
        this.hhdrejectedMedia=hhdapproved
        this.hhdapprovedMedia=hhdreject
        this.vhdrejectedMedia=vhdreject
        this.vhdapprovedMedia=vhdapproved
      },   
     bottomVisible() {
        const scrollY = window.scrollY
        const visible = document.documentElement.clientHeight
        const pageHeight = document.documentElement.scrollHeight
        const bottomOfPage = visible + scrollY >= pageHeight
        return bottomOfPage || pageHeight < visible
      },
      getReviewStatus (variant) {
        if (variant.is_hhd) {
          if (this.selectedMedia.hhd_approved !== null && this.selectedMedia.hhd_approved) {
            return 'Approved'
          } else if (this.selectedMedia.hhd_approved !== null && !this.selectedMedia.hhd_approved) {
            return 'Declined'
          } else {
            return 'Not Reviewed'
          }
        } else if (variant.is_vhd) {
          if (this.selectedMedia.vhd_approved !== null && this.selectedMedia.vhd_approved) {
            return 'Approved'
          } else if (this.selectedMedia.vhd_approved !== null && !this.selectedMedia.vhd_approved) {
            return 'Declined'
          } else {
            return 'Not Reviewed'
          }
        }
      },
      noDeleteClicked () {
        this.mediaDeletionWarningDialog = false
        this.mediaDialog = true
      },
      mediaCreatorCreated () {
        this.currentTab = 0
        this.creatorMode = 'create'
        location.reload()
      },
      hdifyMedia (media) {
        if (!media.has_hhd) {
          this.creatorMode = 'hdify'
          this.creatorOrientation = 'Horizontal'
          this.creatorMedia = media
          this.currentTab = 1
          this.mediaDialog = false
          return
        }
        if (!media.has_vhd) {
          this.creatorMode = 'hdify'
          this.creatorOrientation = 'Vertical'
          this.creatorMedia = media
          this.currentTab = 1
          this.mediaDialog = false
        }
      },
      editMedia () {
        this.edited_media.name = this.selectedMedia.name
        this.edited_media.description = this.selectedMedia.description
        this.mediaDialog = false
        this.editedMediaDialog = true
      },
      saveMedia () {
        if (!this.isPartnerLogged) {
          return
        }
        this.isLoading = true
        this.$http.put(this.publishersUrl + '/api/web_client/partners/media/' + this.selectedMedia.media_id + '/', this.edited_media, {headers: {
          Authorization: 'Token ' + this.authToken
        }})
          .then(
            (res) => {
              this.isLoading = false
              this.editedMediaDialog = false
              location.reload()
            }
          )
          .catch((error) => {
            this.isLoading = false
            if (error.status === this.unauthorized) {
              this.showSessionExpired()
            }
            console.log(error)
          })
      },
      deleteMedia () {
        this.mediaDialog = false
        this.mediaDeletionWarningDialog = true
      },
      confirmDeleteMedia () {
        if (!this.isPartnerLogged) {
          return
        }
        this.isLoading = true
        this.$http.delete(this.publishersUrl + '/api/web_client/partners/media/' + this.selectedMedia.media_id + '/', {headers: {
          Authorization: 'Token ' + this.authToken
        }})
          .then(
            (res) => {
              this.isLoading = false
              location.reload()
            }
          )
          .catch((error) => {
            this.isLoading = false
            if (error.status === this.unauthorized) {
              this.showSessionExpired()
            }
            this.setError(this.getErrorFromResponse(error))
          })
      },
      getMedias (type) {
        if (this.isGettingMedia) {
          return
        }
        if (!this.isPartnerLogged) {
          return
        }
        var url = null
        if (this.response !== null) {
          if (this.response.links.next !== null) {
            url = this.response.links.next
          } else {
            return
          }
        } else {
          url = this.publishersUrl + '/api/web_client/partners/media/'
        }
        if (this.media.length === 0) {
          this.isPageLoading = true
        } else {
          this.isLoading = true
        }
        this.isGettingMedia = true
        this.$http.get(url, {headers: {
          Authorization: 'Token ' + this.authToken
        }})
          .then(
            (res) => {
              if (this.media.length === 0) {
                this.isPageLoading = false
              } else {
                this.isLoading = false
              }
              this.bottom = false
              this.response = res.data
              if (type === undefined) {
                this.media = this.media.concat(res.data.results)
              } else if (type === 'reload') {
                this.media = []
                this.media = this.media.concat(res.data.results)
              }
              if (this.selectedMediaPos !== -1) {
                this.selectedMedia = this.media[this.selectedMediaPos]
              }
              this.isGettingMedia = false
            }
          )
          .catch((error) => {
            if (this.media.length === 0) {
              this.isPageLoading = false
            } else {
              this.isLoading = false
            }
            if (error.status === this.unauthorized) {
              this.showSessionExpired()
            }
            console.log(error)
            this.isGettingMedia = false
          })
      },
      getPrimaryImage (images) {
        if (images === null || images === undefined) {
          return {thumbnail_m: ''}
        }
        for (var i = 0; i < images.length; i++) {
          var image = images[i]
          if (image.is_primary) {
            return image
          }
        }
      },
      getHorizontalVariant (images) {
        if (images === null || images === undefined) {
          return {thumbnail_m: ''}
        }
        for (var i = 0; i < images.length; i++) {
          var image = images[i]
          if (image.is_hhd) {
            return image
          }
        }
      },
      getVerticalVariant (images) {
        if (images === null || images === undefined) {
          return {thumbnail_m: ''}
        }
        for (var i = 0; i < images.length; i++) {
          var image = images[i]
          if (image.is_vhd) {
            return image
          }
        }
      },
      getAnyVariant (images) {
        if (images === null || images === undefined) {
          return {thumbnail_m: ''}
        }
        for (var i = 0; i < images.length; i++) {
          var image = images[i]
          if (!image.is_vhd && !image.is_hhd) {
            return image
          }
        }
      },
      getResolutionName (aspectType) {
        if (aspectType === 1) {
          return 'Uniform Square'
        } else if (aspectType === 2) {
          return 'Horizontal HD'
        } else if (aspectType === 3) {
          return 'Vertical HD'
        } else if (aspectType === 4) {
          return 'Horizontal Non-Uniform'
        } else {
          return 'Vertical Non-Uniform'
        }
      },
      getImageText (image) {
        var text = ''
        if (image.is_vhd) {
          text += 'Vertical HD '
        } else if (image.is_hhd) {
          text += 'Horizontal HD '
        }
        text += '(' + image.resolution_x + ' X ' + image.resolution_y + ') '
        text += 'size = ' + image.size_mb + ' MB'
        return text
      },
      openMedia (med) {
        const index = this.media.indexOf(med)
        this.selectedMediaPos = index
        this.selectedMedia = med
        this.mediaDialog = true
      },
      openVariant (variant) {
        this.selectedVariant = variant
        if (this.selectedMedia.media_type === 2) {
          this.playerOptions.sources[0].src = variant.file
        }
        this.variantDialog = true
      },
      closeMedia () {
        this.mediaDialog = false
        this.selectedMedia = {}
        this.selectedMediaPos = -1
        this.selectedVariant = {}
      },
      closeVariant () {
        this.variantDialog = false
        this.selectedVariant = {}
      },
      fileSelected (file) {
        if (file === null ) {
          return
        }
        var data = new FormData()
        data.set('file', file)
        this.isLoading = true
        if (file.type.includes('image')) {
          this.$http.post(this.publishersUrl + '/api/web_client/partners/images/', data, {headers: {
            Authorization: 'Token ' + this.authToken
          }})
            .then(
              (res) => {
                this.isLoading = false
                location.reload()
              }
            )
            .catch((error) => {
              this.isLoading = false
              if (error.status === this.unauthorized) {
                this.showSessionExpired()
              }
              console.log(error)
              this.setError(this.getErrorFromResponse(error))
            })
        } else {
          this.$http.post(this.publishersUrl + '/api/web_client/partners/videos/', data, {headers: {
            Authorization: 'Token ' + this.authToken
          }})
            .then(
              (res) => {
                this.isLoading = false
                location.reload()
              }
            )
            .catch((error) => {
              this.isLoading = false
              if (error.status === this.unauthorized) {
                this.showSessionExpired()
              }
              console.log(error)
              this.setError(this.getErrorFromResponse(error))
            })
        }
      },
      setError (text) {
        this.errorTxt = text
        this.errorSnackbar = true
      },
      variantSelected (file) {
        if (file === null) {
          return
        }
        if (this.selectedMedia.variants.length < 3) {
          var data = new FormData()
          data.set('file', file)
          if (file.type.includes('image')) {
            this.isLoading = true
            this.$http.post(this.publishersUrl + '/api/web_client/partners/images/?media_id=' + this.selectedMedia.media_id, data, {headers: {
              Authorization: 'Token ' + this.authToken
            }})
              .then(
                (res) => {
                  this.isLoading = false
                  location.reload()
                }
              )
              .catch((error) => {
                this.isLoading = false
                if (error.status === this.unauthorized) {
                  this.showSessionExpired()
                }
                console.log(error)
                this.setError(this.getErrorFromResponse(error))
              })
          } else {
            this.$http.post(this.publishersUrl + '/api/web_client/partners/videos/?media_id=' + this.selectedMedia.media_id, data, {headers: {
              Authorization: 'Token ' + this.authToken
            }})
              .then(
                (res) => {
                  this.isLoading = false
                  location.reload()
                }
              )
              .catch((error) => {
                this.isLoading = false
                if (error.status === this.unauthorized) {
                  this.showSessionExpired()
                }
                console.log(error)
                this.setError(this.getErrorFromResponse(error))
              })
          } 
        } else {
          this.setError('We are sorry, This media has reached to the maximum number of variants')
        }
      },
      logOut () {
        this.kDialog = false
        this.kDialogTitle = ''
        this.kDialogText = ''
        this.$store.dispatch('onSignOut', {})
        this.$router.push('/login')
      },
      showSessionExpired () {
        this.kDialogTitle = 'Login Session'
        this.kDialogText = 'Login session expired, please login again'
        this.kDialog = true
      },
      getTabStyle (pos) {
        var activePos = parseInt(this.currentTab)
        if (pos === activePos) {
          return 'text-transform: none; color: #BF360C; font-weight: bold;'
        }
        return 'text-transform: none; color: #919191;'
      },
      getTabIcnClr (pos) {
        var activePos = parseInt(this.currentTab)
        if (pos === activePos) {
          return 'red darken-3'
        }
        return 'grey'
      }
    },
    created () {
      this.getMedia(this.$route.query.id)

    }
  }
</script>