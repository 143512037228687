import Vue from 'vue'
import VueResource from 'vue-resource'
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
import Affix from 'vue-affix'
import { Chrome } from 'vue-color'
import MediaCreator from '@/components/media-creator.vue'

//import JSZip from 'jszip'
//Vue.use(JSZip)

Vue.use(VueResource)
Vue.use(VueVideoPlayer)
Vue.use(Affix)
Vue.component('media-creator', MediaCreator)
Vue.component('chrome-picker', Chrome)
