<template>
  <v-container fluid>
    <error-snackbar v-model="errorSnackbar" :errorMessage="errorMessage"></error-snackbar>
    <loading-dialog v-model="isLoading"></loading-dialog>
    <campaign-wizard v-model="createCampaignDialog" scope="Partners"></campaign-wizard>
    <v-row justify="center" v-if="sponsor">
       <v-row class=" grey--text font-weight-black text--lighten-1 pl-12 "  >
              <p  class="pl-12" style="font-size: 30px; padding left : 50px" > 
                Sponsor Name: {{sponsor.name}}</p>
              </v-row>
       <v-col cols="12" sm="12" md="12" lg="11" xl="12">
        <v-col>
              <v-card raised>
                <v-toolbar >
                   <v-row class="hidden-sm-and-down text-center px-0" cols="12" md="12" lg="6" >
                  <v-toolbar-items v-for="item in drawerMenuItems" 
                  :class="getElementClass(item.index)" :key="item.index" @click="selectMenuItem(item.index)">
                     <v-col cols="5" >
                       <v-btn class="ma-0 pa-0"  >
                      <v-icon class="ma-0" >{{ item.icon }}</v-icon>   
                     {{ item.text }}
                       </v-btn>
                    </v-col>
                  </v-toolbar-items>
                   </v-row>
           
                
             <v-col cols="1" md="2" class="pl-12">
           
          <v-btn class="grey darken-4"  @click="isOpen=false" fab x-small dark>
              <v-icon class="grey darken-4">mdi-home</v-icon>
            </v-btn>
                   </v-col>
                </v-toolbar> 
              </v-card>
        <v-col  cols="12" md="12" lg="9" class="px-2"  >

            <capmaign-view-sponser class="hidden-sm-and-down text-center px-1"  v-if="selectedPos !== -1 && active_campaigns" 
            :campaignId=" active_campaigns[selectedPos].campaign_id" 
              :onBackPressed="deselectCampaigns"
            :partner_id="Number(this.partner_id) "
            scope="sponsors">
            </capmaign-view-sponser>
            <div v-else>
              <v-row class="pb-0 mb-0">
                <v-col class="d-flex justify-left" cols="8" md="8">
                  <span class="page-title-upper grey--text text--darken-1 ms-2">Campaigns</span>
               
                </v-col>
        
                <v-col class="d-flex justify-right my-0 py-0" cols="4" sm="4">
             
                </v-col>
          
              </v-row>
              <v-divider class="pb-5"></v-divider>

              <campaigns-dashboard-loading v-if="isDashboardLoading"></campaigns-dashboard-loading>
              <div v-else-if="isCampaignsLoading" class="mt-3">
                <v-skeleton-loader
                  type="card-heading, list-item-avatar@20"
                  tile
                ></v-skeleton-loader>
              </div>
              <v-row v-else-if="selectedMenuIndex === 1 && campaignsDash !== null">
                <v-col cols="12">
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody class="py-0" style="border-left: 4px solid #156CA8; margin: 0px;">
                        <tr @click="selectMenuItem(2)" style="cursor: pointer;" class="py-5">
                          <td class="pa-0 ma-0">
                            <span class="my-0" style="border-left: 2px solid #156CA8;"></span>
                            <v-icon class="mx-3" color="blue darken-3">airplay</v-icon>
                            <span class="blue--text text--darken-3 bold" style="text-decoration: underline;">Active Campaigns</span>
                          </td>
                          <td class="blue--text text--darken-3 bold">{{ campaignsDash.campaigns.total_campaigns }}</td>
                        </tr>
                        <tr  @click="dashboard_schedule_index = 0" style="cursor: pointer;">
                          <td>
                            <v-icon class="me-3">cast_connected</v-icon>
                            <span class="bold">Active Schedules</span>
                          </td>
                          <td>{{ campaignsDash.campaigns.active_schedules }}</td>
                        </tr>
                        <tr  @click="dashboard_schedule_index = 1" style="cursor: pointer;">
                          <td>
                            <v-icon class="me-3">cast</v-icon>
                            <span class="bold">Executed Schedules</span>
                          </td>
                          <td>{{ campaignsDash.campaigns.executed_schedules }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <v-card class="elevation-0 pt-3">
                    <div class="text-center">
                      <v-btn-toggle
                        v-model="dashboard_schedule_index"
                        tile
                        color="primary"
                        group
                      >
                        <v-btn :value="0" small class="bold">
                          <v-icon left>cast_connected</v-icon>
                          Active Schedules
                        </v-btn>

                        <v-btn :value="1" small class="bold">
                          <v-icon left>cast</v-icon>
                          Executed Schedules
                        </v-btn>
                      </v-btn-toggle>
                    </div>
                  </v-card>

                  <div v-if="dashboard_schedule_index === 0">
                    <schedules-table
                      :schedules="all_active_schedules" withLocation
                      :withControls="false"
                      withHeader headerTitle="Active Schedules"
                      emptyText="No active schedules">    
                    </schedules-table>
                  </div>
                  <div v-if="dashboard_schedule_index === 1">
                    <schedules-table
                      :schedules="all_inactive_schedules" withLocation
                      :withControls="false"
                      withHeader headerTitle="Executed Schedules"
                      emptyText="No executed schedules">    
                    </schedules-table>
                  </div>
                </v-col>
              </v-row>
              <v-row v-else-if="selectedMenuIndex === 2">
                <v-col cols="12">
                  <v-simple-table v-if="active_campaigns.length > 0">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-start">Campaign Name</th>
                          <th class="text-start">Scheduled Amount</th>
                          <th class="text-start">Executed Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(campaign, i) in active_campaigns" :key="i" style="cursor: pointer;" @click="selectCampaign('active', i)">
                          <td class="singleline" style="max-width: 400px;">
                            <v-avatar
                              color="grey"
                              size="36px"
                              v-if="campaign.thumbnail_s !== null"
                              tile
                            >
                              <v-img :src="campaign.thumbnail_s"></v-img>
                            </v-avatar>
                            <span class="ms-2 singleline">{{ campaign.name }}</span>
                          </td>
                          <td>${{ campaign.scheduled_amount }}</td>
                          <td>${{ campaign.executed_amount }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
              <v-row v-else-if="selectedMenuIndex === 3">
                <v-col cols="12">
                  <v-simple-table v-if="inactive_campaigns.length > 0">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-start">Campaign Name</th>
                          <th class="text-start">Scheduled Amount</th>
                          <th class="text-start">Executed Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(campaign, i) in inactive_campaigns" :key="i" style="cursor: pointer;" @click="selectCampaign('inactive', i)">
                          <td class="singleline" style="max-width: 400px;">
                            <v-avatar
                              color="grey"
                              size="36px"
                              v-if="campaign.thumbnail_s !== null"
                              tile
                            >
                              <v-img :src="campaign.thumbnail_s"></v-img>
                            </v-avatar>
                            <span class="ms-2 singleline">{{ campaign.name }}</span>
                          </td>
                          <td>${{ campaign.scheduled_amount }}</td>
                          <td>${{ campaign.executed_amount }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
                 
            
            </div>
         
          </v-col>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
      props:{
        value:Boolean,
        sponsor_id:Number
    },
    data () {
      return {
        isLoading: false,
        isDashboardLoading: false,
        isCampaignsLoading: false,
        active_campaigns: [],
        partner_id:null,
        sponsor:null,
        inactive_campaigns: [],
        createCampaignDialog: false,
        editCampaignDialog: false,
        edited_campaign: {},
        kDialog: false,
        kDialogTitle: '',
        kDialogText: '',
        errorSnackbar: false,
        errorMessage: '',
        campaignsDash: null,
        drawerMenuItems: [
          {text: 'Dashboard', icon: 'line_style', index: 1},
          {text: 'Active Campaigns', icon: 'airplay', index: 2},
         
         
        ],
        sort_by: ['Date', 'Name', 'Active Schedules'],
        dashboard_schedule_index: null,
        all_active_schedules: [],
        all_inactive_schedules: [],
      }
    },
    watch: {
      createCampaignDialog (val) {
        if (!val) {
          this.getCampaigns()
        }
      },
      editCampaignDialog (val) {
        if (!val) {
          this.getCampaigns()
        }
      },
      selectedMenuIndex (value) {
        if (value === 1) {
          this.getCampaignsDashboard()
        } else if (value === 2) {
          this.getActiveCampaigns()
        } else if (value === 3) {
          this.getInactiveCampaigns()
        }
      },
      dashboard_schedule_index (value) {
        if (value === 0) {
          this.getCampaignsActiveSchedules()
        } else if (value === 1) {
          this.getCampaignsInactiveSchedules()
        }
      }
    },
    computed: {
       isOpen: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('saved', value)
        }
      },
      numCampaigns (){
        if (this.selectedMenuIndex === 3) {
          return this.inactive_campaigns.length
        }
        if (this.selectedMenuIndex === 2) {
          return this.active_campaigns.length
        }
        return 0
      },
      isPartnerLogged () {
        if (this.$store.getters.logged_in_account !== null) {
          return this.$store.getters.logged_in_account.account_type === 3
        }
        return false
      },
      publishersUrl () {
        if (this.isPartnerLogged) {
          return this.$store.getters.logged_in_account.server_dns
        }
        return ''
      },
      campaignsRole () {
        if (this.isLogged) {
          if (this.$store.getters.logged_in_account.user_account.is_admin) {
            return true
          }
          return this.$store.getters.logged_in_account.user_account.config_role
        }
        return ''
      },
      authToken () {
        if (this.isPartnerLogged) {
          return this.$store.getters.logged_in_account.account_token
        }
        return ''
      },
      selectedMenuIndex: {
        get () {
          if (this.$route.query.index === undefined) {
            return 1
          }
          return parseInt(this.$route.query.index)
        },
        set (value) {
          var query = this.getQuery()
          query.index = value
          this.$router.push({ query: query })
        }
      },
     
      selectedPos: {
        get () {
          if (this.$route.query.campaign_index === undefined) {
            return -1
          }
          return parseInt(this.$route.query.campaign_index)
        },
        set (value) {
          var query = this.getQuery()
          query.campaign_index = value
          this.$router.push({ query: query })
        }
      }
    },
    
    head () {
      return {
        title: 'Campaigns'
      }
    },
    methods: {
        getPartner(){
         
           this.isLoading = true
           this.$http.get(this.$store.getters.pubsServer + '/api/publishers/sponsors/'+this.sponsor_id, { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.sponsor = response.body
         
      
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
        })
        },
      getQuery () {
        var query =  {
          index: this.selectedMenuIndex,
          id:this.partner_id
        }
        if (this.selectedType !== undefined) {
          query.campaign_type = this.selectedType
        }
        if (this.selectedPos !== -1) {
          query.campaign_index = this.selectedPos
        }
        return query
      },
      deselectCampaigns () {
        this.selectedType = undefined
        this.selectedPos = undefined
      },
      getCampaignHostStatus (status) {
        if (status === 1) {
          return 'Active'
        } else if (status === 2) {
          return 'Inactive'
        } else if (status === 3){
          return 'Deleted'
        }
        return 'Unknown'
      },
      getMediaApprovalStatus (status) {
        if (status === 1) {
          return 'Not Checked'
        } else if (status === 2) {
          return 'Valid'
        } else if (status === 3) {
          return 'Invalid'
        }
        return 'Unknown'
      },
      getInvalidMediaIssue (status) {
        if (status === 1) {
          return 'No Issues'
        } else if (status === 2) {
          return 'Media Tag Issue'
        } else if (status === 3) {
          return 'Media Category Issue'
        } else if (status === 4) {
          return 'Media Rejected'
        } else if (status === 5) {
          return 'Media has Bad Aspect'
        }
        return 'Unknown'
      },
      editCampaign (cam) {
        this.edited_campaign = cam
        this.editCampaignDialog = true
      },
      
      getCampaignsDashboard () {
        this.isDashboardLoading = true
            this.$http.get(this.$store.getters.pubsServer + '/api/publishers/sponsors/campaigns/dash/?sponsor_id='+this.sponsor_id, { headers: {
             Authorization: 'Token ' + this.$store.getters.token
          }
        })
          .then(
            (res) => {
              this.isDashboardLoading = false
              this.campaignsDash = res.body
            }
          )
          .catch((error) => {
            this.isDashboardLoading = false
           console.log('error')

            console.log(error)
            if (error.status === this.unauthorized) {
              this.showSessionExpired()
            }
          })
            
      },
      getCampaigns () {
        this.getActiveCampaigns()
        this.getInactiveCampaigns()
      },
      getActiveCampaigns () {
        this.isCampaignsLoading = true
        this.$http.get(this.$store.getters.pubsServer + '/api/publishers/sponsors/campaigns/active/?sponsor_id='+this.sponsor_id, { headers: {
             Authorization: 'Token ' + this.$store.getters.token
          }
        })
          .then(
            (res) => {
              this.isCampaignsLoading = false
              this.active_campaigns = res.body
            }
          )
          .catch((error) => {
            this.isCampaignsLoading = false
            console.log(error)
            if (error.status === this.unauthorized) {
              this.showSessionExpired()
            }
          })
      },
      getInactiveCampaigns () {
        this.isCampaignsLoading = true
        this.$http.get(this.$store.getters.pubsServer + '/api/publishers/sponsors/campaigns/inactive/?sponsor_id='+this.sponsor_id, { headers: {
             Authorization: 'Token ' + this.$store.getters.token
          }
        })
          .then(
            (res) => {
              this.isCampaignsLoading = false
              this.inactive_campaigns = res.body
            }
          )
          .catch((error) => {
            this.isCampaignsLoading = false
            console.log(error)
            if (error.status === this.unauthorized) {
              this.showSessionExpired()
            }
          })
      },
      getElementClass (index) {
        if (this.selectedMenuIndex === index) {
          return 'grey darken-3'
        }
        return 'transparent'
      },
      getListTextClass (index) {
        if (this.selectedMenuIndex === index) {
          return 'font-13-5 spacing-04 bold white--text'
        }
        return 'font-13-5 spacing-04 bold blue-grey--text text--darken-4'
      },
      selectMenuItem (index) {
        this.selectedMenuIndex = index
        this.deselectCampaigns()
      },
      selectCampaign (type, pos) {
        this.selectedType = type
        this.selectedPos = pos
      },
      showSessionExpired () {
        this.kDialogTitle = 'Login Session'
        this.kDialogText = 'Login session expired, please login again'
        this.kDialog = true
      },


      // NEW
      getCampaignsActiveSchedules () {
        this.isLoading = true
          this.$http.get(this.$store.getters.pubsServer + '/api/publishers/sponsors/campaigns/all_active_schedules/?sponsor_id='+this.sponsor_id, { headers: {
             Authorization: 'Token ' + this.$store.getters.token
          }
        })
          .then(
            (res) => {
              this.isLoading = false
              this.all_active_schedules = res.body
            }
          )
          .catch((error) => {
            this.isLoading = false
            console.log(error)
            if (error.status === this.unauthorized) {
              this.showSessionExpired()
            }
          })
      },
      // NEW
      getCampaignsInactiveSchedules () {
        this.isLoading = true
           this.$http.get(this.$store.getters.pubsServer + '/api/publishers/sponsors/campaigns/all_inactive_schedules/?sponsor_id='+this.sponsor_id, { headers: {
             Authorization: 'Token ' + this.$store.getters.token
          }
        })
          .then(
            (res) => {
              this.isLoading = false
              this.all_inactive_schedules = res.body
            }
          )
          .catch((error) => {
            this.isLoading = false
            console.log(error)
            if (error.status === this.unauthorized) {
              this.showSessionExpired()
            }
          })
      },
    },
    created () {
  
      this.getPartner()
      if (this.selectedMenuIndex === 1) {
        
        this.getCampaignsDashboard()
      } else if(this.selectedMenuIndex === 2){
        this.getActiveCampaigns()
      } else {
        this.getInactiveCampaigns()
      }
    }
  }
</script>

<style>
  div.titleTextColor{
    color: #777;
    font-weight: bold;
    font-size: 13px;
  }

  span.secondTextColor{
    color: #616161;
  }

  .seconderyTextColor{
    color: #616161;
  }

  .tableSpacing{
    border-spacing: 10px 0;
  }

  .scheduleDateColor{
    color: #575757;
  }

  .singleline{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .subheader_title {
    padding:0px;
    height: 25px;
    width: auto;
    font-size: 15px;
    margin-bottom: 10px;
    border-bottom: 2px solid #00B19E;
    display: inline-block;
  }

  th.column.text-xs-left.tableTitle{
    color: #2E2E2E;
    font-size: 13px;
    font-weight: bold;
  }


</style>
