 <template>
  <v-content>
    <loading-dialog v-model="isLoading"></loading-dialog>
   <v-dialog v-model="isOpen" max-width="900px" scrollable>
      <v-card>
       <v-dialog v-model="showMediaDialog" max-width="600px">
          <v-card class="pt-4 pb-4">
            <v-img v-if="showType === 1" :src="showUrl" contain></v-img>
            <video-player
             class="video-player-box text-center"
             ref="videoPlayer"
             v-else
             :options="playerOptions"
             :playsinline="true"
             customEventName="customstatechangedeventname">
            </video-player>
          </v-card>
        </v-dialog>
        <v-card-title class="font-15">
         <span class="dialogue-title blue-grey--text text--darken-3">Commercial Schedules</span>
        </v-card-title>
        <v-card-text style="height: 500px;">
              <v-row>
                <v-col cols="12" v-if="!date_schedules"> 
                  Loading
                </v-col>
                <v-col cols="12" v-else-if="!date_schedules.length"> 
                  No Schedules
               </v-col>
               <v-col cols="12" v-else v-for="(item, index) in date_schedules" :key="index">
                  <v-card color="white">
                    <v-layout class="blue-grey darken-2 white--text" style="height: 40px;" align-center>
                      <v-card-title>
                        <div class="singleline btn-apps">{{ item.date }}</div>
                      </v-card-title>
                      <v-spacer></v-spacer>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-layout>
                    <v-row>
                      <v-col md="4" cols="12">
                        <v-card-text class="body-2">
                   <table class="tableSpacing blue-grey">
                            <tr>
                              <td align="right"><div class="card-title-text">Total Reserved Slots:</div></td>
                              <td><span class="card-second-text">{{ item.reserved_slots }}</span></td>
                            </tr>
                            <tr>
                              <td align="right"><div class="card-title-text">Free Slots:</div></td>
                              <td><span class="card-second-text">{{ item.free_slots }}</span></td>
                            </tr>
                           <tr>
                             <td align="right"><div class="card-title-text">Reserved Online:</div></td>
                             <td><span class="card-second-text">{{ item.online_slots }}</span></td>
                           </tr>
                            <tr>
                              <td align="right"><div class="card-title-text">Reserved Premium:</div></td>
                              <td><span class="card-second-text">{{ item.premium_slots }}</span></td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-col>
                      <v-col cols="12" md="8">
                        <v-row justify="end">
                          <v-col cols="2" v-for="slot in item.online_playlist_slots" :key="slot.slot_id" style="cursor: pointer;" @click="showMedia(slot.media.media_type == 1 ? slot.media.thumbnail_m : slot.media.file_webm, slot.media.media_type)">
                            <v-card class="transparent elevation-0 mr-2">
                              <v-img :height="50" :src="slot.media.thumbnail_s !== null ? slot.media.thumbnail_s : '/no_image.jpg'" contain>
                                <v-overlay :opacity="0.05" absolute v-if="slot.media.media_type === 2">
                                  <v-row class="fill-height" align="center" justify="center">
                                    <v-btn icon>
                                      <v-avatar size="20" color="primary">
                                        <v-icon>play_arrow</v-icon>
                                      </v-avatar>
                                    </v-btn>
                                  </v-row>
                                </v-overlay>
                              </v-img>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-expansion-panels>
                      <v-expansion-panel class="blue-grey lighten-4 light-blue--text text--darken-3">
                        <v-expansion-panel-header style="font-size: 12px; text-transform: uppercase;"><b>Approved Slots: {{ getPlaylistNumSlots(item.online_playlist_slots) }}</b></v-expansion-panel-header>
                        <v-expansion-panel-content class="white">
                          <v-data-table
                            class="white py-5"
                            :headers="headers"
                            :items="item.online_playlist_slots"
                            hide-default-footer
                            item-key="slot_id">
                            <template v-slot:body="{ items }">
                             <tr v-for="item in items" :key="item.slot_id">
                                <td class="text-center">
                                  <v-img :height="40" :width="40" :src="item.media.thumbnail_s !== null ? item.media.thumbnail_s : '/no_image.jpg'" @click="showMedia(item.media.media_type == 1 ? item.media.thumbnail_m : item.media.file_webm, item.media.media_type)">
                                    <v-overlay :opacity="0.05" absolute v-if="item.media.media_type === 2">
                                      <v-row class="fill-height" align="center" justify="center">
                                        <v-btn icon>
                                          <v-avatar size="20" color="primary">
                                            <v-icon>play_arrow</v-icon>
                                          </v-avatar>
                                        </v-btn>
                                     </v-row>
                                    </v-overlay>
                                  </v-img>
                                </td>
                            <td class="text-xs-left singleline light-blue--text text--darken-3" style="max-width: 200px">{{ item.media.client_name }}</td>
                            <td class="text-center light-blue--text text--darken-3">{{ item.media.category_name + ' / ' + item.media.sub_category_name }}</td>
                            <td class="text-center light-blue--text text--darken-3">{{ item.num_slots }}</td>
                            <td class="text-center light-blue--text text--darken-3">${{ item.partner_share }}</td>
                            <td class="justify-center layout px-0" >
                             </td>
                             </tr>
                       </template>
                       </v-data-table>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-card>
                </v-col>
              </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
         
          <v-btn class="dialogue-btn" color="blue darken-1" text small @click="Close">
            Close
         </v-btn>
        
        </v-card-actions>
      </v-card>

    </v-dialog>
  </v-content>
</template>
  <script>
    export default {
    props: {
      value: Boolean,
      Schedules_host_id: Number,
      date_schedules:Array
      },
    computed: {
        isOpen: {
          get () {
            return this.value
          },
          set (value) {
            this.$emit('input', value)
          }
        },
    
      },
      watch: {
        Schedules_host_id (value) {
          if (value === null) {
          return
          }
          this.host_id = value
      
        },
        showMediaDialog (value) {
        if (!value) {
            this.playerOptions.sources[0].src = ''
        }
        },
      
      },
      data () {
        return {
        isLoading : false,
        host_id: null,
        showMediaDialog: false,
        showUrl: '',
        showType: 1,
        headers: [
            {text: 'Media',align: 'left',sortable: false,value: 'code',class: 'tableTitle'},
            { text: 'Client', value: 'client_name', sortable: false, align: 'left', class: 'tableTitle' },
            { text: 'Media Category', value: 'category_name', sortable: false, align: 'center', class: 'tableTitle' },
            { text: 'Slots', value: 'num_slots', sortable: false, align: 'center', class: 'tableTitle' },
            { text: 'Revenue', value: 'partner_share', sortable: false, align: 'center', class: 'tableTitle' },
            { text: 'Actions', value: 'vhd', sortable: false, align: 'center', class: 'tableTitle' }
          ],
          playerOptions: {
            muted: false,
            language: 'en',
            playbackRates: [0.7, 1.0, 1.5, 2.0],
            sources: [{type: 'video/mp4',src: ''}]},
        }
      },
      methods: {
      
        showMedia (url, type) {
          this.showUrl = url
          this.showType = type
          if (this.showType === 2) {
            this.playerOptions.sources[0].src = this.showUrl
          }
          this.showMediaDialog = true
        },
          getPlaylistNumSlots (plist) {
          const total = plist.reduce((sum, slot) => {
            return sum + slot.num_slots;
          }, 0)
          return total
        },
        Close(){
         
          this.isOpen=false
        }
      
      }
    }
  </script>