<template>
  <v-app>
    <div v-if="!isWithoutBar">
      <yes-no-dialog
        v-model="logoutDialog"
        title="Logout"
        text="Are you sure you want to logout?"
        :yesClicked="logout">
      </yes-no-dialog>
      <v-app-bar
        class="transparent"
        dense
        flat
      >
        <v-spacer></v-spacer>
        <v-row align="center" justify="end">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-badge
                  color="red darken-1"
                  overlap
                  content="5"
                >
                  <v-icon>mdi-bell</v-icon>
                </v-badge>
              </v-btn>
            </template>
            <span>Notifications</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn @click="logoutDialog = true" icon v-on="on">
                <v-icon>logout</v-icon>
              </v-btn>
            </template>
            <span>Sign Out</span>
          </v-tooltip>
        </v-row>
      </v-app-bar>
      <layout-drawer></layout-drawer>
      <loading-dialog v-model="isLoading"></loading-dialog>
    </div>

    <v-content>
      <router-view></router-view>
    </v-content>
  </v-app>
</template>

<script>
  export default {
    data () {
      return {
        isLoading: false,
        logoutDialog: false
      }
    },
    computed: {
      isWithoutBar () {
        return this.no_bar_routes.includes(this.$route.name)
      }
    },
    methods: {
      logout () {
        this.isLoading = true
        this.$http.post(this.$store.getters.pubsServer + '/api/operators/logout/', {'hello': 2}, {headers: {'Authorization': 'Token ' + this.$store.getters.token}})
          .then(
            (res) => {
              console.log(res.data)
              this.isLoading = false
              this.$store.dispatch('onSignOut', null)
              location.reload()
            }
          )
          .catch((error) => {
            this.isLoading = false
            console.log(error)
            if (error.status === this.unauthorized) {
              this.$store.dispatch('onSignOut', null)
              location.reload()
            }
          })
      }
    },
    created () {
    }
  }
</script>

<style>
  /* #toolbar-bg{
    background: url('/static/top_bar_1_bg.png');
  } */

  .textTransformNone {
    text-transform: none !important;
  }

  .textTransformCap {
    text-transform: uppercase !important;
  }

  .v-expansion-panel-header {
    min-height: 30px !important;
    padding: 5px 24px !important;
  }

  .v-expansion-panel--active .v-expansion-panel-header {
    min-height: 35px !important;
  }

  .noOverflow{
    overflow-y: visible !important;
  }

  .weight-500 {
    font-weight: 500 !important;
  }

  .weight-600 {
    font-weight: 600 !important;
  }

  .bold {
    font-weight: bold !important;
  }

  .weight-800 {
    font-weight: 800 !important;
  }

  .weight-900 {
    font-weight: 900 !important;
  }

  .italic {
    font-style: italic !important;
  }

  .font-12 {
    font-size: 12px !important;
  }

  .spacing-1 {
    letter-spacing: 1px;
  }

  .font-13 {
    font-size: 13px !important;
  }

  .font-14 {
    font-size: 14px !important;
  }

  .font-15 {
    font-size: 15px !important;
  }

  .font-16 {
    font-size: 16px !important;
  }
  .font-17 {
    font-size: 17px !important;
  }
  .font-18 {
    font-size: 18px !important;
  }
  .font-19 {
    font-size: 19px !important;
  }
  .font-20 {
    font-size: 20px !important;
  }

  .font-25 {
    font-size: 25px !important;
  }

  .font-30 {
    font-size: 30px !important;
  }

  .font-35 {
    font-size: 35px !important;
  }

  .font-40 {
    font-size: 40px !important;
  }

  .vjs_video_434-dimensions, .video-js {
    width: 100%;
    height: 70vh;
  }

  .singleline{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

</style>
