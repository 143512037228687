<template>
  <v-card class="transparent  elevation-0 black--text" v-if="med !== null">
    <v-container fluid>
      <v-row class="grey darken-3 mediaExpansionPanel" @click="onOpenMedia" align-content="center">
        <v-col cols="12" align-self="center">
          
            <div class="white--text font-weight-medium " style="font-size: 12px; height:40px ">{{ med.name }}</div>
          
        </v-col>
      </v-row>
      <v-row class="grey lighten-2">
        <v-col cols="12">
          <v-img @click="onOpenMedia"
            :src="selected_variant_uri"
            height="120"
            contain
          >
            <v-overlay :opacity="0.4" absolute v-if="med.media_type === 2">
              <v-row class="fill-height" align="center" justify="center">
                <v-btn icon>
                  <v-avatar size="40" color="primary">
                    <v-icon>play_arrow</v-icon>
                  </v-avatar>
                </v-btn>
              </v-row>
            </v-overlay>
          </v-img>
        <v-row class="grey darken-2 mt-1" align-content="center">
          <v-col align="left">
            <div class="text-xs-left">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-chip v-on="on" :class="(!med.has_hhd || med.hhd_approved === false) ? 'red darken-1 white--text' : 'green darken-1 white--text'" style="font-size: 10px;" small>
                    <v-icon v-if="med.hhd_approved" class="white--text" small style="margin-right: 2px">done</v-icon>
                    <v-icon v-else-if="med.hhd_approved === false" class="white--text" small style="margin-right: 2px">clear</v-icon>
                    <v-icon v-else class="white--text" small style="margin-right: 2px">mdi-help</v-icon>
                    H-HD
                  </v-chip>
                </template>
                <span v-if="med.has_hhd">This media has a horizontal HD variant, which means it works on horizontal screens.</span>
                <span v-else>This media does not have a horizontal HD variant, which means it doesn't work on horizontal screens.</span>
                <span v-if="med.hhd_approved"> And the horizontal HD variant is approved.</span>
                <span v-else-if="med.has_hhd && med.hhd_approved === false"> 
                  But the horizontal HD variant is rejected.
                </span>
                <span v-else> 
                  But the horizontal HD variant is not reviewed.
                </span>
              </v-tooltip>
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col align="right">
            <div class="text-xs-right">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-chip v-on="on" :class="(!med.has_vhd || med.vhd_approved === false) ? 'red darken-1 white--text' : 'green darken-1 white--text'" style="font-size: 10px;" small>
                    <v-icon v-if="med.vhd_approved" class="white--text" small style="margin-right: 2px">done</v-icon>
                    <v-icon v-else-if="med.vhd_approved === false" class="white--text" small style="margin-right: 2px">clear</v-icon>
                    <v-icon v-else class="white--text" small style="margin-right: 2px">mdi-help</v-icon>
                    V-HD
                  </v-chip>
                </template>
                <span v-if="med.has_vhd">This media has a vertical HD variant, which means it works on vertical screens.</span>
                <span v-else>This media does not have a vertical HD variant, which means it doesn't work on vertical screens.</span>
                <span v-if="med.vhd_approved"> And the vertical HD variant is approved.</span>
                <span v-else-if="med.has_vhd && med.vhd_approved === false"> 
                  But the vertical HD variant is rejected.
                </span>
                <span v-else> 
                  But the vertical HD variant is not reviewed.
                </span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
      
        <v-row class="grey darken-1 mt-1" align-content="center">
          <v-col align="left">
            <v-btn-toggle color="blue-grey" v-model="selected_variant" @change="getVariant" mandatory>
              <v-btn text small style="min-width: 30px;">
                H
              </v-btn>
              <v-btn text small style="min-width: 30px;">
                V
              </v-btn>
              <v-btn text small style="min-width: 30px;">
                A
              </v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col align="right">
            <v-chip class="grey darken-1 white--text" small>
              Total: {{ med.variants.length }}
            </v-chip>
          </v-col>
        </v-row>
      </v-col>

      </v-row>
 
    </v-container>
  </v-card>
</template>

<script>
  export default {
    props: {
      value: Object,
      openMediaClicked: Function
    },
    data () {
      return {
        selected_variant: 0,
        selected_variant_uri: ''
      }
    },
    computed: {
      med: {
        get () {
          return this.value
        },
        set (med) {
          this.$emit('input', med)
        }
      }
    },
    methods: {
      onOpenMedia () {
        this.openMediaClicked(this.med)
      },
      getVariant () {
        var pr = null
        if (this.selected_variant === 0) {
          pr = this.getHorizontalVariant(this.med.variants)
        } else if (this.selected_variant === 1) {
          pr = this.getVerticalVariant(this.med.variants)
        } else {
          pr = this.getAnyVariant(this.med.variants)
        }
        this.selected_variant_uri = (pr !== undefined && pr.thumbnail_m !== null) ? pr.thumbnail_m : require('@/assets/no_image.jpg')

      },
      getHorizontalVariant (images) {
        if (images === null || images === undefined) {
          return {thumbnail_m: ''}
        }
        for (var i = 0; i < images.length; i++) {
          var image = images[i]
          if (image.is_hhd) {
            return image
          }
        }
      },
      getVerticalVariant (images) {
        if (images === null || images === undefined) {
          return {thumbnail_m: ''}
        }
        for (var i = 0; i < images.length; i++) {
          var image = images[i]
          if (image.is_vhd) {
            return image
          }
        }
      },
      getAnyVariant (images) {
        if (images === null || images === undefined) {
          return {thumbnail_m: ''}
        }
        for (var i = 0; i < images.length; i++) {
          var image = images[i]
          if (!image.is_vhd && !image.is_hhd) {
            return image
          }
        }
      },
      getPrimaryImage (images) {
        if (images === null || images === undefined) {
          return {thumbnail_m: ''}
        }
        for (var i = 0; i < images.length; i++) {
          var image = images[i]
          if (image.is_primary) {
            return image
          }
        }
      }
    },
    created () {
      var selected = -1
      var pr = this.getPrimaryImage(this.med.variants)
      if (pr.is_hhd) {
        selected = 0
      } else if (pr.is_vhd) {
        selected = 1
      } else {
        selected = 2
      }
      this.selected_variant = selected
      this.selected_variant_uri = (pr !== undefined && pr.thumbnail_m !== null) ? pr.thumbnail_m : require('@/assets/no_image.jpg')

    }
  }
</script>
