<template>
  <v-content>
    <loading-dialog v-model="isLoading"></loading-dialog>
    <v-dialog v-model="review_media" max-width="1000px" persistent  scrollable>
      <v-card v-if="review_request!== null">
        <v-card-title class="font-15">
          Review Media : {{media_type}}
          <v-spacer></v-spacer>
          <countdown :time="10 * 60 * 1000" ref="countdown" emit-events :key="count_down_key" @end="pickUnpickReviewRequest('unpick')">
            <template slot-scope="props">
              <span class="font-13">You Have: </span>
              <span class="font-13 bold">{{ props.minutes }} minutes {{ props.seconds }} seconds</span>
            </template>
          </countdown>
        </v-card-title>
        <v-card-text >
          <v-row  v-if="review_request.media.media_type === 1  ">
               
        <v-row  justify="center" class="mx-0 px-0 my-0 py-0" v-for="(img,i) in review_request.media.variants" :key="i">
        <v-col v-if="img.is_hhd">
          <v-img contain
          @click="variantDialogHhd=true"
                :src="img.file"
             style="  border: 2px solid #ddd; border-radius: 4px; padding: 0px; width: 550px; "></v-img>
                <p> Horizontal HD</p>
                  <media-viewer v-model="variantDialogHhd" :url="img.file"
                        :video="img.media_type === 2" :title="img.name" :mediaClosed="closeVariant">
                        </media-viewer>
        </v-col>
          <v-col  v-if="img.is_vhd ">
          <v-img contain
          @click="variantDialogVhd=true"
                :src="img.file"
                 style="  height: 40vh;border: 2px solid #ddd; width:40vh "></v-img>
                 <p v-if="img.is_vhd "> Vertical HD</p>
                   <media-viewer v-model="variantDialogVhd" :url="img.file"
                        :video="img.media_type === 2" :title="img.name" :mediaClosed="closeVariant">
                        </media-viewer>
          </v-col>
         </v-row>
          </v-row>
       
           <div  v-if="review_request.media.media_type === 2  ">
          
            <!-- <v-btn-toggle
              v-model="selected_slide"
              mandatory
            >
              <v-btn v-for="(item, i) in review_request.media.variants" :key="i">
                {{ getVariantText(item) }}
              </v-btn>
            </v-btn-toggle>
            <video-player 
              class="video-player-box text-center"
              ref="videoPlayer"
              :options="playerOptions"
              :playsinline="true">
            </video-player> -->
            <v-row >
              <v-row  justify="center" class="mx-0 px-0 my-0 py-0" v-for="(img,i) in review_request.media.variants" :key="i">
        <v-col v-if="img.is_hhd">
          <v-img  :src="img.thumbnail_m !== null ? img.thumbnail_m : require('@/assets/no_image.jpg')"
            contain
             @click="variantDialogVHhd=true"
             style="  border: 2px solid #ddd; border-radius: 4px; padding: 0px; width: 450px; "></v-img>
                <p> Horizontal HD</p>
                  <media-viewer v-model="variantDialogVHhd" :url="img.file" v-if="variantDialogVHhd"
                        :video="review_request.media.media_type === 2 " :title="img.name" :mediaClosed="closeVariant">
                        </media-viewer>
        </v-col>
          <v-col  v-if="img.is_vhd ">
          <v-img    @click="variantDialogVVhd=true"
          :src="img.thumbnail_m !== null ? img.thumbnail_m : require('@/assets/no_image.jpg')"
             contain
                 style="  height: 40vh;border: 2px solid #ddd; width:40vh "></v-img>
                 <p v-if="img.is_vhd "> Vertical HD</p>
                   <media-viewer v-model="variantDialogVVhd" :url="img.file" v-if="variantDialogVVhd"
                        :video="review_request.media.media_type === 2 " :title="img.name" :mediaClosed="closeVariant">
                        </media-viewer>
          </v-col>
     </v-row>
            </v-row>
          </div>
          
          <v-row justify="center">
               <v-col md="4" lg="4" >
            <v-card min-width="250px" min-height="175px" raised color="secondary">
               <v-card-title class="font-13">
              Partner Details : 
                  </v-card-title>
              <v-card-text class="pb-0">
        
                <table class="table ">
                   <tbody class=".table-bordered">
                    <tr>
                      <td ><div >  Name :   </div></td>
                      <td>{{ review_request.owner.name }} </td>
                    </tr>
              
                    <tr>
                      <td ><div >Category  :</div></td>
                      <td><span >{{ review_request.owner.category_name }}</span></td>
                    </tr>
            
                      <tr>
                      <td ><div >Sub Category  :</div></td>
                      <td><span >{{ review_request.owner.sub_category_name   }}</span></td>
                    </tr>
                 
                   </tbody>
                </table>
              </v-card-text>
          
            </v-card>
            </v-col>
           
            <v-col cols="12" md="6" v-if="getMediaChoices() !== false">
              <v-card raised color="secondary">
                <v-card-title class="font-13">
                  Review Type
                </v-card-title>
                <v-card-text>
                  <v-radio-group v-model="review_type">
                    <v-tooltip 
                      bottom
                      v-for="(item, key) in getMediaChoices()"
                      :key="key">
                      <template v-slot:activator="{ on }">
                        <v-radio
                          v-on="on"
                          :label="item.text"
                          :value="item.value">
                        </v-radio>
                      </template>
                      <span>{{ item.tooltip }}</span>
                    </v-tooltip>
                  </v-radio-group>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="6" v-if="getMediaChoices() !== false && review_type === 0">
              <v-card raised color="secondary">
                <v-card-title class="font-13">
                  Single Review
                </v-card-title>
                <v-card-text>
                  <v-select
                    label="Category"
                    :items="categories"
                    item-text="name"
                    item-value="category_id"
                    @change="getSubcategories('single')"
                    v-model="single_review.category"
                    dense
                    solo
                    >    
                  </v-select>
                  <v-select
                    label="Subcategory"
                    :items="single_subcategories"
                    item-text="name"
                    item-value="sub_category_id"
                    v-model="single_review.sub_category"
                    dense
                    solo
                    >    
                  </v-select>
                  <v-select
                    label="Status"
                    :items="media_status_choices"
                    v-model="single_review.media_status"
                    @change="statusChanged('single')"
                    dense
                    solo>
                  </v-select>
                  <v-select
                    v-if="single_review.media_status === 1"
                    label="Reject Reason"
                    v-model="single_review.reject_reason"
                    :items="reject_reasons"
                    item-text="reason"
                    item-value="reject_reason_id"
                    dense
                    solo>
                  </v-select>
                  <v-select
                    label="Media Quality Class"
                    :items="media_quality_classes"
                    v-model="single_review.media_quality_class"
                    dense
                    solo>
                  </v-select>
                  <v-checkbox
                    label="Safe Media"
                    v-model="single_review.is_safe_media"
                    dense>
                  </v-checkbox>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
           <v-row justify="center">
            <v-col cols="12" md="6" 
              v-if="(getMediaChoices() !== false && review_type === 1) 
                || (getMediaChoices() === false && review_request.media.has_hhd)">
              <v-card raised color="secondary">
                <v-card-title class="font-13">
                  HHD Review
                </v-card-title>
                <v-card-text>
                  <v-select
                    label="Category"
                    :items="categories"
                    item-text="name"
                    item-value="category_id"
                    v-model="hhd_review.category"
                    @change="getSubcategories('hhd')"
                    dense
                    solo
                    >    
                  </v-select>
                  <v-select
                    label="Subcategory"
                    :items="hhd_subcategories"
                    item-text="name"
                    item-value="sub_category_id"
                    v-model="hhd_review.sub_category"
                    dense
                    solo
                    >    
                  </v-select>
                  <v-select
                    label="Status"
                    :items="media_status_choices"
                    v-model="hhd_review.media_status"
                    @change="statusChanged('hhd')"
                    dense
                    solo>
                  </v-select>
                  <v-select
                    v-if="hhd_review.media_status === 1"
                    label="Reject Reason"
                    v-model="hhd_review.reject_reason"
                    :items="reject_reasons"
                    item-text="reason"
                    item-value="reject_reason_id"
                    dense
                    solo>
                  </v-select>
                  <v-select
                    label="Media Quality Class"
                    :items="media_quality_classes"
                    v-model="hhd_review.media_quality_class"
                    dense
                    solo>
                  </v-select>
                  <v-checkbox
                    label="Safe Media"
                    v-model="hhd_review.is_safe_media"
                    dense>
                  </v-checkbox>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="6" 
              v-if="(getMediaChoices() !== false && review_type === 1) 
                || (getMediaChoices() === false && review_request.media.has_vhd)">
              <v-card raised color="secondary">
                <v-card-title class="font-13">
                  VHD Review
                </v-card-title>
                <v-card-text>
                  <v-select
                    label="Category"
                    :items="categories"
                    item-text="name"
                    item-value="category_id"
                    @change="getSubcategories('vhd')"
                    v-model="vhd_review.category"
                    dense
                    solo
                    >    
                  </v-select>
                  <v-select
                    label="Subcategory"
                    :items="vhd_subcategories"
                    item-text="name"
                    item-value="sub_category_id"
                    v-model="vhd_review.sub_category"
                    dense
                    solo
                    >    
                  </v-select>
                  <v-select
                    label="Status"
                    :items="media_status_choices"
                    v-model="vhd_review.media_status"
                    @change="statusChanged('vhd')"
                    dense
                    solo>
                  </v-select>
                  <v-select
                    v-if="vhd_review.media_status === 1"
                    label="Reject Reason"
                    v-model="vhd_review.reject_reason"
                    :items="reject_reasons"
                    item-text="reason"
                    item-value="reject_reason_id"
                    dense
                    solo>
                  </v-select>
                  <v-select
                    label="Media Quality Class"
                    :items="media_quality_classes"
                    v-model="vhd_review.media_quality_class"
                    dense
                    solo>
                  </v-select>
                  <v-checkbox
                    label="Safe Media"
                    v-model="vhd_review.is_safe_media"
                    dense>
                  </v-checkbox>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>


        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="pickUnpickReviewRequest('unpick')" small>
            Cancel
          </v-btn>
          <v-btn text @click="submitReviewRequest" small>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-content>
</template>

<script>
  export default {
    props: {
      review_request_id: Number,
      reviewClosed: Function,
      reviewFinished: Function
    },
    data () {
      return {
        isLoading: false,
        review_media: false,
        review_request: null,
        selected_slide: 0,
        count_down_integer: 0,
        count_down_key: 'Count_Down_0',
        review_type: 0,
        media_type:null,
        categories: [],
        hhd_subcategories: [],
        vhd_subcategories: [],
        single_subcategories: [],
        reject_reasons: [],
        variantDialogHhd:false,
        variantDialogVhd:false,
        variantDialogVHhd:false,
        variantDialogVVhd:false,
        media_status_choices: [
          {text: 'Approved', value: 0},
          {text: 'Rejected', value: 1}
        ],
        media_quality_classes: [
          {text: 'High', value: 1},
          {text: 'Normal', value: 2},
          {text: 'Fair', value: 3},
          {text: 'Low', value: 4}
        ],
        playerOptions: {
          autoplay: false,
          muted: false,
          language: 'en',
          displayDuration: true,
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [{
            type: 'video/mp4',
            src: ''
          }],
          poster: "@/assets/logo.png",
        },
        hhd_review: {
          category: null,
          sub_category: null,
          is_authorized: true,
          is_safe_media: false,
          media_quality_class: null,
          media_status: null,
          is_rejected: false,
          reject_reason: null
        },
        vhd_review: {
          category: null,
          sub_category: null,
          is_authorized: true,
          is_safe_media: false,
          media_quality_class: null,
          media_status: null,
          is_rejected: false,
          reject_reason: null
        },
        single_review: {
          category: null,
          sub_category: null,
          is_authorized: true,
          is_safe_media: false,
          media_quality_class: null,
          media_status: null,
          is_rejected: false,
          reject_reason: null
        }
      }
    },
    watch: {
      review_request_id (value) {
        if (value !== 0) {
          this.getReviewRequest()
        }
      },
      selected_slide (value) {
        if (this.review_request.media.media_type === 2) {
          this.playerOptions.sources[0].src = this.review_request.media.variants[value].file
        }
      },
      review_request(value){
        if(value.media.media_type === 1){
          this.media_type="Image"
        }else if(value.media.media_type ===2){
          this.media_type="Video" 
        }
      }
      
    },
    methods: {
      closeReviewDialog () {
        this.review_media = false
        setTimeout(function () {
          this.hhd_review = {
            category: null,
            sub_category: null,
            is_authorized: true,
            is_safe_media: false,
            media_quality_class: null,
            media_status: null,
            is_rejected: false,
            reject_reason: null
          }
          this.vhd_review = {
            category: null,
            sub_category: null,
            is_authorized: true,
            is_safe_media: false,
            media_quality_class: null,
            media_status: null,
            is_rejected: false,
            reject_reason: null
          }
          this.single_review = {
            category: null,
            sub_category: null,
            is_authorized: true,
            is_safe_media: false,
            media_quality_class: null,
            media_status: null,
            is_rejected: false,
            reject_reason: null
          }
        }, 300)
        this.playerOptions.sources[0].src = ''
        this.reviewClosed()
      },
      getReviewRequest () {
        this.isLoading = true
        this.$http.get(this.$store.getters.pubsServer + '/api/media_reviews/review_requests/' + this.review_request_id + '/', { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.review_request = response.body
          const list= this.review_request.media.variants
          const variants=[]
          for (const key in list) {
            if(this.getVariantText(list[key])!=="General Varaint"){
                 variants.push(list[key])
            }
          }
          this.review_request.media.variants=variants
          this.review_media = true
          this.pickUnpickReviewRequest('pick')
          this.count_down_integer++
          this.count_down_key = 'Count_Down_' + this.count_down_integer
          if (this.review_request.media !== null && this.review_request.media.media_type === 2) {
            if (this.review_request.media.variants.length > 0) {
              this.playerOptions.sources[0].src = this.review_request.media.variants[0].file
            }
          }
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
        })
      },
      pickUnpickReviewRequest (type) {
        this.isLoading = true
        this.$http.put(this.$store.getters.pubsServer + '/api/media_reviews/pick_review_action/' + this.review_request_id + '/?action=' + type, {}, { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          if (type === 'unpick') {
            this.closeReviewDialog()
          }
        }, error => {
          this.isLoading = false
          console.log(error)
          if (error.status === this.unauthorized) {
            
          }
          if (type === 'unpick') {
            this.closeReviewDialog()
          }
        })
      },
      closeVariant () {
        this.variantDialogVhd = false
        this.variantDialogHhd =false
        this.selectedVariant = {}
      },
      submitReviewRequest () {
        var review_object = {}
        review_object.uses_single_review = false
        if (this.getMediaChoices() !== false) {
          review_object.uses_single_review = this.review_type === 0
        }
        if (review_object.uses_single_review) {
          review_object.single_review = Object.assign({}, this.single_review)
          if (review_object.single_review.is_authorized) {
            review_object.single_review.reject_reason = undefined
          }
        } else {
          if (this.review_request.media.has_hhd) {
            review_object.hhd_review = Object.assign({}, this.hhd_review)
            if (review_object.hhd_review.is_authorized) {
              review_object.hhd_review.reject_reason = undefined
            }
          }
          if (this.review_request.media.has_vhd) {
            review_object.vhd_review = Object.assign({}, this.vhd_review)
            if (review_object.vhd_review.is_authorized) {
              review_object.vhd_review.reject_reason = undefined
            }
          }
        }
        this.isLoading = true
        this.$http.put(this.$store.getters.pubsServer + '/api/media_reviews/review_requests/' + this.review_request_id + '/', review_object, { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.closeReviewDialog()
          if (this.reviewFinished) {
            this.reviewFinished()
          }
        }, error => {
          this.isLoading = false
          console.log(error)
          if (error.status === this.unauthorized) {
            
          }
        })
      },
      getCategories () {
        this.isLoading = true
        this.$http.get(this.$store.getters.pubsServer + '/api/common/categories/', { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.categories = response.body
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
        })
      },
      getSubcategories (type) {
        this.isLoading = true
        var category_id = null
        if (type === 'single') {
          category_id = this.single_review.category
        } else if (type === 'hhd') {
          category_id = this.hhd_review.category
        } else if (type === 'vhd') {
          category_id = this.vhd_review.category
        }
        this.$http.get(this.$store.getters.pubsServer + '/api/common/categories/' + category_id + '/', { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          if (type === 'single') {
            this.single_subcategories = response.body.sub_categories
          } else if (type === 'hhd') {
            this.hhd_subcategories = response.body.sub_categories
          } else if (type === 'vhd') {
            this.vhd_subcategories = response.body.sub_categories
          }
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
        })
      },
      getRejectReasons () {
        this.isLoading = true
        this.$http.get(this.$store.getters.pubsServer + '/api/common/reject_reasons/', { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.reject_reasons = response.body
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
        })
      },
      statusChanged (type) {
        if (type === 'single') {
          if (this.single_review.media_status === 0) {
            this.single_review.is_authorized = true
            this.single_review.is_rejected = false
          } else if (this.single_review.media_status === 1) {
            this.single_review.is_authorized = false
            this.single_review.is_rejected = true
          }
        } else if (type === 'hhd') {
          if (this.hhd_review.media_status === 0) {
            this.hhd_review.is_authorized = true
            this.hhd_review.is_rejected = false
          } else if (this.hhd_review.media_status === 1) {
            this.hhd_review.is_authorized = false
            this.hhd_review.is_rejected = true
          }
        } else if (type === 'vhd') {
          if (this.vhd_review.media_status === 0) {
            this.vhd_review.is_authorized = true
            this.vhd_review.is_rejected = false
          } else if (this.vhd_review.media_status === 1) {
            this.vhd_review.is_authorized = false
            this.vhd_review.is_rejected = true
          }
        }
      },
      getMediaChoices () {
        if (this.review_request === null || this.review_request.media === null) {
          return false
        }

        if (this.review_request.media.has_hhd && this.review_request.media.has_vhd) {
          return [
            {text: 'Single Review', value: 0, tooltip: 'Single Review for Both HHD and VHD'},
            {text: 'HHD and VHD Seperated', value: 1, tooltip: 'Each Variant has seperate review'}
          ]
        } else {
          return false
        }
      }
    },
    created () {
      if (this.review_request_id !== 0) {
        this.getReviewRequest()
      }
      this.getCategories()
      this.getRejectReasons()
    }
  }
</script>

<style>
  
  .vjs_video_434-dimensions, .video-js {
    width: 100%;
    height: 60vh;
  }

</style>