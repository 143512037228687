<template>
  <v-container fluid class="py-0">
    <k-alert :visible="kDialog" :title="kDialogTitle" :text="kDialogText" :okCallBack="logOut"></k-alert>
    <v-snackbar
    class="white--text"
    color="red"
    :timeout="4000"
    top
    v-model="errorSnackbar">
    {{ errorTxt }}
  </v-snackbar>

    <!-- Skeleton Loader -->
    <v-card v-if="isPageLoading" class="transparent elevation-0 pb-3">
      <v-skeleton-loader
        type="card-heading"
        tile
      ></v-skeleton-loader>
      <v-row justify="center">
        <v-col cols="12">
          <v-row>
            <v-col cols="12" v-for="k in 7" :key="k">
              <v-skeleton-loader
                type="list-item-three-line"
                tile
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>


    <media-info-dialog v-if="selectedMedia !== null"
      v-model="viewMediaDialog" :selectedMedia="selectedMedia"></media-info-dialog>

    <v-card class="transparent elevation-0 pb-3" v-else>
      <loading-dialog v-model="isLoading" ></loading-dialog>
      <v-toolbar light flat color="grey lighten-5" class="mt-3">
        <v-app-bar-nav-icon v-on:click="handleNav" class="hidden-md-and-up"></v-app-bar-nav-icon>
        <v-toolbar-title class="grey--text text--darken-1 page-title-upper mx-0 px-0">Playlists</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn class="primary btn-apps px-1" v-if="playlistsRole" style="text-transform: none" @click="newPlaylist">
          <v-icon>add</v-icon>
          <span class="px-2 btn-act">New Playlist</span>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
    <v-row justify="center">
    <v-col cols="12">
    <v-dialog v-model="playlistDialog" persistent max-width="500px">
        <v-card>
          <v-card-title class="justify-center mt-12">
            <span class="dialogue-title blue-grey--text text--darken-3">{{ playlistDialogTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-row>
                <v-col cols="12">
                  <v-text-field label="Playlist Name" v-model="editedPlaylistItem.name"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-row align-content="center">
                    <v-checkbox class="compact-form ml-3" label="Shuffle Slots" v-model="editedPlaylistItem.shuffle_playlist_tracks"></v-checkbox>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          dense
                          color="light-blue darken-2"
                          style="cursor: pointer;"
                          dark
                        >help</v-icon>
                      </template>
                      <span>slots will play randomly at each loop. Hint: add more slots and enable shuffling.</span>
                    </v-tooltip>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="dialogue-btn" color="blue darken-1" text @click.native="closePlaylistDialog">Cancel</v-btn>
            <v-btn class="dialogue-btn" color="blue darken-1" text @click.native="savePlaylist">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showMediaDialog" max-width="600px">
        <v-card class="pt-4 pb-4">
          <v-img v-if="showType === 1" :src="showUrl" contain></v-img>
          <video-player
           class="video-player-box text-center"
           ref="videoPlayer"
           v-else
           :options="playerOptions"
           :playsinline="true"
           customEventName="customstatechangedeventname">
          </video-player>
        </v-card>
      </v-dialog>
      <v-row>
        <v-col cols="12" v-for="(item, index) in items" :key="index">
          <v-card color="white">
            <v-layout class="blue-grey darken-2 white--text" style="height: 40px;" align-center>
              <v-card-title>
                <div class="singleline btn-apps">{{ item.name }}</div>
              </v-card-title>
              <v-spacer></v-spacer>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn icon class="mx-1" v-if="playlistsRole" small @click="editPlaylist(item)" title="Edit Playlist">
                  <v-icon color="white">edit</v-icon>
                </v-btn>
                <v-btn icon class="mx-1" v-if="playlistsRole" small @click="" title="Playlist Schedules">
                  <v-icon color="white">schedule</v-icon>
                </v-btn>
                <v-btn icon class="mx-1" v-if="playlistsRole" small @click="" title="Playlist Assignments">
                  <v-icon color="white">assignment</v-icon>
                </v-btn>
              </v-card-actions>
            </v-layout>
            <v-row>
            <v-col md="4" cols="12">
              <v-card-text class="body-2">
                <table class="tableSpacing">
                  <tr>
                    <td align="right"><div class="card-title-text">Total Slots Time:</div></td>
                    <td><span class="card-second-text">{{ getSlotsTotal(item.playlist_slots) }} s</span></td>
                  </tr>
                  <tr>
                    <td align="right"><div class="card-title-text">Shuffle Slots:</div></td>
                    <td><v-icon small class="card-second-text" v-if="item.shuffle_playlist_tracks">done</v-icon>
                    <v-icon small class="card-second-text" v-else>clear</v-icon></td>
                  </tr>
                </table>
              </v-card-text>
            </v-col>
            <v-col cols="12" md="8">
              <v-row justify="end">
                <v-col cols="2" v-for="slot in item.playlist_slots" :key="slot.slot_id" style="cursor: pointer;" @click="showMedia(slot.media.media_type == 1 ? slot.media.thumbs.thumbnail_m : slot.media.thumbs.file_webm, slot.media.media_type)">
                  <v-card class="transparent elevation-0 mr-2">
                    <v-img :height="50" :src="slot.media.thumbs.thumbnail_m !== null ? slot.media.thumbs.thumbnail_m : '/no_image.jpg'" contain>
                      <v-overlay :opacity="0.05" absolute v-if="slot.media.media_type === 2">
                        <v-row class="fill-height" align="center" justify="center">
                          <v-btn icon>
                            <v-avatar size="20" color="primary">
                              <v-icon>play_arrow</v-icon>
                            </v-avatar>
                          </v-btn>
                        </v-row>
                      </v-overlay>
                    </v-img>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            </v-row>
            <v-expansion-panels>
              <v-expansion-panel class="blue-grey lighten-4 light-blue--text text--darken-3">
                <v-expansion-panel-header style="font-size: 12px; text-transform: uppercase;"><b>Slots: {{ item.playlist_slots.length }}</b></v-expansion-panel-header>
                <v-expansion-panel-content class="white">
                  <v-data-table
                    class="white py-5"
                    :headers="headers"
                    :items="item.playlist_slots"
                    hide-default-footer
                    item-key="slot_id">
                    <template v-slot:body="{ items }">
                      <tr v-for="item in items" :key="item.slot_id">
                        <td class="text-center">
                          <v-img :height="40" :width="40" :src="item.media.thumbs.thumbnail_m !== null ? item.media.thumbs.thumbnail_m : '/no_image.jpg'" @click="viewMedia(item.media.media_id)">
                            <v-overlay :opacity="0.05" absolute v-if="item.media.media_type === 2">
                              <v-row class="fill-height" align="center" justify="center">
                                <v-btn icon>
                                  <v-avatar size="20" color="primary">
                                    <v-icon>play_arrow</v-icon>
                                  </v-avatar>
                                </v-btn>
                              </v-row>
                            </v-overlay>
                          </v-img>
                        </td>
                        <td class="text-xs-left singleline light-blue--text text--darken-3" style="max-width: 200px">{{ item.media.name }}</td>
                        <td class="text-center light-blue--text text--darken-3">{{ item.slot_run_time }}</td>
                        <td class="text-center light-blue--text text--darken-3">{{ item.slot_time_units }}</td>
                        <td class="text-center"><v-icon small color="light-blue darken-3" v-if="item.media.has_hhd" style="margin-right: 2px">done</v-icon>
                        <v-icon small color="light-blue darken-3" v-else style="margin-right: 2px">clear</v-icon></td>
                        <td class="text-center"><v-icon color="light-blue darken-3" small v-if="item.media.has_vhd" style="margin-right: 2px">done</v-icon>
                        <v-icon small color="light-blue darken-3" v-else style="margin-right: 2px">clear</v-icon></td>
                        <td class="justify-center layout px-0" v-if="playlistsRole">
                          <v-btn icon class="mx-0" @click="editSlot(index, item)">
                            <v-icon color="light-blue darken-1">edit</v-icon>
                          </v-btn>
                          <v-btn icon class="mx-0" @click="deleteSlot(index, item)">
                            <v-icon color="red lighten-2">delete</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                  <div class="text-center mt-2" v-if="playlistsRole">
                    <v-btn class="primary btn-apps" style="text-transform: none;" @click="newSlot(index)">New Slot</v-btn>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </v-col>
      </v-row>
      </v-col>
      </v-row>
    </v-card>
  <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="justify-center mt-12">
          <span class="dialogue-title blue-grey--text text--darken-3">{{ slotFormTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field label="Slot Run Time (s)" v-model="editedItem.slot_run_time" type="number" disabled></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field label="Number Of Slots" v-model="editedItem.slot_time_units" type="number" :disabled="!editedItem.override_slot_duration"></v-text-field>
              </v-col>
              <v-col cols="12" sm="8">
                <v-checkbox label="Override Number of Slots" v-model="editedItem.override_slot_duration"></v-checkbox>
              </v-col>
              <v-col cols="12" sm="8" v-if="getCurrentEditedSlot().media.media_type === 2">
                <v-checkbox label="Use thumbnail when video stops" v-model="editedItem.use_thumb_on_video_stop"></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="dialogue-btn" color="blue darken-1" text @click.native="close">Cancel</v-btn>
          <v-btn class="dialogue-btn" color="blue darken-1" text @click.native="saveEdit">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <media-dialog v-model="mediaDialog" scope="Partners" :mediaSelectedCallback="submitSlot"></media-dialog>
  </v-container>
</template>

  <script>
  export default {
    name: 'p-playlists',
    props: {
      sideNav: Boolean
    },
    data () {
      return {

        viewMediaDialog: false,
        selectedMedia: null,
        selectedVariant: {},

        items: [],
        selectedPlaylist: -1,
        editedIndex: -1,
        dialog: false,
        mediaDialog: false,
        playlistDialog: false,
        errorTxt: '',
        showMediaDialog: false,
        showUrl: '',
        playerOptions: {
          muted: false,
          language: 'en',
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [{
            type: 'video/mp4',
            src: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm'
          }]
        },
        showType: 1,
        errorSnackbar: false,
        dashboardNav: false,
        isPageLoading: false,
        isLoading: false,
        headers: [
          {
            text: 'Media',
            align: 'left',
            sortable: false,
            value: 'code',
            class: 'tableTitle'
          },
          { text: 'Name', value: 'media', sortable: false, align: 'left', class: 'tableTitle' },
          { text: 'Time (s)', value: 'runTime', sortable: false, align: 'center', class: 'tableTitle' },
          { text: 'Slots', value: 'repeats', sortable: false, align: 'center', class: 'tableTitle' },
          { text: 'H-HD', value: 'hhd', sortable: false, align: 'center', class: 'tableTitle' },
          { text: 'V-HD', value: 'vhd', sortable: false, align: 'center', class: 'tableTitle' }
        ],
        editedItem: {
          use_thumb_on_video_stop: false,
          slot_time_units: 0,
          slot_run_time: 0,
          override_slot_duration: false
        },
        defaultItem: {
          use_thumb_on_video_stop: false,
          slot_time_units: 0,
          slot_run_time: 0,
          override_slot_duration: false
        },
        editedPlaylistItem: {
          name: '',
          shuffle_playlist_tracks: false
        },
        defaultPlaylistItem: {
          name: '',
          shuffle_playlist_tracks: false
        },
        editedPlaylistIndex: -1,
        kDialog: false,
        kDialogTitle: '',
        kDialogText: ''
      }
    },
    computed: {
      isPartnerLogged () {
        if (this.$store.getters.logged_in_account !== null) {
          return this.$store.getters.logged_in_account.account_type === 3
        }
        return false
      },
      playlistsRole () {
        //TEMP
        return true
        //return this.$store.getters.logged_in_account.user_account.playlists_role || 
        //        this.$store.getters.logged_in_account.user_account.is_admin
      },
      publishersUrl () {
        if (this.isPartnerLogged) {
          return this.$store.getters.logged_in_account.server_dns
        }
        return ''
      },
      slotFormTitle () {
        return this.editedIndex === -1 ? 'New Slot' : 'Edit Slot'
      },
      playlistDialogTitle () {
        if (this.editedPlaylistIndex === -1) {
          return 'New Playlist'
        } else {
          return 'Edit Playlist'
        }
      },
      authToken () {
        if (this.isPartnerLogged) {
          return this.$store.getters.logged_in_account.account_token
        }
        return ''
      }
    },
    created () {
      if (this.playlistsRole) {
        this.headers.push({ text: 'Actions', value: 'actions', sortable: false, align: 'center', class: 'tableTitle' })
      }
      this.getPlaylists()
    },
    watch: {
      viewMediaDialog (value) {
        if (!value) {
          this.selectedMedia = null
          this.playerOptions.sources[0].src = ''
        }
      },
      },
    methods: {

      // --- NEW ---
      viewMedia (med_id) {
        //this.selectedMedia = this.retrieveMedia(med_id)
        this.retrieveMedia(med_id)
        this.viewMediaDialog = true
      },
      retrieveMedia (med_id) {
        this.isLoading = true
        this.$http.get(this.publishersUrl + '/api/web_client/partners/media/' + med_id + '/', {
          headers: {
            Authorization: 'Token ' + this.authToken
          }
        })
          .then(
            (res) => {
              this.isLoading = false
              this.selectedMedia = res.data
              //return res.data
              //console.log('Playlists Data: ' + res.body)
            }
          )
          .catch((error) => {
            this.isLoading = false
            if (error.status === this.unauthorized) {
              this.showSessionExpired()
            }
            console.log(error)
          })
      },



      getMediaType (type) {
        if (type === 1) {
          return 'image'
        } else if (type === 2) {
          return 'play_arrow'
        }
        return ''
      },
      getCurrentEditedSlot () {
        if (this.selectedPlaylist !== -1 && this.items.length > 0 && this.items[this.selectedPlaylist].playlist_slots.length > 0 && this.editedIndex !== -1) {
          return this.items[this.selectedPlaylist].playlist_slots[this.editedIndex]
        } else {
          return {media: {media_type: 2}}
        }
      },
      handleNav () {
        this.dashboardNav = this.sideNav
        this.dashboardNav = !this.dashboardNav
        this.$emit('input', this.dashboardNav)
      },
      toggle (index) {
        this.isLoading = true
        this.$http.put(this.$store.getters.pubsServer + '/api/frontends/partners/playlists/' + this.items[index].playlist_id + '/', {
          shuffle_playlist_tracks: !this.items[index].shuffle_playlist_tracks
        }, {
          headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        })
          .then(
            (res) => {
              this.isLoading = false
              this.getPlaylists()
            }
          )
          .catch((error) => {
            this.isLoading = false
            if (error.status === this.unauthorized) {
              this.showSessionExpired()
            }
          })
      },
      showMedia (url, type) {
        this.showUrl = url
        this.showType = type
        if (this.showType === 2) {
          this.playerOptions.sources[0].src = this.showUrl
        }
        this.showMediaDialog = true
      },
      getPlaylists () {
        //  url = 
        this.isPageLoading = true
        this.$http.get(this.$store.getters.pubsServer + '/api/web_client/publishers/playlists/', {
          headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        })
          .then(
            (res) => {
              this.isPageLoading = false
              this.items = res.data
              console.log('Playlists Data: ' + res.body)
            }
          )
          .catch((error) => {
            this.isPageLoading = false
            if (error.status === this.unauthorized) {
              this.showSessionExpired()
            }
          })
      },
      newSlot (playlistIndex) {
        this.selectedPlaylist = playlistIndex
        this.mediaDialog = true
      },
      submitSlot (id) {
        if (this.items[this.selectedPlaylist].playlist_slots.length < 12) {
          this.isLoading = true
          this.$http.post(this.$store.getters.pubsServer + '/api/web_client/partners/playlists/slots/?playlist_id=' + this.items[this.selectedPlaylist].playlist_id,
            {media: id}, {
              headers: {
                Authorization: 'Token ' + this.$store.getters.token
              }
            })
            .then(
              (res) => {
                this.isLoading = false
                this.mediaDialog = false
                this.selectedPlaylist = -1
                this.getPlaylists()
              }
            )
            .catch((error) => {
              this.isLoading = false
              console.log(error)
            })
        } else {
          this.errorTxt = 'We are sorry, this playlist have reached the maximum number of slots'
          // if (error.status === this.unauthorized) {
          //   this.showSessionExpired()
          // }
          this.errorSnackbar = true
        }
      },
      getPrimaryImage (images) {
        for (var i = 0; i < images.length; i++) {
          var image = images[i]
          if (image.is_primary) {
            return image
          }
        }
        return images[0]
      },
      editSlot (playlistIndex, item) {
        this.selectedPlaylist = playlistIndex
        this.editedIndex = this.items[playlistIndex].playlist_slots.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
      saveEdit () {
        this.isLoading = true
        this.$http.put(this.$store.getters.pubsServer + '/api/web_client/partners/playlists/slots/' + this.items[this.selectedPlaylist].playlist_slots[this.editedIndex].slot_id + '/',
          this.editedItem, {
            headers: {
              Authorization: 'Token ' + this.$store.getters.token
            }
          })
          .then(
            (res) => {
              this.isLoading = false
              // Object.assign(this.items[this.selectedPlaylist].playlist_slots[this.editedIndex], this.editedItem)
              this.getPlaylists()
              this.close()
              this.selectedPlaylist = -1
            }
          )
          .catch((error) => {
            this.isLoading = false
            if (error.status === this.unauthorized) {
              this.showSessionExpired()
            }
            console.log(error)
          })
      },
      deleteSlot (playlistIndex, item) {
        this.selectedPlaylist = playlistIndex
        const index = this.items[this.selectedPlaylist].playlist_slots.indexOf(item)
        confirm('Are you sure you want to delete this slot?') && this.confirmDelete(index, item.slot_id)
      },
      confirmDelete (index, id) {
        this.isLoading = true
        this.$http.delete(this.$store.getters.pubsServer + '/api/web_client/partners/playlists/slots/' + id + '/',
          {
            headers: {
              Authorization: 'Token ' + this.$store.getters.token
            }
          })
          .then(
            (res) => {
              this.isLoading = false
              this.items[this.selectedPlaylist].playlist_slots.splice(index, 1)
              this.selectedPlaylist = -1
            }
          )
          .catch((error) => {
            this.isLoading = false
            if (error.status === this.unauthorized) {
              this.showSessionExpired()
            }
            console.log(error)
          })
      },
      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
          this.selectedPlaylist = -1
        }, 300)
      },
      newPlaylist () {
        this.playlistDialog = true
      },
      getSlotsTotal (slots) {
        var total = 0
        for (var i = 0; i < slots.length; i++) {
          var slot = slots[i]
          // if (slot.override_slot_duration) {
          total += slot.slot_run_time
          // * slot.slot_time_units
          // } else {
          //  total += 10 * slot.slot_time_units
          // }
        }
        return total
      },
      savePlaylist () {
        if (this.editedPlaylistIndex === -1) {
          this.isLoading = true
          this.$http.post(this.$store.getters.pubsServer + '/api/web_client/partners/playlists/',
            this.editedPlaylistItem, {
              headers: {
                Authorization: 'Token ' + this.$store.getters.token
              }
            })
            .then(
              (res) => {
                this.isLoading = false
                this.closePlaylistDialog()
                this.getPlaylists()
              }
            )
            .catch((error) => {
              this.isLoading = false
              if (error.status === this.unauthorized) {
                this.showSessionExpired()
              }
              console.log(error)
            })
        } else {
          this.isLoading = true
          this.$http.put(this.$store.getters.pubsServer + '/api/web_client/partners/playlists/' + this.items[this.editedPlaylistIndex].playlist_id + '/', {
            shuffle_playlist_tracks: this.editedPlaylistItem.shuffle_playlist_tracks,
            name: this.editedPlaylistItem.name
          }, {
            headers: {
              Authorization: 'Token ' + this.$store.getters.token
            }
          })
            .then(
              (res) => {
                this.isLoading = false
                Object.assign(this.items[this.editedPlaylistIndex], this.editedPlaylistItem)
                this.closePlaylistDialog()
              }
            )
            .catch((error) => {
              this.isLoading = false
              if (error.status === this.unauthorized) {
                this.showSessionExpired()
              }
              console.log(error)
            })
        }
      },
      editPlaylist (item) {
        this.editedPlaylistIndex = this.items.indexOf(item)
        this.editedPlaylistItem = Object.assign({}, item)
        this.playlistDialog = true
      },
      closePlaylistDialog () {
        this.playlistDialog = false
        setTimeout(() => {
          this.editedPlaylistItem = Object.assign({}, this.defaultPlaylistItem)
          this.editedPlaylistIndex = -1
        }, 300)
      },
      logOut () {
        this.kDialog = false
        this.kDialogTitle = ''
        this.kDialogText = ''
        this.$store.dispatch('onSignOut', {})
        this.$router.push('/login')
      },
      showSessionExpired () {
        this.kDialogTitle = 'Login Session'
        this.kDialogText = 'Login session expired, please login again'
        this.kDialog = true
      }
    }
  }
</script>

<style>

  div.titleTextColor{
    color: #777;
    font-weight: bold;
    font-size: 13px;
  }

  span.secondTextColor{
    color: #616161;
  }

  .seconderyTextColor{
    color: #616161;
  }

  .tableSpacing{
    border-spacing: 10px 0;
  }

  th.column.text-xs-left.tableTitle{
    color: #2E2E2E;
    font-size: 13px;
    font-weight: bold;
  }

  .singleline{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

</style>
