<template>
  <v-snackbar
      v-model="isOpen"
      color="error"
      :timeout="3000"
      top
    >
      {{ errorMessage }}
  </v-snackbar>
</template>

<script>
  export default {
    props: {
      value: Boolean,
      errorMessage: String
    },
    computed: {
      isOpen: {
        get () {
          return this.value
        },
        set (isOpen) {
          this.$emit('input', isOpen)
        }
      }
    }
  }
</script>