<template>
  <v-btn class="btn--dark-flat-focused jbtn-file" :small="small" style="text-transform: none">
    {{ title }}<input id="selectFile" type="file" :accept="fileTypes" v-on:change="fileSelected">
  </v-btn>
</template>

<script>
  export default {
    name: 'upload-button',
    props: {
      selectedCallback: Function,
      title: String,
      mimeType: String,
      small: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      fileTypes () {
        return this.mimeType
      }
    },
    methods: {
      fileSelected (e) {
        if (this.selectedCallback) {
          if (e.target.files[0]) {
            this.selectedCallback(e.target.files[0])
          } else {
            this.selectedCallback(null)
          }
        }
      }
    }
  }
</script>

<style scoped>
  .jbtn-file {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .jbtn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    cursor: inherit;
    display: block;
  }
</style>
