<template>
  <v-content>
    <loading-dialog v-model="isLoading"></loading-dialog>
    <v-dialog v-model="isOpen" max-width="500px" scrollable>
      <v-card>
        <v-card-title class="font-14">Location Profile</v-card-title>
        <v-card-text style="height: 500px;">
          <v-text-field
            class="mt-2"
            v-model="profile_data.average_weekday_visitors"
            dense
            outlined
            type="number"
            label="Average Weekday Visitors">
          </v-text-field>
          <v-text-field
            v-model="profile_data.average_weekend_visitors"
            dense
            outlined
            type="number"
            label="Average Weekend Visitors">
          </v-text-field>
          <v-text-field
            v-model="profile_data.visitor_average_site_time"
            dense
            outlined
            hide-details
            type="number"
            label="Visitor Stays in Site For (Minutes)">
          </v-text-field>
          <v-checkbox
            v-model="profile_data.open_24_7"
            dense
            label="Open 24/7">
          </v-checkbox>
          <v-text-field
            v-model="profile_data.weekday_open_time"
            dense
            v-if="!profile_data.open_24_7"
            outlined
            type="time"
            label="Weekday Open Time">
          </v-text-field>
          <v-text-field
            v-model="profile_data.weekday_close_time"
            dense
            outlined
            v-if="!profile_data.open_24_7"
            type="time"
            label="Weekday Close Time"
            hide-details>
          </v-text-field>
          <v-checkbox
            v-model="profile_data.open_weekends"
            dense
            v-if="!profile_data.open_24_7"
            label="Open Weekends"
            hide-details>
          </v-checkbox>
          <v-checkbox
            v-model="profile_data.weekend_as_weekdays"
            dense
            v-if="profile_data.open_weekends && !profile_data.open_24_7"
            label="Weekend as Weekdays">
          </v-checkbox>
          <v-text-field
            v-model="profile_data.weekend_open_time"
            dense
            outlined
            v-if="profile_data.open_weekends && !profile_data.open_24_7 && !profile_data.weekend_as_weekdays"
            type="time"
            label="Weekend Open Time">
          </v-text-field>
          <v-text-field
            v-model="profile_data.weekend_close_time"
            dense
            outlined
            v-if="profile_data.open_weekends && !profile_data.open_24_7 && !profile_data.weekend_as_weekdays"
            type="time"
            label="Weekend Close Time">
          </v-text-field>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-combobox
                v-model="profile_data_keywords"
                :hide-no-data="!keyword_search"
                :items="available_keywords"
                :search-input.sync="keyword_search"
                label="Keywords"
                multiple
                dense
                v-on="on"
                small-chips
                deletable-chips
                outlined
              >
                <template v-slot:no-data>
                  <v-list-item color="primary">
                    <v-chip
                      color="accent"
                      label
                      small
                    >
                      {{ keyword_search }}
                    </v-chip>
                  </v-list-item>
                </template>
              </v-combobox>
            </template>
            <span>Press Enter to add Keyword</span>
          </v-tooltip>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small @click="isOpen = false">
            Cancel
          </v-btn>
          <v-btn text small @click="createProfile">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-content>
</template>

<script>
  export default {
    props: {
      value: Boolean,
      location_id: Number,
      profile_object: {
        type: Object,
        default: null
      }
    },
    watch: {
      location_id () {
        if (this.profile_object === null) {
          this.profile_data = Object.assign({}, this.default_profile_data)
        }
      },
      profile_object (value) {
        if (value !== null) {
          this.profile_data = Object.assign({}, value)
        }
      } 
    },
    computed: {
      isOpen: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      },
      profile_data_keywords: {
        get () {
          var keywords = this.profile_data.profile_keywords || []
          var modified_keywords = []
          if (keywords !== null) {
            for (var i = 0; i < keywords.length; i++) {
              modified_keywords.push(keywords[i].word)
            }
          }
          return modified_keywords
        },
        set (value) {
          var keywords = value
          var modified_keywords = []
          if (keywords !== null) {
            for (var i = 0; i < keywords.length; i++) {
              modified_keywords.push({word: keywords[i]})
            }
          }
          this.profile_data.profile_keywords = modified_keywords
        }
      }
    },
    data () {
      return {
        isLoading: false,
        available_keywords: [],
        keyword_search: null,
        default_profile_data: {
          average_weekday_visitors: null,
          average_weekend_visitors: null,
          visitor_average_site_time: null,
          open_24_7: false,
          weekday_open_time: null,
          weekday_close_time: null,
          open_weekends: false,
          weekend_as_weekdays: false,
          weekend_open_time: null,
          weekend_close_time: null,
          profile_keywords: []
        },
        profile_data: {}
      }
    },
    methods: {
      createProfile () {
        if (this.profile_object === null) {
          this.isLoading = true
          this.$http.post(this.$store.getters.pubsServer + '/api/hosts/location_profile/?location_id=' + this.location_id, this.profile_data, { headers: {
              Authorization: 'Token ' + this.$store.getters.token
            }
          }).then(response => {
            this.isLoading = false
            this.isOpen = false
            this.$emit('saved')
          }, error => {
            this.isLoading = false
            if (error.status === this.unauthorized) {
              
            }
            console.log(error)
          })
        } else {
          this.isLoading = true
          this.$http.put(this.$store.getters.pubsServer + '/api/hosts/location_profile/' + this.location_id + '/', this.profile_data, { headers: {
              Authorization: 'Token ' + this.$store.getters.token
            }
          }).then(response => {
            this.isLoading = false
            this.isOpen = false
            this.$emit('saved')
          }, error => {
            this.isLoading = false
            if (error.status === this.unauthorized) {
              
            }
            console.log(error)
          })
        }
      }
    }
  }
</script>