<template>
  <v-card>
    <v-row class="accent mx-0" align-content="center" style="height: 40px;">
      <v-col cols="12" align-self="center">
        <v-card-title>
          <div class="singleline font-14">
            {{ value.owner_name }}
          </div>
        </v-card-title>
      </v-col>
    </v-row>
    <v-row class="grey lighten-2 mx-0">
      <v-col cols="12">
        <v-img
          :src="value.thumbnail_m !== null ? value.thumbnail_m : require('@/assets/no_image.jpg')"
          height="120"
          contain
        >
        </v-img>
      </v-col>
    </v-row>
    <v-card-actions class="primary">
      <v-btn block color="btn_color" rounded small class="textTransformNone" @click="onReviewSelected">
        Review this Media
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    props: {
      value: Object,
      reviewSelected: Function
    },
    methods: {
      onReviewSelected () {
        this.reviewSelected(this.value.review_request_id)
      }
    }
  }
</script>