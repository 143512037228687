<template>
  <div id="hosts">
    <loading-dialog v-model="isLoading"></loading-dialog>
    <HereMap v-if="!isLoading" :center="center" :zoom="zoom" :locations="locations" />
  </div>
</template>

<script>
import HereMap from '../components/HereMapLoc'
export default {
  name: 'hosts',
  components: {
    HereMap
  },
  data() {
    return {
      locations: [],
      zoom: 12,
      center:{ 
        lat: 25.310610, 
        lng: 51.501242
        },
      isLoading: true
    }
  },
  created () {
    this.getMapLocations()
  },
  methods: {
    getMapLocations () {
      this.isLoading = true
      this.$http.get(this.$store.getters.pubsServer + '/api/hosts/locations_express/', { headers: {
          Authorization: 'Token ' + this.$store.getters.token
        }
      }).then(response => {
        this.isLoading = false
        this.locations = response.body
        //console.log("locations_express:", response.body)
      }, error => {
        //console.log("locations_express ERROR")
        this.isLoading = false
        if (error.status === this.unauthorized) {
          
        }
      })
    },
  }
}
</script>

<style>
#hosts {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 10px;
}
</style>
