import Vue from 'vue'

Vue.mixin({
  data () {
    return {
      get gateway () {
        return 'https://gw.oscreens.com/'
      },
      get api_key_header () {
        return {'Api-Key': 'TsRzekdctHPtwAk4sbKsySec7YAhS0L'}
      },
      get here_maps_app_id () {
        return 'LAAWHqlXMelQmQQZr9Nk'
      },
      get here_maps_api_key () {
        return 'gRaOarzekRoFSXgAS4GeESJPbxoDilts4t2pXpLZg4M'
      },
      get unauthorized () {
        return 401
      },
      get bad_request () {
        return 400
      },
      get robot_site_key () {
        return '6LfOA6kUAAAAAOXE4NWB-__Qghgu-xVlmKKxuMd4'
      },
      get no_bar_routes () {
        return ['Login', 'PageNotFound']
      },
      get foregrounds_limit () {
        return 4
      },
      get captions_limit () {
        return 4
      },
    }
  },
  methods: {
    getVariantText (image) {
      var text = ''
      if (image.is_vhd) {
        text = 'Vertical HD'
      } else if (image.is_hhd) {
        text = 'Horizontal HD'
      } else {
        text = 'General Varaint'
      }
      return text
    },
    getPrimaryImage (images) {
      if (images === null || images === undefined) {
        return {thumbnail_m: ''}
      }
      for (var i = 0; i < images.length; i++) {
        var image = images[i]
        if (image.is_primary) {
          return image
        }
      }
    },
    getErrorFromResponse (error) {
      if (error !== undefined) {
        if (error.data === undefined) {
          return 'We are sorry, Something wrong happened'
        }
        if (Array.isArray(error.data)) {
          if (error.data.length > 0) {
            return error.data[0]
          }
        } else {
          if (error.data.detail !== undefined) {
            return error.data.detail
          }
          for (var key in error.data) {
            if (key === 'non_field_errors') { 
              return error.data.non_field_errors[0]
            } else {
              if (Array.isArray(error.data[key])) {
                return error.data[key][0]
              }
            }
          }
        }
      }
      return 'We are sorry, Something wrong happened'
    }
  }
})
