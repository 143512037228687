<template>
  <v-container fluid>
  <v-row justify="center">
    <loading-dialog v-model="isPageLoading"></loading-dialog>
    <v-col cols="12" sm="12" md="12" lg="11" xl="9">
      <v-snackbar
      class="white--text"
      color="red"
      :timeout="4000"
      top
      v-model="errorSnackbar">
      {{ errorTxt }}
    </v-snackbar>
      <k-alert :visible="kDialog" :title="kDialogTitle" :text="kDialogText" :okCallBack="logOut"></k-alert>
      <loading-dialog v-model="isLoading" ></loading-dialog>
      <v-toolbar light flat color="grey lighten-5">
        <v-spacer></v-spacer>
        <v-toolbar-title class="grey--text text--darken-2 page-title-upper mx-0 px-0" style="font-size: 17px !important;"><b>Media</b></v-toolbar-title>
        <v-spacer></v-spacer>
        <u-btn class="primary btn-apps white--text" v-if="playlistsRole" title="New Media" mimeType="image/png, image/jpeg, video/mp4" :selectedCallback="fileSelected">
        </u-btn>
      </v-toolbar>

      <v-divider></v-divider>

      <v-tabs class="mt-2" v-model="currentTab" slider-color="blue darken-1" fixed-tabs>
        <v-tab class="page-tab" ripple :key="0" :style="getTabStyle(0)">
          <v-icon class="mx-3" :key="0" :color="getTabIcnClr(0)">image</v-icon>
          All Media
        </v-tab>
        <v-tab class="page-tab" ripple :key="1" :style="getTabStyle(1)">
          <v-icon class="mx-3" :key="1" :color="getTabIcnClr(1)">photo_library</v-icon>
          Media Groups
        </v-tab>
        <v-tab class="page-tab" ripple :key="2" :style="getTabStyle(2)">
          <v-icon class="mx-3" :key="2" :color="getTabIcnClr(2)">playlist</v-icon>
          Playlists
        </v-tab>
        <v-tab class="page-tab" ripple :key="3" :style="getTabStyle(3)" v-if="playlistsRole">
          <v-icon class="mx-3" :key="3" :color="getTabIcnClr(3)">photo_size_select_large</v-icon>
          Media Creator
        </v-tab>

        <!-- Media TAB -->
        <v-tab-item :key="0">
          <v-row justify="center">
            <v-col cols="12">
              <v-card class="transparent elevation-0 grid">
                <v-container fluid>
                  <v-row>
                    <v-dialog v-model="editedMediaDialog" max-width="500px">
                      <v-card>
                        <v-card-title>
                          <span class="headline">Media Info</span>
                        </v-card-title>
                        <v-card-text>
                          <v-container grid-list-md>
                            <v-row>
                              <v-col md="6" cols="12">
                                <v-text-field name="mediaName" label="Media Name" id="name" v-model="edited_media.name"></v-text-field>
                              </v-col>
                              <v-col md="6" cols="12">
                                <v-text-field name="description" label="Media description" id="name" v-model="edited_media.description"></v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click.native="saveMedia">Save</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="mediaDialog" max-width="1000px">
                      <!--v-card class="grid" color="grey lighten-5"-->
                      <!--v-card class="grid" color="rgb(215, 218, 223, 0.95)"-->
                      <v-card class="grid" color="rgb(250, 250, 250, 0.95)">
                        <v-card-title>
                          <span style="font-size: 15px;">{{ selectedMedia.name }}</span>
                          <v-spacer></v-spacer>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon
                                v-on="on"
                                color="grey"
                                style="cursor: pointer;"
                                dark
                              >help</v-icon>
                            </template>
                            <span>Each media can have 1 Vertical and 1 Horizontal HD versions to work on both Vertical and Horizontal Screens</span>
                          </v-tooltip>
                          <v-btn icon @click.native="editMedia">
                            <v-icon color="grey darken-2">edit</v-icon>
                          </v-btn>
                          <v-btn icon @click.native="deleteMedia">
                            <v-icon color="red darken-2">delete</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text v-if="selectedMedia.description !== null">
                          <div>
                            <span class="grey--text text--darken-3 caption">{{ selectedMedia.description }}</span>
                          </div>
                        </v-card-text>
                        <v-card-actions>
                          <u-btn title="Add Variant" v-if="playlistsRole && currentTab == '0'" :small="true" mimeType="image/png, image/jpeg" :selectedCallback="variantSelected" class="primary white--text">
                          </u-btn>
                          <v-btn v-if="playlistsRole && ((!selectedMedia.has_hhd || !selectedMedia.has_vhd) && selectedMedia.media_type === 1)" class="blue darken-4" small @click.native="hdifyMedia(selectedMedia)" dark>
                            HDify
                          </v-btn>
                        </v-card-actions>
                        <media-viewer v-model="variantDialog" :url="selectedVariant.file"
                        :video="selectedMedia.media_type === 2" :title="selectedMedia.name" :mediaClosed="closeVariant">
                        </media-viewer>
                        <v-container fluid>
                          <v-row>
                            <v-col cols="12" sm="6" md="4" v-for="(variant, key) in selectedMedia.variants" :key="key">
                              <v-card class="black--text blue-grey lighten-5">
                                <v-container fluid>
                                  <v-row>
                                    <v-col cols="12" class="pa-0">
                                      <v-img
                                        :src="variant.thumbnail_m !== null ? variant.thumbnail_m : require('@/assets/no_image.jpg')"
                                        height="100"
                                        @click="openVariant(variant)"
                                        style="cursor: pointer"
                                        contain>
                                        <v-overlay :opacity="0.4" absolute v-if="selectedMedia.media_type === 2">
                                          <v-row class="fill-height" align="center" justify="center">
                                            <v-btn icon>
                                              <v-avatar size="40" color="primary">
                                                <v-icon>play_arrow</v-icon>
                                              </v-avatar>
                                            </v-btn>
                                          </v-row>
                                        </v-overlay>
                                      </v-img>
                                      <v-card-text>
                                        <div class="grey--text text--darken-3 font-13">{{ getVariantText(variant) }}</div>
                                        <div class="grey--text text--darken-3 font-13">Resolution: {{ variant.resolution_x }} x {{ variant.resolution_y }}</div>
                                        <div class="grey--text text--darken-3 font-13">Size: {{ variant.size_mb }} MB</div>
                                        <div class="grey--text text--darken-3 font-13" v-if="variant.is_hhd || variant.is_vhd">
                                          Review Status: 
                                          {{ getReviewStatus(variant) }}
                                        </div>
                                        <div class="grey--text text--lighten-3 font-13" v-else>-</div>
                                        <div class="grey--text text--darken-3 font-13" v-if="selectedMedia.media_type === 2">
                                          Duration (sec.): 
                                          {{ variant.duration_sec }}
                                        </div>
                                        <div class="blue--text text--darken-2 font-13 mt-1" v-if="variant.is_primary">
                                          Primary: 
                                            <v-icon small class='ms-2 blue--text text--darken-2'>done</v-icon>
                                        </div>
                                        <div class="black--text font-13 mt-1" v-else>
                                          <v-btn class="blue darken-1" x-small dark @click="setPrimaryVariant((selectedMedia.media_type === 1) ? variant.image_id : variant.video_id)">
                                            Set As Primary
                                          </v-btn>
                                        </div>
                                      </v-card-text>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-dialog>
                    <yes-no-dialog
                      v-model="mediaDeletionWarningDialog"
                      title="Delete Media"
                      text="Are you sure you want to delete this media?"
                      :yesClicked="confirmDeleteMedia"
                      :noClicked="noDeleteClicked"></yes-no-dialog>
                    <v-col lg="3" md="4" sm="6" cols="12" v-for="(med, i) in media" style="cursor: pointer" :key="i">
                      <media-item v-model="media[i]" :openMediaClicked="openMedia"></media-item>
                    </v-col>
                  </v-row>
                  <v-row v-if="isLoading" justify="center">
                    <v-progress-circular indeterminate :width="5" :size="50" color="primary"></v-progress-circular>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>

        <!-- Media Groups TAB -->
        <v-tab-item :key="1" class="grey lighten-5">

        </v-tab-item>

        <!-- Playlists TAB -->
        <v-tab-item :key="3" class="grey lighten-5">
          <pub-playlists />
        </v-tab-item>

        <!-- Media Creator TAB -->
        <v-tab-item :key="2" v-if="playlistsRole">
          <media-creator :mediaUploaded="mediaCreatorCreated" :mode="creatorMode" :media="creatorMedia" :orientation="creatorOrientation" scope="Publishers"></media-creator>
        </v-tab-item>

        </v-tabs>
    </v-col>
  </v-row>
  </v-container>
</template>

<script>
  export default{
    head () {
      return {
        title: 'Partner Media'
      }
    },
    data () {
      return {
        response: null,
        bottom: false,
        media: [],
        isGettingMedia: false,
        creatorMode: 'create',
        creatorOrientation: 'Horizontal',
        creatorMedia: {},
        mediaDialog: false,
        selected_variant: 0,
        selectedMediaPos: -1,
        selectedMedia: {},
        selectedVariant: {},
        mediaDeletionWarningDialog: false,
        variantDialog: false,
        errorSnackbar: false,
        errorTxt: '',
        isLoading: false,
        isPageLoading: false,
        kDialog: false,
        kDialogTitle: '',
        kDialogText: '',
        currentTab: 0,
        editedMediaDialog: false,
        edited_media: {
          name: '',
          description: ''
        },
        playerOptions: {
          muted: false,
          language: 'en',
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [{
            type: 'video/mp4',
            src: 'https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm'
          }]
        }
      }
    },
    computed: {
      playlistsRole () {
        // TEMP
        return true
        //if (this.$store.getters.logged_in_account === null) {
        //  return false
        //}
        //return this.$store.getters.logged_in_account.user_account.playlists_role || 
        //        this.$store.getters.logged_in_account.user_account.is_admin
      },
    },
    watch: {
      bottom (value) {
        if (value && this.currentTab === 0) {
          this.getMedias()
        }
      }
    },
    methods: {
      bottomVisible() {
        const scrollY = window.scrollY
        const visible = document.documentElement.clientHeight
        const pageHeight = document.documentElement.scrollHeight
        const bottomOfPage = visible + scrollY >= pageHeight
        return bottomOfPage || pageHeight < visible
      },
      getReviewStatus (variant) {
        if (variant.is_hhd) {
          if (this.selectedMedia.hhd_approved !== null && this.selectedMedia.hhd_approved) {
            return 'Approved'
          } else if (this.selectedMedia.hhd_approved !== null && !this.selectedMedia.hhd_approved) {
            return 'Declined'
          } else {
            return 'Not Reviewed'
          }
        } else if (variant.is_vhd) {
          if (this.selectedMedia.vhd_approved !== null && this.selectedMedia.vhd_approved) {
            return 'Approved'
          } else if (this.selectedMedia.vhd_approved !== null && !this.selectedMedia.vhd_approved) {
            return 'Declined'
          } else {
            return 'Not Reviewed'
          }
        }
      },
      noDeleteClicked () {
        this.mediaDeletionWarningDialog = false
        this.mediaDialog = true
      },
      mediaCreatorCreated () {
        this.currentTab = 0
        this.creatorMode = 'create'
        location.reload()
      },
      hdifyMedia (media) {
        if (!media.has_hhd) {
          this.creatorMode = 'hdify'
          this.creatorOrientation = 'Horizontal'
          this.creatorMedia = media
          this.currentTab = 3
          this.mediaDialog = false
          return
        }
        if (!media.has_vhd) {
          this.creatorMode = 'hdify'
          this.creatorOrientation = 'Vertical'
          this.creatorMedia = media
          this.currentTab = 3
          this.mediaDialog = false
        }
      },
      editMedia () {
        this.edited_media.name = this.selectedMedia.name
        this.edited_media.description = this.selectedMedia.description
        this.mediaDialog = false
        this.editedMediaDialog = true
      },
      saveMedia () {
        this.isLoading = true
        this.$http.put(this.$store.getters.pubsServer + '/api/web_client/publishers/media/' + this.selectedMedia.media_id + '/', this.edited_media, {headers: {
          Authorization: 'Token ' + this.$store.getters.token
        }})
          .then(
            (res) => {
              this.isLoading = false
              this.editedMediaDialog = false
              location.reload()
            }
          )
          .catch((error) => {
            this.isLoading = false
            if (error.status === this.unauthorized) {
              this.showSessionExpired()
            }
          })
      },
      deleteMedia () {
        this.mediaDialog = false
        this.mediaDeletionWarningDialog = true
      },
      confirmDeleteMedia () {
        this.isLoading = true
        this.$http.delete(this.$store.getters.pubsServer + '/api/web_client/publishers/media/' + this.selectedMedia.media_id + '/', {headers: {
          Authorization: 'Token ' + this.$store.getters.token
        }})
          .then(
            (res) => {
              this.isLoading = false
              location.reload()
            }
          )
          .catch((error) => {
            this.isLoading = false
            if (error.status === this.unauthorized) {
              this.showSessionExpired()
            }
            this.setError(this.getErrorFromResponse(error))
          })
      },
      setPrimaryVariant (variant_id) {
        this.isLoading = true
        var data = {"variant": variant_id}
        this.$http.put(this.$store.getters.pubsServer + '/api/web_client/publishers/media/set_primary/' + this.selectedMedia.media_id + '/', data, {headers: {
          Authorization: 'Token ' + this.$store.getters.token
        }})
          .then(
            (res) => {
              this.isLoading = false
              location.reload()
            }
          )
          .catch((error) => {
            this.isLoading = false
            if (error.status === this.unauthorized) {
              this.showSessionExpired()
            }
            this.setError(this.getErrorFromResponse(error))
          })
      },
      getMedias (type) {
        if (this.isGettingMedia) {
          return
        }
        var url = null
        if (this.response !== null) {
          if (this.response.links.next !== null) {
            url = this.response.links.next
          } else {
            return
          }
        } else {
          url = this.$store.getters.pubsServer + '/api/web_client/publishers/media/'
        }
        if (this.media.length === 0) {
          this.isPageLoading = true
        } else {
          this.isLoading = true
        }
        this.isGettingMedia = true
        this.$http.get(url, {headers: {
          Authorization: 'Token ' + this.$store.getters.token
        }})
          .then(
            (res) => {
              if (this.media.length === 0) {
                this.isPageLoading = false
              } else {
                this.isLoading = false
              }
              this.bottom = false
              this.response = res.data
 
              if (type === undefined) {
                this.media = this.media.concat(res.data.results)
              } else if (type === 'reload') {
                this.media = []
                this.media = this.media.concat(res.data.results)
              }
              if (this.selectedMediaPos !== -1) {
                this.selectedMedia = this.media[this.selectedMediaPos]
              }
              this.isGettingMedia = false
            }
          )
          .catch((error) => {
            //console.log("Error fetching media:", error)
            //console.log("this.unauthorized:", this.unauthorized)
            if (this.media.length === 0) {
              this.isPageLoading = false
            } else {
              this.isLoading = false
            }
            if (error.status === this.unauthorized) {
              this.showSessionExpired()
            }
            this.isGettingMedia = false
          })
      },
      getPrimaryImage (images) {
        if (images === null || images === undefined) {
          return {thumbnail_m: ''}
        }
        for (var i = 0; i < images.length; i++) {
          var image = images[i]
          if (image.is_primary) {
            return image
          }
        }
      },
      getHorizontalVariant (images) {
        if (images === null || images === undefined) {
          return {thumbnail_m: ''}
        }
        for (var i = 0; i < images.length; i++) {
          var image = images[i]
          if (image.is_hhd) {
            return image
          }
        }
      },
      getVerticalVariant (images) {
        if (images === null || images === undefined) {
          return {thumbnail_m: ''}
        }
        for (var i = 0; i < images.length; i++) {
          var image = images[i]
          if (image.is_vhd) {
            return image
          }
        }
      },
      getAnyVariant (images) {
        if (images === null || images === undefined) {
          return {thumbnail_m: ''}
        }
        for (var i = 0; i < images.length; i++) {
          var image = images[i]
          if (!image.is_vhd && !image.is_hhd) {
            return image
          }
        }
      },
      getResolutionName (aspectType) {
        if (aspectType === 1) {
          return 'Uniform Square'
        } else if (aspectType === 2) {
          return 'Horizontal HD'
        } else if (aspectType === 3) {
          return 'Vertical HD'
        } else if (aspectType === 4) {
          return 'Horizontal Non-Uniform'
        } else {
          return 'Vertical Non-Uniform'
        }
      },
      getImageText (image) {
        var text = ''
        if (image.is_vhd) {
          text += 'Vertical HD '
        } else if (image.is_hhd) {
          text += 'Horizontal HD '
        }
        text += '(' + image.resolution_x + ' X ' + image.resolution_y + ') '
        text += 'size = ' + image.size_mb + ' MB'
        return text
      },
      openMedia (med) {
        const index = this.media.indexOf(med)
        this.selectedMediaPos = index
        this.selectedMedia = med
        this.mediaDialog = true
      },
      openVariant (variant) {
        this.selectedVariant = variant
        if (this.selectedMedia.media_type === 2) {
          this.playerOptions.sources[0].src = variant.file
        }
        this.variantDialog = true
      },
      closeMedia () {
        this.mediaDialog = false
        this.selectedMedia = {}
        this.selectedMediaPos = -1
        this.selectedVariant = {}
      },
      closeVariant () {
        this.variantDialog = false
        this.selectedVariant = {}
      },
      fileSelected (file) {
        if (file === null) {
          return
        }
        var data = new FormData()
        data.set('file', file)
        this.isLoading = true
        if (file.type.includes('image')) {
          this.$http.post(this.$store.getters.pubsServer + '/api/web_client/publishers/images/', data, {headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }})
            .then(
              (res) => {
                this.isLoading = false
                location.reload()
              }
            )
            .catch((error) => {
              this.isLoading = false
              if (error.status === this.unauthorized) {
                this.showSessionExpired()
              }
              this.setError(this.getErrorFromResponse(error))
            })
        } else {
          this.$http.post(this.$store.getters.pubsServer + '/api/web_client/publishers/videos/', data, {headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }})
            .then(
              (res) => {
                this.isLoading = false
                location.reload()
              }
            )
            .catch((error) => {
              this.isLoading = false
              if (error.status === this.unauthorized) {
                this.showSessionExpired()
              }
              this.setError(this.getErrorFromResponse(error))
            })
        }
      },
      setError (text) {
        this.errorTxt = text
        this.errorSnackbar = true
      },
      variantSelected (file) {
        if (file === null) {
          return
        }
        if (this.selectedMedia.variants.length < 3) {
          var data = new FormData()
          data.set('file', file)
          if (file.type.includes('image')) {
            this.isLoading = true
            this.$http.post(this.$store.getters.pubsServer + '/api/web_client/publishers/images/?media_id=' + this.selectedMedia.media_id, data, {headers: {
              Authorization: 'Token ' + this.$store.getters.token
            }})
              .then(
                (res) => {
                  this.isLoading = false
                  location.reload()
                }
              )
              .catch((error) => {
                this.isLoading = false
                if (error.status === this.unauthorized) {
                  this.showSessionExpired()
                }
                this.setError(this.getErrorFromResponse(error))
              })
          } else {
            this.$http.post(this.$store.getters.pubsServer + '/api/web_client/publishers/videos/?media_id=' + this.selectedMedia.media_id, data, {headers: {
              Authorization: 'Token ' + this.$store.getters.token
            }})
              .then(
                (res) => {
                  this.isLoading = false
                  location.reload()
                }
              )
              .catch((error) => {
                this.isLoading = false
                if (error.status === this.unauthorized) {
                  this.showSessionExpired()
                }
                this.setError(this.getErrorFromResponse(error))
              })
          } 
        } else {
          this.setError('We are sorry, This media has reached to the maximum number of variants')
        }
      },
      logOut () {
        this.kDialog = false
        this.kDialogTitle = ''
        this.kDialogText = ''
        this.$store.dispatch('onSignOut', {})
        this.$router.push('/login')
      },
      showSessionExpired () {
        this.kDialogTitle = 'Login Session'
        this.kDialogText = 'Login session expired, please login again'
        this.kDialog = true
      },
      getTabStyle (pos) {
        var activePos = parseInt(this.currentTab)
        if (pos === activePos) {
          return 'text-transform: none; color:dodgerblue; font-weight: bold;'
        }
        return 'text-transform: none; color: grey;'
      },
      getTabIcnClr (pos) {
        var activePos = parseInt(this.currentTab)
        if (pos === activePos) {
          return 'blue darken-1'
        }
        return 'grey'
      }
    },
    created () {
      window.addEventListener('scroll', () => {
        this.bottom = (window.innerHeight + window.scrollY + 10) >= document.documentElement.scrollHeight
      })
      this.getMedias()
    }
  }
</script>

<style>

  .singleline{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .mediaExpansionPanel{
    height: 40px;
  }

</style>
