<template>
  <v-container fluid class="py-0">
   <loading-dialog v-model="isLoading"></loading-dialog>
    <v-card-title class="font-18" v-if="balance">
      <v-row v-if="balance !== null" class="py-0">
       <v-col cols="12" class="py-2 ps-5" >
          <v-card>
            <v-app-bar color="blue-grey darken-3">
              <v-spacer></v-spacer>
              <v-toolbar-title class="font-20  justify-center">Balance</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-app-bar>
          </v-card>
          <v-container class="grey lighten">
            <v-row dense>
              <v-col cols="6" class="pa-3">
                <v-card min-width="200" elevation="2" class="grey darken-3 pa-2">
                  <v-card-title class="font-20 font-weight-black  justify-center">Outstanding Balance</v-card-title>
                  <v-card-text class="mt-3 pb-10 text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <span v-on="on"
                          :class="'font-25 bold ' + (balance.total_balance < 0 ? 'red--text' : 'indigo--text text--darken-1')">
                          ${{ balance.total_balance }}</span>
                      </template>
                      <span v-if="balance.total_balance < 0">Your Account Balance is Negative. Please settle your due amount</span>
                      <span v-else>Your Account Balance. You can use this balance to shcedule your campaings</span>
                    </v-tooltip>
                  </v-card-text>
                </v-card>
              </v-col>


              <v-col cols="6" class="pa-3">
                <v-card class="white" elevation="1">
                  <v-card-text class="mt-1 pb-3 text-center">
                    <v-row>
                      <v-col cols="8" class="pa-0" style="text-align:right">
                        <span class="font-14 bold grey--text text--darken-3 justify-center">Scheduled Slots Sales: </span>
                      </v-col>
                      <v-col cols="3" class="pa-0 ms-2" style="text-align:left">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <span v-on="on" style="text-align:left"
                              class="font-15 bold indigo--text text--darken-1">
                              ${{ balance.scheduled_amount }}
                            </span>
                          </template>
                          <span>Upcoming sales revenues (not confirmed yet)</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="8" class="pa-0" style="text-align:right">
                        <span class="font-13 grey--text text--darken-3 justify-center">Online Campaigns: </span>
                      </v-col>
                      <v-col cols="3" class="pa-0 ms-2" style="text-align:left">
                        <span class="font-13 indigo--text text--darken-1">${{ balance.scheduled_online }}</span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="8" class="pa-0" style="text-align:right">
                        <span class="font-13 grey--text text--darken-3 justify-center">Premium Share: </span>
                      </v-col>
                      <v-col cols="3" class="pa-0 ms-2" style="text-align:left">
                        <span class="font-13 indigo--text text--darken-1">${{ balance.scheduled_share }}</span>
                      </v-col>
                    </v-row>

                  </v-card-text>
                </v-card>

                <v-card class="white" elevation="1">
                  <v-card-text class="mt-1 pb-3 text-center">
                    <v-row>
                      <v-col cols="8" class="pa-0" style="text-align:right">
                        <span class="font-14 bold grey--text text--darken-3 justify-center">Scheduled Purchases: </span>
                      </v-col>
                      <v-col cols="3" class="pa-0 ms-2" style="text-align:left">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <span v-on="on" style="text-align:left"
                              class="font-15 bold red--text text--darken-3">
                              ${{ balance.on_hold_amount }}
                            </span>
                          </template>
                          <span>Upcoming scheduled purchases (amount is on-hold)</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>

              </v-col>
            </v-row>
          </v-container>
        </v-col>


        <v-col cols="12" class="py-2 ps-5">
          <v-card
            class="mx-auto"
          >
            <v-app-bar
              dark
              color="blue-grey darken-3"
            >
              <v-icon class="flip-icon">call_received</v-icon>
              <v-spacer></v-spacer>
              <v-toolbar-title>Month Period {{ balance.month_balance.month }}-{{ balance.month_balance.year }} </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-menu
                :close-on-content-click="true"
                :nudge-left="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    dark
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item>
                    <v-date-picker
                      v-model="balance_month"
                      type="month"
                      light
                      @change="getMonthBalance"
                    ></v-date-picker>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-app-bar>
          </v-card>
          <v-container class="grey lighten">
            <v-row dense>
              <v-col cols="6" class="pa-2">
                <v-col cols="12" class="pa-2">
                  <v-card min-width="300" color="grey darken-3" elevation="2">
                    <v-card-title class="font-16 font-weight-black justify-center">Month Balance</v-card-title>
                    <v-card-text class="mt-1 pb-10 text-center">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <span v-on="on"
                            :class="'font-20 bold ' + (selected_month_balance < 0 ? 'red--text' : 'blue--text text--darken-2')">
                            ${{ selected_month_balance }}</span>
                        </template>
                        <span v-if="balance.total_balance < 0">Your Account Balance is Negative. Please settle your due amount</span>
                        <span v-else>Your Account Balance. You can use this balance to shcedule your campaings</span>
                      </v-tooltip>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" class="pa-2 mt-0">
                  <v-card class="white" elevation="1">
                    <v-card-text class="mt-1 pb-3 text-center">
                      <v-row>
                        <v-col cols="8" class="pa-0" style="text-align:right">
                          <span class="font-14 bold grey--text text--darken-3 justify-center">Slots Sales Revenue: </span>
                        </v-col>
                        <v-col cols="3" class="pa-0 ms-2" style="text-align:left">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <span v-on="on" style="text-align:left"
                                class="font-15 bold indigo--text text--darken-1">
                                ${{ balance.month_balance.cleared_amount }}
                              </span>
                            </template>
                            <span>Total Confirmed Sales Revenue Share</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="8" class="pa-0" style="text-align:right">
                          <span class="font-13 grey--text text--darken-3 justify-center">Online Campaigns: </span>
                        </v-col>
                        <v-col cols="3" class="pa-0 ms-2" style="text-align:left">
                          <span class="font-13 indigo--text text--darken-1">${{ balance.month_balance.cleared_online }}</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="8" class="pa-0" style="text-align:right">
                          <span class="font-13 grey--text text--darken-3 justify-center">Premium Share: </span>
                        </v-col>
                        <v-col cols="3" class="pa-0 ms-2" style="text-align:left">
                          <span class="font-13 indigo--text text--darken-1">${{ balance.month_balance.cleared_share }}</span>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                  <v-card class="white" elevation="1">
                    <v-card-text class="mt-1 pb-3 text-center">
                      <v-row>
                        <v-col cols="8" class="pa-0" style="text-align:right">
                          <span class="font-14 bold grey--text text--darken-3 justify-center">Subscriptions Revenue: </span>
                        </v-col>
                        <v-col cols="3" class="pa-0 ms-2" style="text-align:left">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <span v-on="on" style="text-align:left"
                                class="font-15 bold indigo--text text--darken-1">
                                ${{ balance.month_balance.in_invoice_payments }}
                              </span>
                            </template>
                            <span>Total Subscription Revenue Share</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-col>

              <v-col cols="6" class="pa-2">
                <v-card flat class="white mt-1" elevation="1">
                  <v-card-text class="mt-0 pb-3 text-center">
                    <v-row>
                      <v-col cols="8" class="pa-0" style="text-align:right">
                        <span class="font-14 bold grey--text text--darken-3 justify-center">Collected Payments: </span>
                      </v-col>
                      <v-col cols="3" class="pa-0 ms-2" style="text-align:left">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <span v-on="on" style="text-align:left"
                              class="font-15 bold red--text text--darken-3">
                              ${{ balance.month_balance.in_collect_payments }}
                            </span>
                          </template>
                          <span>Amounts Paid For Your Account in Balance</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card flat class="white mt-1" elevation="1">
                  <v-card-text class="mt-0 pb-3 text-center">
                    <v-row>
                      <v-col cols="8" class="pa-0" style="text-align:right">
                        <span class="font-14 bold grey--text text--darken-3 justify-center">Granted Gifts/Vouchers: </span>
                      </v-col>
                      <v-col cols="3" class="pa-0 ms-2" style="text-align:left">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <span v-on="on" style="text-align:left"
                              class="font-15 bold red--text text--darken-3">
                              ${{ balance.month_balance.out_gift_payments }}
                            </span>
                          </template>
                          <span>Amounts Paid For Your Account in Balance</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card flat class="white mt-1" elevation="1">
                  <v-card-text class="mt-0 pb-3 text-center">
                    <v-row>
                      <v-col cols="8" class="pa-0" style="text-align:right">
                        <span class="font-14 bold grey--text text--darken-3 justify-center">Confirmed Purchases: </span>
                      </v-col>
                      <v-col cols="3" class="pa-0 ms-2" style="text-align:left">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <span v-on="on" style="text-align:left"
                              class="font-15 bold red--text text--darken-3">
                              ${{ balance.month_balance.executed_slots_amount }}
                            </span>
                          </template>
                          <span>Total confirmed purchases</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card flat class="white mt-1" elevation="1">
                  <v-card-text class="mt-0 pb-3 text-center">
                    <v-row>
                      <v-col cols="8" class="pa-0" style="text-align:right">
                        <span class="font-14 bold grey--text text--darken-3 justify-center">Paid Invoices: </span>
                      </v-col>
                      <v-col cols="3" class="pa-0 ms-2" style="text-align:left">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <span v-on="on" style="text-align:left"
                              class="font-15 bold red--text text--darken-3">
                              ${{ balance.month_balance.paid_out_invoices }}
                            </span>
                          </template>
                          <span>Amounts Paid Out of Your Account</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card flat class="white mt-1" elevation="1">
                  <v-card-text class="mt-0 pb-3 text-center">
                    <v-row>
                      <v-col cols="8" class="pa-0" style="text-align:right">
                        <span class="font-14 bold grey--text text--darken-3 justify-center">Paid Out Amounts: </span>
                      </v-col>
                      <v-col cols="3" class="pa-0 ms-2" style="text-align:left">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <span v-on="on" style="text-align:left"
                              class="font-15 bold red--text text--darken-3">
                              ${{ balance.month_balance.paid_out_remits }}
                            </span>
                          </template>
                          <span>Amounts Paid Out of Your Account</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-col>


        <v-col cols="12" class="py-2 ps-5 pl-12">
          <v-card >
            <v-app-bar
              dark
            >
           
              <v-spacer></v-spacer>
              <v-toolbar-title class="font-20  justify-center">Balance In</v-toolbar-title>
              <v-spacer></v-spacer>
             
            </v-app-bar>
          </v-card>
          <v-container>
            <v-row dense>
              <v-col cols="6" class="pa-3">
                <v-card  elevation="1">
                  <v-card-title class="font-18 font-weight-black  justify-center">Confirmed Revenue</v-card-title>
                  <v-card-text class="mt-3 pb-10 text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <span v-on="on" 
                          class="font-20 bold indigo--text text--darken-1">
                          ${{ balance.cleared_amount }}
                        </span>
                      </template>
                      <span>Total Confirmed Sales Revenue</span>
                    </v-tooltip>
                  </v-card-text>
               
                 <hr color=" gray ">
                  <v-card  class=" pa-3 font-12  bold ">
                       <v-row>
                      <v-col>
                        Online
                      </v-col>
                      <v-col>
                      ${{balance.cleared_online}}
                      </v-col>
                    </v-row>
                     <v-row>
                      <v-col>
                        premium
                      </v-col>
                      <v-col>
                      ${{balance.cleared_share}}
                      </v-col>
                    </v-row>
                  </v-card>
                </v-card>
              </v-col>
              <v-col cols="6" class="pa-3">
                <v-card  elevation="1">
                  <v-card-title class="font-18 font-weight-black  justify-center">Scheduled Revenue</v-card-title>
                  <v-card-text class="mt-3 pb-10 text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <span v-on="on" 
                          class="font-20 bold indigo--text text--darken-1">
                          ${{ balance.scheduled_amount }}
                        </span>
                      </template>
                      <span>Upcoming sales revenues (not confirmed yet)</span>
                    </v-tooltip>
                 
                  </v-card-text>
                  <hr color=" gray ">
                  <v-card  class=" pa-3 font-12  bold ">
                       <v-row>
                      <v-col>
                         Online
                      </v-col>
                      <v-col>
                      ${{balance.scheduled_online}}
                      </v-col>
                    </v-row>
                     <v-row>
                      <v-col>
                        premium
                      </v-col>
                      <v-col>
                      ${{balance.scheduled_share}}
                      </v-col>
                    </v-row>
                  </v-card>
                </v-card>

              </v-col>
            </v-row>
          </v-container>
        </v-col>

        <v-col cols="12" class="py-2 ps-5">
          <v-card >
            <v-app-bar
              dark

            >
              <v-spacer></v-spacer>
              <v-toolbar-title class="ms-2">Balance Out</v-toolbar-title>
              <v-spacer></v-spacer>
            
            </v-app-bar>
          </v-card>
          <v-container>
            <v-row dense>
              <v-col cols="6" class="pa-3">
                <v-card flat  elevation="1">
                  <v-card-title class="font-18 font-weight-black  justify-center">Confirmed Purchases</v-card-title>
                  <v-card-text class="mt-3 pb-10 text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <span v-on="on" 
                          class="font-20 bold red--text text--darken-3">
                          ${{ balance.executed_slots_amount }}
                        </span>
                      </template>
                      <span>Total confirmed purchases</span>
                    </v-tooltip>
                  </v-card-text>
                    <hr color=" gray ">
                  <v-card  class=" pa-3 font-12  bold ">
                       <v-row>
                      <v-col>
                        Online
                      </v-col>
                      <v-col>
                      ${{balance.executed_slots_online}}
                      </v-col>
                    </v-row>
                     <v-row>
                      <v-col>
                        premium
                      </v-col>
                      <v-col>
                      ${{balance.executed_slots_share}}
                      </v-col>
                    </v-row>
                  </v-card>
                </v-card>
              </v-col>
              <v-col cols="6" class="pa-3">
                <v-card flat  elevation="1">
                  <v-card-title class="font-18 font-weight-black  justify-center">Scheduled Purchases</v-card-title>
                  <v-card-text class="mt-3 pb-10 text-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <span v-on="on" 
                          class="font-20 bold red--text text--darken-3">
                          ${{ balance.on_hold_amount }}
                        </span>
                      </template>
                      <span>Upcoming scheduled purchases (amount is on-hold)</span>
                    </v-tooltip>
                  </v-card-text>
                </v-card>
                 <hr color=" gray ">
                  <v-card  class=" pa-3 font-12  bold ">
                       <v-row>
                      <v-col>
                       On Hold Online
                      </v-col>
                      <v-col>
                      ${{balance.on_hold_online}}
                      </v-col>
                    </v-row>
                     <v-row>
                      <v-col>
                        On Hold Share
                      </v-col>
                      <v-col>
                      ${{balance.on_hold_share}}
                      </v-col>
                    </v-row>
                  </v-card>
                
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-card-title>
  </v-container>
</template>
<script>
  export default {
   
    data () {
      return {
        isLoading: false,
        balance: null,
        balance_month: null,
        select_month: false,
        selected_month_balance: 0.0,      
      }
      
    },
    
    methods: {
      
      calculateSelectedMonthBalance () {
        if (this.balance === null) {
          this. selected_month_balance = null
          return
        }
        var total_balance = parseFloat(this.balance.month_balance.cleared_amount) + parseFloat(this.balance.month_balance.in_invoice_payments) - parseFloat(this.balance.month_balance.in_collect_payments) - parseFloat(this.balance.month_balance.out_gift_payments) - parseFloat(this.balance.month_balance.paid_out_invoices) - parseFloat(this.balance.month_balance.paid_out_remits) - parseFloat(this.balance.month_balance.executed_slots_amount)
        this.selected_month_balance = total_balance.toString()
      },

      getMonthBalance () {
        this.isLoading = true
        this.select_month = false
        this.$http.get(this.$store.getters.pubsServer + '/api/publishers/balance/month/' + this.balance_month, {
          headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        })
          .then(
            (res) => {
              this.balance.month_balance = res.data
              this.balance_month = this.balance.month_balance.year.toString() + '-' + this.balance.month_balance.month.toString()
              //console.log(this.balance.month_balance)
              this.calculateSelectedMonthBalance()
              this.isLoading = false
            }
          )
          .catch((error) => {
            this.isLoading = false
            if (error.status === this.unauthorized) {
            }
            //console.log(error)
          })
      },

      getBalance(){
        this.$http.get(this.$store.getters.pubsServer + '/api/publishers/balance', { headers: {
         Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.balance = response.body
          this.balance_month = this.balance.month_balance.year.toString() + '-' + this.balance.month_balance.month.toString()
          this.calculateSelectedMonthBalance()
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
          }
        })
      },
       
    },

    created () {
    this.getBalance()
    }
  
  }
</script>

<style>
   td.clickable_td {
    color: #156CA8;
    cursor: pointer;
    /* text-decoration: underline; */
  }

  td.clickable_td:hover {
      color: white;
  }

  div.titleTextColor{
    color: #777;
    font-weight: bold;
    font-size: 13px;
  }

  span.secondTextColor{
    color: #616161;
  }

  .seconderyTextColor{
    color: #616161;
  }

  .dashboardTableSpacing{
    border-spacing: 10px 5px;
  }
</style>
