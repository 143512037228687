<template>
  <v-content>
    <v-dialog v-model="isOpen" max-width="1000px">
      
      <v-card class="grid">
        <v-card-title>
          <span class="font-15 singleline">{{ selectedMedia.name }}</span>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                color="grey"
                style="cursor: pointer;"
                dark
              >help</v-icon>
            </template>
            <span>Each media can have 1 Vertical and 1 Horizontal HD versions to work on both Vertical and Horizontal Screens</span>
          </v-tooltip>
        </v-card-title>
        <v-card-text v-if="selectedMedia.description !== null">
          <div>
            <span class="grey--text caption">{{ selectedMedia.description }}</span>
          </div>
        </v-card-text>
      
        <v-container fluid>
          <v-row>
            <v-col cols="12" sm="6" md="4" v-for="(variant, key) in selectedMedia.variants" :key="key" 
              v-show="variant.is_hhd || variant.is_vhd">
              <v-card class="blue-grey lighten-5">
                <v-container fluid>
                  <v-row>
                    <v-col cols="12" @click="openVariant(variant)" style="cursor: pointer">
                      <v-img
                        :src="variant.thumbnail_m !== null ? variant.thumbnail_m : require('@/assets/no_image.jpg')"
                        height="100"
                        contain
                      ></v-img>
                      <v-card-text>
                        <div class="grey--text text--darken-3 font-13">{{ getVariantText(variant) }}</div>
                        <div class="grey--text text--darken-3 font-13">Resolution: {{ variant.resolution_x }} X {{ variant.resolution_y }}</div>
                        <div class="grey--text text--darken-3 font-13">Size: {{ variant.size_mb }} MB</div>
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-content>
</template>

<script>
  export default {
    props: {
      value: Boolean,
      selectedMedia: Object
    },
    computed: {
      isOpen: {
        get () {
          return this.value
        },
        set (isOpen) {
          this.$emit('input', isOpen)
        }
      }
    },
    data () {
      return {
        variantDialog: false,
        selectedVariant: {}
      }
    },
    methods: {
      openVariant (variant) {
        this.selectedVariant = variant
        this.variantDialog = true
      },
      closeVariant () {
        this.variantDialog = false
        this.selectedVariant = {}
      },
      
    }
  }
</script>