<template>
  <v-container fluid>
    <loading-dialog v-model="isLoading"></loading-dialog>
    <v-row justify="center">
      <v-col cols="12" md="11" lg="10" xl="9" class="shrink">
        <edit-location-dialog
          v-model="editLocationDialog"
          :location="edited_location"
          @saved="getLocations">
        </edit-location-dialog>
        <edit-keywords-dialog 
        v-model="editkeywordsDialog "
          :location_id="profile_location_id"
          :profile_object="profile_location_object"
          @saved="getLocations"
        >
        </edit-keywords-dialog>
       <schedules-plist-dialog
         v-model="commercialSchedulesDialog"
         :Schedules_host_id="schedules_host_id"
         :date_schedules="date_schedules"
       >
       </schedules-plist-dialog>
        <edit-site-dialog
          v-model="editSiteDialog"
          :site="edited_site"
          @saved="getLocations">
        </edit-site-dialog>
        <profile-location-dialog
          v-model="profileLocationDialog"
          :location_id="profile_location_id"
          :profile_object="profile_location_object"
          @saved="getLocations"
        >
        </profile-location-dialog>
        <edit-visitors-dialog
          v-if="profileVisitorDialog"
          v-model="profileVisitorDialog"
          :location_id="profile_location_id"
          :VisitorPercentage='visitorPercentage'
          :ProfileVistor="profileVistor"
          :LocationName="locationName"
          @saved="getLocations">
        </edit-visitors-dialog>
        <v-row v-if="$route.query.id!==undefined && partner" >
          <v-row class=" grey--text font-weight-bold text--lighten-1 pl-6 " >
            <p style="font-size: 30px;" > Partner: {{partner.name}}</p>
          </v-row>
        </v-row>
        <v-toolbar dense>
          <v-toolbar-title class="font-16">
            Locations
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            solo-inverted
            dense
            clearable
            hide-details
            prepend-inner-icon="mdi-magnify">
          </v-text-field>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon small v-on="on" @click="filterPanel = !filterPanel">
                <v-icon>mdi-filter</v-icon>
              </v-btn>
            </template>
            <span>Filter Locations</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon small v-on="on">
                <v-icon>mdi-sort</v-icon>
              </v-btn>
            </template>
            <span>Sort Locations</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon small v-on="on">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Add New Location</span>
          </v-tooltip>      
          <router-link  style=" color:white;"  v-if="$route.query.id!==undefined && partner"
            :to="{ path: '/partners/PartnersProfile'+'/' , 
             query: { id: $route.query.id }}" 
             append
            >    
            <v-icon >mdi-home</v-icon>
          </router-link>
        </v-toolbar>
        <!-- <v-expand-transition>
          <v-card v-if="filterPanel" class="mt-3">
            <v-card-text>
              <v-row>
                <v-col cols="12" md="3">
                  <div class="bold primary_text--text textTransformCap">
                    Location Status
                  </div>
                  <v-divider />
                  <v-list dense>
                    <v-list-item-group v-model="location_status" color="accent">
                      <v-list-item
                        v-for="(item, i) in location_status_choices"
                        :key="i"
                        :value="item.value"
                      >
                        <v-list-item-content>
                          <v-list-item-title v-text="item.text"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-col>
                <v-col cols="12" md="3">
                  <div class="bold primary_text--text textTransformCap">
                    State
                  </div>
                  <v-divider />
                  <v-list dense>
                    <v-list-item-group v-model="location_status" color="accent">
                      <v-list-item
                        v-for="(item, i) in location_status_choices"
                        :key="i"
                        :value="item.value"
                      >
                     <v-list-item-content>
                          <v-list-item-title v-text="item.text"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-col>
                <v-col cols="12" md="3">
                  <div class="bold primary_text--text textTransformCap">
                    City
                  </div>
                  <v-divider />
                  <v-list dense>
                    <v-list-item-group v-model="location_status" color="accent">
                      <v-list-item
                        v-for="(item, i) in location_status_choices"
                        :key="i"
                        :value="item.value"
                      >

                        <v-list-item-content>
                          <v-list-item-title v-text="item.text"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-col>
                <v-col cols="12" md="3">
                  <div class="bold primary_text--text textTransformCap">
                    Venue
                  </div>
                  <v-divider />
                  <v-list dense>
                    <v-list-item-group v-model="location_status" color="accent">
                      <v-list-item
                        v-for="(item, i) in location_status_choices"
                        :key="i"
                        :value="item.value"
                      >
                        <v-list-item-content>
                          <v-list-item-title v-text="item.text"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn small color="primary" outlined>
                Reset
              </v-btn>
              <v-btn small color="btn_color" @click="filterPanel = false">
                Done
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-expand-transition> -->
        <v-toolbar dense class="mt-3">
          <v-toolbar-title class="font-16 me-5">
            Location Status Filter:
          </v-toolbar-title>
          <v-btn-toggle v-model="locations_status_value" mandatory>
            <v-btn text small value="2" @click="getLocationsOfStatus(2)">
              ACTIVE
            </v-btn>
            <v-btn text small value="1" @click="getLocationsOfStatus(1)">
              PENDING
            </v-btn>
            <v-btn text small value="3" @click="getLocationsOfStatus(3)">
              SUSPENDED
            </v-btn>
            <v-btn text small value="4" @click="getLocationsOfStatus(4)">
              DELETED
            </v-btn>
          </v-btn-toggle>
        </v-toolbar>

        <v-row class="ms-0 mt-5"> 
          <v-col cols="12">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <td>Total Locations:</td>
                  <td class="mx-5 px-5">{{ locations_count.toString() }}</td>
                </tr>
                <tr>
                  <td>Total Sites:</td>
                  <td class="mx-5 px-5">{{ sites_count.toString() }}</td>
                </tr>
                <tr>
                  <td>Unconnected Sites:</td>
                  <td class="mx-5 px-5">{{ unconnected_count.toString() }}</td>
                  <td class="mx-5 px-5">
                    <v-btn text icon>
                      <v-icon>cached</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </thead>
             </template>
          </v-simple-table>

          <v-row class="mt-4" >
            <label class="mx-8 mt-1" style="font-size: 14px;">Connected Locations Filter:</label>
            <v-btn-toggle v-model="locations_filter_value" mandatory>
              <v-btn text small value="1" @click="showLocationsOfConnectStatus(1)">
                ALL
              </v-btn>
              <v-btn text small value="2" @click="showLocationsOfConnectStatus(2)">
                CONNECTED
              </v-btn>
              <v-btn text small value="3" @click="showLocationsOfConnectStatus(3)">
                UNCONNECTED
              </v-btn>
            </v-btn-toggle>
          </v-row>

          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" v-for="(location, i) in locations" :key="i">
            <v-card color="less_dark_color" raised>
              <div class="d-flex flex-no-wrap">
                <v-avatar
                  class="ma-3"
                  size="125"
                  tile
                >
                  <v-img 
                    :src="location.location_images.length > 0 ? location.location_images[0].thumbnail_m : require('@/assets/no_image.jpg')">
                  </v-img>
                </v-avatar>
                <div>
                  <v-card-title class="font-14">
                    {{ location.name }}
                  </v-card-title>
                  <v-card-subtitle class="font-13">
                    {{ location.marketing_message }}
                  </v-card-subtitle>
                  <v-chip small :color="location.is_listed_location ? 'primary' : 'red darken-1'" label>
                    <v-icon left v-if="location.is_listed_location">check</v-icon>
                    <v-icon left v-else>close</v-icon>
                    Listed Location
                  </v-chip>
                  <v-chip small class="ma-1" label>
                    <v-icon left>location_city</v-icon> {{ location.city_name }}
                  </v-chip>
                  <v-chip small class="ma-1" label color="secondary">
                    <v-icon left>mdi-store</v-icon> {{ location.venue_name }}
                  </v-chip>
                </div>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon small class="me-3 mt-2" @click="editLocation(location)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Location</span>
                </v-tooltip>
                <!-- <v-btn icon small class="me-3 mt-2" color="red darken-1">
                  <v-icon>mdi-delete</v-icon>
                </v-btn> -->
              </div>
              <v-expansion-panels class="mt-3">
                <v-expansion-panel>
                  <v-expansion-panel-header color="accent">
                    Location Profile
                    <template v-slot:actions>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" x-small icon @click.native.stop="profileLocation(location)">
                            <v-icon style="transform: unset;">mdi-pencil</v-icon>
                          </v-btn>
                          </template>
                          <span>profile location</span>
                      </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" x-small icon  @click.native.stop="profileVistor(location,location.name)">
                             <v-icon style="transform: unset;">mdi-account-group-outline</v-icon>
                            </v-btn>
                        </template>
                          <span>edit visitors_types</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" x-small icon  @click.native.stop="editkeywords(location)">
                            <v-icon style="transform: unset;">mdi-alpha-w-box</v-icon>
                          </v-btn>
                        </template>
                        <span>edit keywords</span>
                      </v-tooltip>
                     <v-icon>expand_more</v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="blue-grey darken-2">
                    <v-simple-table class="mt-3" v-if="location.location_profile !== null">
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <td class="font-14 secondary_text--text">
                              Average Weekday Visitors
                            </td>
                            <td class="font-14 primary_text--text bold">
                              {{ location.location_profile.average_weekday_visitors }}
                            </td>
                          </tr>
                          <tr>
                            <td class="font-14 secondary_text--text">
                              Average Weekend Visitors
                            </td>
                            <td class="font-14 primary_text--text bold">
                              {{ location.location_profile.average_weekend_visitors }}
                            </td>
                          </tr>
                          <tr v-if="location.location_profile.open_24_7">
                            <td class="font-14 secondary_text--text">
                              Open 24/7
                            </td>
                            <td class="font-14 primary_text--text bold">
                              <v-icon>check</v-icon>
                            </td>
                          </tr>
                          <tr v-if="!location.location_profile.open_24_7 && !location.location_profile.weekend_as_weekdays">
                            <td class="font-14 secondary_text--text">
                              Weekday Open Time
                            </td>
                            <td class="font-14 primary_text--text bold">
                              {{ location.location_profile.weekday_open_time }}
                            </td>
                          </tr>
                          <tr v-if="!location.location_profile.open_24_7 && !location.location_profile.weekend_as_weekdays">
                            <td class="font-14 secondary_text--text">
                              Weekday Close Time
                            </td>
                            <td class="font-14 primary_text--text bold">
                              {{ location.location_profile.weekday_close_time }}
                            </td>
                          </tr>
                          <tr>
                            <td class="font-14 secondary_text--text">
                              Open Weekends
                            </td>
                            <td class="font-14 primary_text--text bold">
                              <v-icon v-if="location.location_profile.open_weekends">check</v-icon>
                              <v-icon v-else>close</v-icon>
                            </td>
                          </tr>
                          <tr v-if="!location.location_profile.open_24_7 && 
                            !location.location_profile.weekend_as_weekdays && location.location_profile.open_weekends">
                            <td class="font-14 secondary_text--text">
                              Weekend Open Time
                            </td>
                            <td class="font-14 primary_text--text bold">
                              {{ location.location_profile.weekend_open_time }}
                            </td>
                          </tr>
                          <tr v-if="!location.location_profile.open_24_7 && 
                            !location.location_profile.weekend_as_weekdays && location.location_profile.open_weekends">
                            <td class="font-14 secondary_text--text">
                              Weekend Close Time
                            </td>
                            <td class="font-14 primary_text--text bold">
                              {{ location.location_profile.weekend_close_time }}
                            </td>
                          </tr>
                          <tr v-if="!location.location_profile.open_24_7 && location.location_profile.weekend_as_weekdays">
                            <td class="font-14 secondary_text--text">
                              Open Time
                            </td>
                            <td class="font-14 primary_text--text bold">
                              {{ location.location_profile.weekday_open_time }}
                            </td>
                          </tr>
                          <tr v-if="!location.location_profile.open_24_7 && location.location_profile.weekend_as_weekdays">
                            <td class="font-14 secondary_text--text">
                              Close Time
                            </td>
                            <td class="font-14 primary_text--text bold">
                              {{ location.location_profile.weekday_close_time }}
                            </td>
                          </tr>
                          <tr>
                            <td class="font-14 secondary_text--text">
                              Visitor Stays in Site For
                            </td>
                            <td class="font-14 primary_text--text bold">
                              {{ location.location_profile.visitor_average_site_time }} minutes
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <v-list-item v-else class="mt-2 warning">
                      <v-list-item-icon>
                        <v-icon>warning</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="font-13">
                          This Location is not profiled yet
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header color="accent">
                    Location Images
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col cols="12" class="text-center" v-for="(image, key) in location.location_images" :key="key">
                        <v-img
                          :src="image.thumbnail_m"
                          height="200"
                          contain>
                        </v-img>
                        <v-btn class="mt-2" @click="deleteSiteImage(image)" x-small outlined color="red darken-1">
                          Delete
                        </v-btn>
                      </v-col>
                      <u-location-btn
                        color="secondary"
                        class="mx-auto mt-2"
                        title="Upload Image"
                        mimeType="image/*"
                        :selectedCallback="uploadLocationImage"
                        :location_id="location.location_id">
                      </u-location-btn>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header color="accent">
                    Location Sites
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row> 
                      <v-col cols="12" sm="12" xl="3" lg="4" md="6" v-for="(item, i) in location.location_sites" :key="i" class="mt-2">
                        <v-card style="background-color: #fffaf8;" class="pa-3 pt-0" >
                          <v-row class="blue-grey darken-4 white--text pl-4" style="height: 30px;" align-content="center">
                              {{ item.name }}
                           
                           </v-row>
                           <v-row class="white darken-1 white--text" style="height: 30px;" align-content="center">
                            <v-col class='mt-2'>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-btn v-on="on" icon x-small color="teal">
                                    <v-icon v-if="item.connectivity.is_connected">mdi-cellphone-link</v-icon>
                                    <v-icon v-else>mdi-cellphone-link-off</v-icon>
                                  </v-btn>
                                </template>
                                <span>Last Connection: {{ item.connectivity.last_connection }}</span>
                              </v-tooltip>
                            </v-col>
                             <v-col class='mt-2'>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-btn v-on="on" icon x-small color="teal" @click="connectionCheck(item)">
                                    <v-icon>mdi-refresh</v-icon>
                                  </v-btn>
                                </template>
                                <span>Refresh Connectivity</span>
                              </v-tooltip>
                            </v-col>
                            <v-card-actions >
                              <v-col>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" icon x-small @click="editSite(item)" color="blue-grey darken-2">
                                      <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Edit Site</span>
                                </v-tooltip>
                              </v-col>
                            </v-card-actions>
                           </v-row>
                          <v-img
                            v-if="item.site_images.length > 0"
                            :height="200"
                            :src="item.site_images[0].thumbnail_m"
                            
                          ></v-img>
                          <v-img
                            v-else
                            :height="200"
                            src="@/assets/no_image.jpg"
                            class="ms-2"
                          ></v-img>
                          <v-col class="blue-grey--text px-2 mx-3 font-13" align-content="center">
                            <!--div class="py-1">Name: {{ item.name }}</div-->
                            <v-row>
                              <div class="py-1 ">Host Status : 
                                <span v-if="item.host_status===1">DEVICE NOT ACTIVE</span>
                                <span v-else-if="item.host_status===2">DEVICE ACTIVE</span>
                                <span v-else-if="item.host_status==4">DEVICE DELETED</span>
                              </div>
                            </v-row>
                            <v-row>
                              <div class="py-1 ">Share Status : 
                                <span v-if="item.share_status===1">NEVER SHARED</span>
                                <span v-else-if="item.share_status===2">SHARE ACTIVE</span>
                                <span v-else-if="item.share_status==3">SHARE STOPPED</span>
                                <span v-else-if="item.share_status==4">SHARE STALLED</span>
                              </div>
                            </v-row>
                            <v-row>
                              <div class="py-1 ">Is Listed Online : 
                                <v-icon color="green" v-if="item.is_listed_site">check</v-icon>
                                <v-icon color="red" v-else >closed</v-icon>
                              </div>
                            </v-row>
                            <v-row>
                              <div class="py-1 ">Weekday Rate :  ${{ item.weekday_rate }} </div>
                            </v-row>
                            <v-row>
                              <div class="py-1">Weekend Rate :  ${{ item.weekend_rate }} </div>
                            </v-row>
                            <v-row >
                              <v-btn x-small outlined color="primary"
                                class="bold "
                                @click="viewCommercialSchedules(item)">
                                Commercial Schedules
                                <v-icon x-small class="ms-1">cast</v-icon>
                               </v-btn>
                            </v-row>
                          </v-col>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
             </v-expansion-panels>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
        isLoading: false,
        locations: [],
        unconnected_sites: [],
        filterPanel: false,
        location_status_choices: [
          {text: 'PENDING', value: 1},
          {text: 'ACTIVE', value: 2},
          {text: 'SUSPENDED', value: 3},
          {text: 'DELETED', value: 4},
        ],
        locations_status_value: 2, //ACTIVE
        locations_count: 0,
        sites_count: 0,
        unconnected_count: 0,
        all_locations: [],
        connected_locations: [],
        unconnected_locations: [],
        location_filter_choices: [
          {text: 'ALL', value: 1},
          {text: 'CONNECTED', value: 2},
          {text: 'UNCONNECTED', value: 3},
        ],
        locations_filter_value: 2, //ALL
        site_host_status_choices: [
          {text: 'INACTIVE', value: 1},
          {text: 'ACTIVE', value: 2},
          {text: 'DELETED', value: 3},
        ],
        site_share_status_choices: [
          {text: 'SHARE_NEVER_SET', value: 1},
          {text: 'SHARE_ACTIVE', value: 2},
          {text: 'SHARE_STOPPED', value: 3},
          {text: 'SHARE_STALLED', value: 4},
        ],

        countries: [],
        states: [],
        cities: [],
        venues: [],
        location_status: null,
        edited_location: null,
        editLocationDialog: false,
        profileLocationDialog: false,
        editkeywordsDialog:false,
        profileVisitorDialog: false,
        profile_location_id: -1,
        profile_location_object: null,
        commercialSchedulesDialog: false,
        schedules_host_id: null,
        partner: null,
        locationName: null,

        edited_site: null,
        date_schedules: null,
        
        editSiteDialog: false,
        Visitors:[],
        visitor_percentage:[],
        visitors_people_types:[],
        visitorPercentage: null,
        visitor_name:[]
      }
    },
    watch: {
      editLocationDialog (value) {
        if (!value) {
          this.edited_location = null
        }
      },
      profileLocationDialog (value) {
        if (!value) {
          this.profile_location_id = -1
          this.profile_location_object = null
        }
      },
       
      //NEW
      editSiteDialog (value) {
        if (!value) {
          this.edited_site = null
        }
      },
      date_schedules (value) {
        if (!value) {
          this.date_schedules = null
        }
        else{
          this.date_schedules=value
         }
      }
    },
    methods: {
      editLocation (location) {
        this.edited_location = Object.assign({}, location)
        this.editLocationDialog = true
      },
      editSite (site) {
        this.edited_site = Object.assign({}, site)
        this.editSiteDialog = true
      },
      connectionCheck (site) {
        var prev_is_connected = false
        // store prev connectivity status
        if (site.connectivity !== null && site.connectivity !== undefined) {
          prev_is_connected = site.connectivity.is_connected
        }
        this.isLoading = true
        this.$http.get(this.$store.getters.pubsServer + '/api/publishers/partners/hosts/last_connection/' + site.site_id, { headers: { Authorization: 'Token ' + this.$store.getters.token }
          }
        ).then(response => {
          this.isLoading = false
          site.connectivity = response.body
          // update connectivity count
          if (site.connectivity !== null && site.connectivity !== undefined) {
            if (prev_is_connected !== site.connectivity.is_connected) {
              if (site.connectivity.is_connected) {
                this.unconnected_count -= 1
              } else {
                this.unconnected_count += 1
              }
            }
          }

        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
        })
      },
      profileLocation (location) {
        this.profile_location_id = location.location_id
        if (location.location_profile !== null) {
          this.profile_location_object = Object.assign({}, location.location_profile)
        } else {
          this.profile_location_object = null
        }
        this.profileLocationDialog = true
      },
      editkeywords (location) {
        this.profile_location_id = location.location_id
        if (location.location_profile !== null) {
          this.profile_location_object = Object.assign({}, location.location_profile)
        } else {
          this.profile_location_object = null
        }
        this.editkeywordsDialog = true
      },
      profileVistor (Vistors,name) {
        this.locationName=name
        this.profile_location_id = Vistors.location_id
        if(this.profile_location_id===undefined){
          this.profile_location_id=Vistors
        }
        this.$http.get(this.$store.getters.pubsServer + '/api/hosts/locations/visitors/?location_id=' +  this.profile_location_id +"/", { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
         
           this.visitorPercentage=response.body;
           
        this.profileVisitorDialog = true
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
        })
      },
      getLocationsOfStatus (value) {
        if (this.locations_status_value !== value) {
          this.locations_status_value = value
          this.getLocations()
        }
      },
      showLocationsOfConnectStatus (value) {
        if (this.locations_filter_value !== value) {
          this.locations_filter_value = value
          if (value === 1) {
            this.locations = this.all_locations
          } else if (value === 2) {
            this.locations = this.connected_locations
          } else {
            this.locations = this.unconnected_locations
          }
        }
      },
      getLocations () {
        this.isLoading = true
        if (this.$route.query.id!==undefined) {
         this.getLocationsPartner(this.$route.query.id)
         return
        }
        this.$http.get(this.$store.getters.pubsServer + '/api/hosts/locations/?status=' + this.locations_status_value, { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.locations_filter_value = 1 // SHOW ALL Locations
          this.all_locations = response.body
          this.locations = response.body
          this.updateLocationsCounts()
          //console.log(this.locations)
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
        })       
      },
      getLocationsPartner(id) {
        this.isLoading = true
         this.$http.get(this.$store.getters.pubsServer + '/api/publishers/partners/'+ id, { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.partner = response.body
         
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
        })
        
        this.$http.get(this.$store.getters.pubsServer + '/api/hosts/locations/?partner_id=' + id + '&status=' + this.locations_status_value , { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.locations_filter_value = 1 // SHOW ALL Locations
          this.all_locations = response.body
          this.locations = response.body
          this.updateLocationsCounts()
          
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
        })
      },
      updateLocationsCounts () {
        if (this.all_locations !== undefined && this.all_locations !== null) {
          this.locations_count = this.all_locations.length
          this.getSitesCountsAndStats()
          //this.sites_count = 0
          //this.unconnected_count = 0
        } else {
          this.locations_count = 0
          this.sites_count = 0
          this.unconnected_count = 0
        }
      },
      getSitesCountsAndStats () {
        this.sites_count = 0
        this.unconnected_count = 0
        this.connected_locations = []
        this.unconnected_locations = []
        for (var i = 0; i < this.all_locations.length; i++) {
          if (this.all_locations[i].location_sites !== undefined && this.all_locations[i].location_sites !== null) {
            this.sites_count += this.all_locations[i].location_sites.length
            // for unconnected_count, assume they are not connected first
            //this.unconnected_count += this.all_locations[i].location_sites.length
            // get connection status
            for (var j = 0; j < this.all_locations[i].location_sites.length; j++) {
              if (this.all_locations[i].location_sites[j].connectivity !== undefined && this.all_locations[i].location_sites[j].connectivity !== null) {
                if (this.all_locations[i].location_sites[j].connectivity.is_connected) {
                  this.connected_locations.push(this.all_locations[i])
                } else {
                  this.unconnected_count += 1
                  this.unconnected_locations.push(this.all_locations[i])
                }
              } else {
                this.unconnected_count += 1
                this.unconnected_locations.push(this.all_locations[i])
              }
            }
          } else {
            this.unconnected_locations.push(this.all_locations[i])
          }
        }
      },
      uploadLocationImage (file, location_id) {
        if (file === null) {
          return
        }
        var data = new FormData()
        data.set('file', file)
        this.isLoading = true
        this.$http.post(this.$store.getters.pubsServer + '/api/hosts/location_images/?location_id=' + location_id, data, { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.getLocations()
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
          console.log(error)
        })
      },
      getCountries () {
        this.isLoading = true
        this.$http.get(this.$store.getters.pubsServer + '/api/common/all_countries/', { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.countries = response.body
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
        })
      },
      getStates () {
        this.isLoading = true
        this.$http.get(this.$store.getters.pubsServer + '/api/common/all_countries/', { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.countries = response.body
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
        })
      },
      getVenues () {
        this.isLoading = true
        this.$http.get(this.$store.getters.pubsServer + '/api/hosts_public/location_venues/', { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.venues = response.body
        }, error => {
          this.isLoading = false
        })
      }, 
      getVisitorList(){
        this.$http.get(this.$store.getters.pubsServer + '/api/hosts_public/people_types/', { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
          }).then(response=>{
            return response.json();
          }).then(data =>{
          this.visitors_people_types=data
          for (const key in data) {
            var item=data[key]
            this.visitor_name.push(item.name)
          }
          },error =>{
          console.log(error)
          }
        )
      },
      viewCommercialSchedules (location) {
        this.schedules_host_id = location.site_id
        this.getDateSchedules(location.site_id)
        this.date_schedules =null

        this.commercialSchedulesDialog = true
      },
      getDateSchedules (id) {
        this.isLoading = true
        this.$http.get(this.$store.getters.pubsServer + '/api/publishers/partners/commercial_playlists/?host_id=' + id, {
          headers: {Authorization: 'Token ' + this.$store.getters.token}
        })
        .then(
          (res) => {
              this.date_schedules = res.body
             this.isLoading = false
           }
         )
       .catch((error) => {
          if (error.status === this.unauthorized) {
             this.showSessionExpired()
           }
           this.isLoading = false
        })
      },
      deleteSiteImage (image) {
        if (image.site_id === null) {
          return
        }
        this.isLoading = true
        this.$http.delete(this.$store.getters.pubsServer + '/api/hosts/location_images/' + image.image_id + '/', { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.getLocations()
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
          console.log(error)
        })
      }
 
    },
    created () {
      if(this.$route.query.id!==undefined){
        this.getLocationsPartner(this.$route.query.id)

      }else{
         this.getLocations()
        }
  
    }
  }
</script>


<!--     
    PENDING = 1
    ACTIVE = 2
    SUSPENDED = 3
    DELETED = 4
    LOCATION_STATUS_CHOICES = (
        (PENDING, 'Location is pending, awaiting installation/activation'),
        (ACTIVE, 'Currently is active'),
        (SUSPENDED, 'Location is suspended - may resume operations later'),
        (DELETED, 'Location is deleted'),
    )
 
-> host_status:
    INACTIVE = 1
    ACTIVE = 2
    DELETED = 4

-> share_status:
    SHARE_NEVER_SET = 1
    SHARE_ACTIVE = 2
    SHARE_STOPPED = 3
    SHARE_STALLED = 4

 -->