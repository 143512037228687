<template>
  <v-container fluid>
    <loading-dialog v-model="isLoading"></loading-dialog>
    <v-row justify="center">
      <v-col cols="12" md="10" lg="9" xl="9">
        <v-toolbar dense class="white--text font-weight-black blue-grey darken-4">
          <v-toolbar-title >
            Partners
          </v-toolbar-title>
        </v-toolbar>
        <v-row>
          <v-col cols="12" v-for="(partner, key) in partners" :key="key">
            <v-card color="blue-grey darken-3 white--text" style="background-color: #fffaf8;" raised>
              <v-card-title class="font-15 pb-1"  >
                <v-row>
                  <v-col class="font-weight-medium font-16 ">
               Name :  {{ partner.name }}
                  </v-col>
                  <v-col md="5"></v-col>
                  <v-col  md="1">
                      <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                  <router-link  :to="{ path: '/partners/PartnersProfile'+'/' , query: { id: partner.partner_id }}" style=" color:white;"> 
                  <v-avatar color=" white darken-3  " >
                    
                 <v-icon large  v-on="on"  color="blue-grey darken-3">
                 mdi-account-circle
               </v-icon>
                  </v-avatar>
         </router-link>
            </template>
            <span> {{ partner.name }}  Profile</span>
               </v-tooltip>

        
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-subtitle class="font-14 bold white--text font-weight-black " >
                {{ getClientStatus(partner.client_status) }}
              </v-card-subtitle>
              <div class="px-4">
                <v-simple-table dense light>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td class="bold" >Name</td>
                        <td>
                          {{ partner.main_contact_name }}
                        </td>
                      </tr>
                      <tr>
                        <td class="bold">Email</td>
                        <td>
                          {{ partner.main_contact_email }}
                        </td>
                      </tr>
                      <tr>
                        <td class="bold">Phone</td>
                        <td>
                          {{ partner.main_contact_phone }}
                        </td>
                      </tr>
                         <tr>
                        <td class="bold">Category </td>
                        <td  style="text-align:left">
                          {{ partner.category_name }}
                        </td>
                      </tr>
                          <tr>
                        <td align="left" class="bold">Sub Category </td>
                        <td  style="text-align:left">
                          {{ partner.sub_category_name }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                
              </div>
              <v-col ></v-col>
        
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
        isLoading: false,
        partners: []
      }
    },
    methods: {
      getPartners () {
        this.isLoading = true
        this.$http.get(this.$store.getters.pubsServer + '/api/publishers/partners/', { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.partners = response.body
          
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
        })
      },
      getClientStatus (status) {
        if (status === 1) {
          return 'Legal Operations'
        } else if (status === 2) {
          return 'Soft Operations'
        } else if (status === 3) {
          return 'Full Operations'
        } else if (status === 4) {
          return 'Suspended'
        } else if (status === 5) {
          return 'Deleted'
        }
      }
    },
    created () {
      this.getPartners()
    }
  }
</script>

