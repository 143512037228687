<template>
  <div>
    <!-- <v-btn
      style="text-transform: none"
      @click="creatorDialog = true"
      dark>
      Edit Image
    </v-btn> -->
    <error-snackbar v-model="errorSnackbar" :errorMessage="errorMessage"></error-snackbar>
    <loading-dialog v-model="isLoading" ></loading-dialog>
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="5" md="3" lg="3" class="px-0">
          <v-navigation-drawer permanent class="pa-0" width="auto" style="min-height: 700px" id="side-bar">
            <v-toolbar style="background-color: #156CA8;">
              <v-toolbar-title class="white--text" style="font-weight: bold; width: 100%; text-align: center; margin-left: 0px; font-size: 20px;">Settings</v-toolbar-title>
            </v-toolbar>
            <div class=" pb-5"></div>
            <v-select
              class="my-0"
              v-model="type"
              :items="['Horizontal', 'Vertical']"
              @change="switchView(type)"
              label="Orientation"
              dense
              outlined
            ></v-select>
            <v-expansion-panels v-model="panel" class="white" accordion focusable hover>
              <v-expansion-panel>
                <v-expansion-panel-header class="weight-600 my-1"><div>Background</div></v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-card flat class="px-0 py-0">
                      <v-row class="py-0">
                        <!--v-col cols="12">
                          <div class="mt-4 ml-4"><p>Background</p></div>
                        </v-col-->
                        <v-col cols="12">
                          <v-radio-group v-model="backgroundType">
                            <v-radio
                              label="Color"
                              value="color"
                            ></v-radio>
                            <v-radio
                              label="Image"
                              value="image"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col cols="12">
                          <u-btn v-if="backgroundType === 'image'" class="primary font-12 spacing-02 weight-600" title="Upload Background" mimeType="image/png, image/jpeg" :selectedCallback="backgroundSelected">
                          </u-btn>
                          <v-slider
                            v-if="backgroundType === 'image'"
                            class="mt-2"
                            label="Blur"
                            v-model="backgroundBlur"
                            :max="100"
                            :min="0"
                            :step="1"
                          ></v-slider>
                          <v-btn
                            v-if="backgroundType === 'color'"
                            style="text-transform: none"
                            color = "primary"
                            @click="colorDialog = true; colorChange = 'background';"
                            small
                            >
                            <span><v-icon>palette</v-icon></span>
                            <span class="px-2 btn-act">Pick Color</span>
                          </v-btn>

                        </v-col>
                      </v-row>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header class="weight-600 my-1"><div>Foreground</div></v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div v-for="(foreground, key) in foregrounds" :key="key">
                      <creator-foreground class="mt-3 mb-0" v-model="foregrounds[key]"
                        :pos="key" :changedCallback="updateImage" :height="height" :onRemoveClicked="deleteForeground" :width="width"></creator-foreground>
                      <v-divider></v-divider>
                    </div>
                    <v-btn
                      class="mt-2"
                      color="secondary font-12 bold spacing-02"
                      style="text-transform: none"
                      @click="addForeground"
                      dark>
                      <span><v-icon>add</v-icon></span>
                      <span class="px-2 btn-act">New Foreground</span>
                    </v-btn>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header class="weight-600 my-1"><div>Caption</div></v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card flat class="mt-3" v-for="(caption, key) in captions" :key="key">
                      <v-row justify="center">
                        <v-col cols="12">
                          <v-layout style="height: 30px;" align-center>
                            <v-btn @click="deleteCaption(key)" color="red" fab x-small dark>
                              <v-icon>clear</v-icon>
                            </v-btn>
                            <div class="mt-4 ml-4 font-14"><p>Caption {{ key + 1 }}</p></div>
                          </v-layout>
                        </v-col>
                        <v-col cols="12" class="ml-2 mr-2">
                          <v-text-field name="caption" label="Caption" id="name" v-model="captions[key].text"></v-text-field>
                        </v-col>
                        <v-col cols="12" class="ml-2 mr-2">
                          <v-slider
                            label="Caption X"
                            v-model="captions[key].captionX"
                            :max="width"
                            :min="0 - width"
                            :step="5"
                          ></v-slider>
                          <v-slider
                            label="Caption Y"
                            v-model="captions[key].captionY"
                            :max="height"
                            :min="0 - height"
                            :step="5"
                          ></v-slider>
                          <v-btn
                            style="text-transform: none"
                            @click="captionIndex = key; colorDialog = true; colorChange = 'textColor';"
                            small
                            color="primary"
                            dark>
                            <span><v-icon>palette</v-icon></span>
                            <span class="px-2 btn-act">Caption Color</span>
                          </v-btn>

                          <v-checkbox
                            v-model="captions[key].fontWeight"
                            label="Bold"
                            true-value="bold"
                            false-value="normal">
                          </v-checkbox>
                          <v-slider
                            label="Font Size"
                            class="mt-1"
                            v-model="captions[key].fontSize"
                            :max="100"
                            :min="30"
                            :step="1"
                          ></v-slider>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                    </v-card>
                    <v-btn
                      class="mt-2"
                      style="text-transform: none"
                      @click="addCaption"
                      color = "secondary"
                      dark>
                      <span><v-icon>add</v-icon></span>
                      <span class="px-2 btn-act">New Caption</span>
                    </v-btn>
                  </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <div class="transparent mt-2 mb-2 text-center">
              <v-btn
                v-if="mode === 'create'"
                class="mt-2"
                style="text-transform: none"
                @click="uploadBtnClicked"
                color = "primary"
                dark>
                <span><v-icon>backup</v-icon></span>
                <span class="px-2 btn-act">Upload Image</span>
              </v-btn>
              <v-menu right v-else>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    class="blue darken-2"
                    style="text-transform: none"
                    dark>
                    Upload Image
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item @click="uploadBtnClicked">
                    <v-list-item-content>As New Variant</v-list-item-content>
                  </v-list-item>
                  <v-list-item @click="fillMediaName">
                    <v-list-item-content>As New Media</v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-navigation-drawer>
        </v-col>
        <v-col cols="12" sm="12" md="9" lg="9" v-if="$vuetify.breakpoint.mdAndUp">
          <div class="image-preview" ref="affixCol">
            <affix class="sidebar-menu d-none d-sm-flex" relative-element-selector="#side-bar" style="width: 700px;">
              <img :src="dataURI" alt="preview image">
            </affix>
          </div>
        </v-col>
        <v-col cols="12" v-else>
          <div class="image-preview">
            <img :src="dataURI" alt="preview image">
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="mediaInfoDialog" max-width="500px">
      <v-card>
          <v-card-title>
            <span class="headline">Media Info</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex md6 xs12>
                  <v-text-field name="mediaName" label="Media Name" id="name" v-model="mediaName"></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click.native="uploadNewMedia">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="colorDialog" max-width="400px">
        <v-card>
          <v-card-title>
            <span class="headline center">Pick Color</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex md6 xs12 v-if="colorChange === 'background'">
                  <chrome-picker flat v-model="backgroundColor" />
                </v-flex>
                <v-flex md6 xs12 v-if="colorChange === 'textColor' && captionIndex !== -1">
                  <chrome-picker v-model="captions[captionIndex].textColor" />
                </v-flex>
                <v-flex md6 xs12 v-if="colorChange === 'secondaryTextColor'">
                  <chrome-picker v-model="secondaryTextColor" />
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-2" text @click.native="colorDialog = false">Done</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
  import BitmapRenderer from '../plugins/BitmapRenderer'
  const extensions = {
    'image/png': 'png',
    'image/jpeg': 'jpg'
  }
  function filetypeOptions () {
    const formats = [
      {label: 'png', value: 'image/png'},
      {label: 'jpg', value: 'image/jpeg'}
    ]
    const retval = []
    const canvas = document.createElement('canvas')
    formats.forEach((e) => {
      if (canvas.toDataURL(e.value).indexOf(e.value) !== -1) {
        retval.push(e)
      }
    })
    return retval
  }
  const renderers = {
    bitmap: new BitmapRenderer()
  }
  export default {
    name: 'media-creator',
    props: {
      mediaUploaded: Function,
      mode: String,
      media: Object,
      orientation: String,
      scope: String
    },
    data () {
      return {
        isLoading: false,
        type: 'Horizontal',
        mediaInfoDialog: false,
        mediaName: '',
        width: 1920,
        height: 1080,
        affixWidth: '100%',
        foregrounds: [
          {image: null, foregroundX: -1, foregroundY: -1, foregroundScale: 1.0}
        ],
        colorDialog: false,
        colorChange: '',
        captionIndex: -1,
        backgroundColor: {hex: '#1F85DE'},
        captions: [{text: '', captionX: 1920 / 2, captionY: 1080 / 2, textColor: {hex: '#ffffff'}, fontSize: 40, fontWeight: 'normal'}],
        fontFamily: 'Montserrat',
        fontSize: 40,
        fontWeight: 'normal',
        fontAlign: 'center',
        filetype: 'image/jpeg',
        dataURI: '',
        imageQuality: 1.0,
        backgroundType: 'color',
        backgroundImage: null,
        backgroundBlur: 0,
        creatorDialog: false,
        filetypeOptions: [{label: 'svg', value: 'image/svg+xml'}],
        panel: 0,
        errorSnackbar: false,
        errorMessage: ''
      }
    },
    computed: {
      friendlyFilename () {
        if (this.caption === null) {
          return ''
        }
        const fg = this.textColor.replace(/#/, '')
        const bg = this.backgroundColor.replace(/#/, '')
        let retval = `dummy_${this.width}x${this.height}_${fg}_${bg}`
        if (this.caption !== '') {
          let sanitizedCaption = this.caption.replace(/\s/g, '-')
          sanitizedCaption = sanitizedCaption.replace(/[^A-Za-z0-9-]/g, '')
          sanitizedCaption = sanitizedCaption.toLowerCase()
          retval = `${retval}_${sanitizedCaption}`
        }
        retval = `${retval}.${extensions[this.filetype]}`
        return retval
      },
      rendererParams () {
        return {
          width: this.width,
          height: this.height,
          backgroundColor: this.backgroundColor,
          captions: this.captions,
          fontFamily: this.fontFamily,
          fontWeight: this.fontWeight,
          filetype: this.filetype,
          backgroundImage: this.backgroundImage,
          backgroundType: this.backgroundType,
          backgroundBlur: this.backgroundBlur,
          foregrounds: this.foregrounds
        }
      },
    },
    watch: {
      rendererParams: {
        handler () {
          this.updateImage()
        },
        deep: true
      },
      imageQuality () {
        this.updateImage()
      },
      orientation (value) {
        console.log('Orientation changed')
        this.type = value
        this.switchView(this.type)
      },
      media: {
        handler (value) {
          this.onMediaChanged(value)
        },
        deep: true
      }
    },
    methods: {
      reloadCreator () {
        this.mediaName = ''
        this.foregrounds = [
          {image: null, foregroundX: -1, foregroundY: -1, foregroundScale: 1.0}
        ]
        this.colorDialog = false
        this.colorChange = ''
        this.backgroundColor = {hex: '#1F85DE'}
        this.captions = [{text: '', captionX: 1920 / 2, captionY: 1080 / 2, textColor: {hex: '#ffffff'}, fontSize: 40}]
        this.fontFamily = 'Montserrat'
        this.fontSize = 40
        this.fontWeight = 'normal'
        this.fontAlign = 'center'
        this.backgroundType = 'color'
        this.backgroundImage = null
        this.backgroundBlur = 0
      },
      addCaption () {
        if (this.captions.length < this.captions_limit) {
          this.captions.push({text: '', captionX: 1920 / 2, captionY: 1080 / 2, textColor: {hex: '#ffffff'}, fontSize: 40, fontWeight: 'normal'})
        } else {
          this.errorMessage = 'You have reached to the maximum number of allowed captions'
          this.errorSnackbar = true
        }
      },
      addForeground () {
        if (this.foregrounds.length < this.foregrounds_limit) {
          this.foregrounds.push({image: null, foregroundX: -1, foregroundY: -1, foregroundScale: 1.0})
        } else {
          this.errorMessage = 'You have reached to the maximum number of allowed foregrounds'
          this.errorSnackbar = true
        }
      },
      deleteForeground (pos) {
        this.foregrounds.splice(pos, 1)
      },
      deleteCaption (pos) {
        this.captions.splice(pos, 1)
      },
      uploadBtnClicked () {
        if (this.mode === 'hdify') {
          this.uploadMedia()
        } else {
          this.fillMediaName()
        }
      },
      reloadWidth () {
        if (this.$refs.affixCol !== undefined) {
          this.affixWidth = '' + this.$refs.affixCol.clientWidth + 'px'
        }
        this.affixWidth = '100%'
      },
      getPrimaryImage (images) {
        if (images === null || images === undefined) {
          return {thumbnail_m: ''}
        }
        for (var i = 0; i < images.length; i++) {
          var image = images[i]
          if (image.is_primary) {
            return image
          }
        }
      },
      fillMediaName () {
        this.mediaInfoDialog = true
      },
      switchView (type) {
        if (type === 'Vertical') {
          this.width = 1080
          this.height = 1920
          this.captions[0].captionX = this.width / 2
          this.captions[0].captionY = this.height / 2
          this.subtitleX = this.width / 2
          this.subtitleY = this.height / 2
        } else {
          this.width = 1920
          this.height = 1080
          this.captions[0].captionX = this.width / 2
          this.captions[0].captionY = this.height / 2
          this.subtitleX = this.width / 2
          this.subtitleY = this.height / 2
        }
      },
      updateImage () {
        this.updateDataURI()
      },
      updateDataURI () {
        //console.log(this.backgroundColor)
        // if (this.filetype === 'image/svg+xml') {
        //   this.dataURI = renderers.svg.render(this.rendererParams)
        // }
        if (this.filetype === 'image/jpeg' || this.filetype === 'image/webp') {
          this.dataURI = renderers.bitmap.render(this.rendererParams, this.filetype, this.imageQuality)
        } else {
          this.dataURI = renderers.bitmap.render(this.rendererParams, this.filetype)
        }
      },
      dataURItoBlob (dataURI) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString
        if (dataURI.split(',')[0].indexOf('base64') >= 0) {
          byteString = atob(dataURI.split(',')[1])
        } else {
          byteString = unescape(dataURI.split(',')[1])
        }

        // separate out the mime component
        // var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length)
        for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i)
        }

        return new Blob([ia], {type: this.fileType})
      },
      selectDataURI (event) {
        event.target.setSelectionRange(0, event.target.value.length)
      },
      onColorChange (event) {
        this.backgroundColor = event.backgroundColor
        this.textColor = event.textColor
      },
      backgroundSelected (file) {
        var reader = new FileReader()
        var self = this
        reader.onloadend = function () {
          console.log('Image Readed')
          var img = new Image()
          img.src = reader.result
          var self2 = self
          img.onload = function () {
            if (this.height !== self2.height || this.width !== self2.width) {
              self2.backgroundBlur = 30
            } else {
              self2.backgroundBlur = 0
            }
            self2.is_image = true
            self2.backgroundImage = img
          }
        }
        reader.readAsDataURL(file)
      },
      imageSelected (file, pos) {
        var reader = new FileReader()
        var self = this
        reader.onloadend = function () {
          console.log('Image Readed ' + pos)
          var img = new Image()
          img.src = reader.result
          var self2 = self
          img.onload = function () {
            console.log('Image Loaded ' + pos)
            self2.foregrounds[pos] = {image: img, foregroundX: self2.width / 2 - img.width / 2, foregroundY: self2.height / 2 - img.height / 2, foregroundScale: 1.0}
          }
        }
        reader.readAsDataURL(file)
      },
      uploadMedia () {
        if (this.mode === 'create') {
          this.uploadNewMedia()
        } else {
          this.uploadNewVariant()
        }
      },
      onMediaChanged (value) {
        if (value === null) {
          return
        }
        this.type = this.orientation
        this.switchView(this.type)
        this.panel = 1
        var pImg = this.getPrimaryImage(value.variants)
        var img = new Image()
        img.src = pImg.file
        img.crossOrigin = 'Anonymous'
        var self2 = this
        img.onload = function () {
          self2.foregrounds = [{image: img, foregroundX: self2.width / 2 - img.width / 2, foregroundY: self2.height / 2 - img.height / 2, foregroundScale: 1.0}]
          if (!pImg.is_vhd && !pImg.is_hhd) {
            self2.backgroundType = 'image'
            self2.backgroundImage = img
            self2.backgroundBlur = 30
          }
        }
      },
      uploadNewVariant () {
        var blob = this.dataURItoBlob(this.dataURI)
        var data = new FormData()
        data.set('file', blob, this.media.name + '.jpg')
        this.isLoading = true
        var url = this.$store.getters.pubsServer
        if (this.scope === 'Partners') {
          url = url + '/api/web_client/partners/images/?media_id=' + this.media.media_id
        } else if (this.scope === 'Sponsors') {
          url = url + '/api/web_client/sponsors/images/?media_id=' + this.media.media_id
        } else {
          url = url + '/api/web_client/publishers/images/?media_id=' + this.media.media_id
        }
        console.log(url)
        this.$http.post(url, data, {headers: {
          Authorization: 'Token ' + this.$store.getters.token
        }})
          .then(
            (res) => {
              this.isLoading = false
              console.log(res.data)
              this.reloadCreator()
              this.mediaUploaded()
              Object.assign(this.$data, this.$options.data())
            }
          )
          .catch((error) => {
            this.isLoading = false
            if (error.status === this.unauthorized) {
              this.showSessionExpired()
            }
            console.log(error.response)
          })
      },
      uploadNewMedia () {
        this.mediaInfoDialog = false
        this.isLoading = true
        var blob = this.dataURItoBlob(this.dataURI)
        var data = new FormData()
        data.set('file', blob, this.mediaName + '.jpg')
        var url = this.$store.getters.pubsServer
        if (this.scope === 'Partners') {
          url = url + '/api/web_client/partners/images/'
        } else if (this.scope === 'Sponsors') {
          url = url + '/api/web_client/sponsors/images/'
        } else {
          url = url + '/api/web_client/publishers/images/'
        }
        this.$http.post(url, data, {headers: {
          Authorization: 'Token ' + this.$store.getters.token
        }})
          .then(
            (res) => {
              console.log(res.data)
              this.isLoading = false
              this.reloadCreator()
              this.mediaUploaded()
            }
          )
          .catch((error) => {
            this.isLoading = false
            if (error.status === this.unauthorized) {
              // this.showSessionExpired()
            }
            console.log(error)
         })
      }
    },
    mounted () {
      this.filetypeOptions = this.filetypeOptions.concat(filetypeOptions())
      this.updateImage()
    },
    created () {
      if (this.mode === 'hdify') {
        this.switchView(this.orientation)
        this.onMediaChanged(this.media)
      } else {
        this.switchView('Horizontal')
      }
      this.reloadWidth()
    }
  }
</script>

<style>
  .image-preview img {
    display: block;
    max-height: 800px;
    max-width: 100%;
  }
</style>
