<template>
  <v-content>
    <loading-dialog v-model="isLoading"></loading-dialog>
    <v-dialog v-model="isOpen"  max-width="800px" persistent  scrollable>
      <v-card>
        <v-card-title class="font-14">New Sponsor</v-card-title>
        <form>
        <v-card-text style="height: 550px;">
            
            <v-col>
            <v-row>
                <v-col>
        <v-text-field
            class="mt-2"
            label="Name"
            v-model="default_location_edit_object.name"
            outlined
            dense
            required
            type="text">
          </v-text-field>
                </v-col>
                <v-col>
         <v-text-field
           class="mt-2"
            v-model="default_location_edit_object.main_contact_email"
            label="Email"
            outlined
            dense
            required
            type="Email" >
          </v-text-field>
                </v-col>
            </v-row>
               <v-row>
                   <v-col>
             <v-text-field
            v-model="default_location_edit_object.main_contact_name"
            label="Main Contact Name"
            outlined
            dense
            required
            type="text" >
          </v-text-field>
                   </v-col>
                   <v-col>
            <v-text-field
            v-model="default_location_edit_object.main_contact_phone"
            label="Contact Phone"
            outlined
            dense
            required
            type="number" >
          </v-text-field>
                   </v-col>
               </v-row>
               <v-row>
                   <v-col>
          <v-autocomplete
            :items="countries"
            v-model="country"
            label="Country"
            class="font-14"
            prepend-inner-icon="flag"
            item-text="name"
            item-value="country_id"
             outlined
            dense
            required
          ></v-autocomplete>
                   </v-col>
                   <v-col>
          <v-autocomplete
            :items="states"
            v-model="default_location_edit_object.state"
            label="State"
            class="font-14"
            @input="getCities"
            prepend-inner-icon="location_city"
            item-text="name"
            item-value="state_id"
            outlined
            required
            dense
          ></v-autocomplete>
                   </v-col>
                   <v-col>
             <v-autocomplete
            :items="cities"
            v-model="default_location_edit_object.city"
            label="City"
            class="font-14"
            prepend-inner-icon="location_city"
            item-text="name"
            item-value="city_id"
            outlined
            required
            dense
          ></v-autocomplete>
                   </v-col>
               </v-row>
               <v-row>
       <v-col>
          <v-autocomplete
                :items="categories"
               v-model="default_location_edit_object.category"
                label="Category"
                @input="getSubCategories"
                class="font-14"
                prepend-inner-icon="flag"
                item-text="name"
                item-value="category_id"
                outlined
                
                dense
                required
              ></v-autocomplete>
       </v-col>
       <v-col>
                <v-autocomplete
                :items="sub_categories"
              v-model="default_location_edit_object.sub_category"
                label="Sub Category"
                class="font-14"
                prepend-inner-icon="location_city"
                item-text="name"
                item-value="sub_category_id"
                outlined
                required
                dense
              ></v-autocomplete>
       </v-col>
               </v-row>
               <v-row>
         <v-col>
           <v-text-field
            v-model="default_location_edit_object.address"
            label="Address"
            outlined
            dense
            required
            type="text">
          </v-text-field>
                   </v-col>
                   <v-col>
                         <v-text-field
            v-model="default_location_edit_object.zip_code"
            label=" Zip Code"
            outlined
            dense
            type="text">
          </v-text-field>
                   </v-col>
               </v-row>
        
                  </v-col>
           </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small @click="isOpen = false">
            Cancel
          </v-btn>
          <v-btn text small @click="submitNewPremium">
            Save
          </v-btn>
        </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </v-content>
</template>

<script>
  export default {
    props: {
      value: Boolean,
   },
    computed: {
      isOpen: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      }
    },
    watch: {
    isOpen (value) {
        if (!value) {
          this.default_location_edit_object = Object.assign({}, this.default_location_edit_object)
        }
       
      },
      country(val){
       
     
        if(val!==null){
        this.default_location_edit_object.country=val
        this.getStates()
          }
  
      }
      
      
    },
    data () {
      return {
        isLoading : false,
        countries: [],
        states: [],
        cities: [],
        venues: [],
        categories:[],
        sub_categories: [],
        country: null,
        default_location_edit_object: {
          name: null,
          category:null,
          sub_category: null,
          address: null,
          country: null,
          state: null,
          city: null,
          main_contact_email: null,
          main_contact_name: null,
          main_contact_phone: null,
          zip_code: null,
         
        }
      }
    },
    methods: {
      submitNewPremium () {
     
        this.isLoading = true
        this.$http.post(this.$store.getters.pubsServer + '/api/publishers/sponsors/' , this.default_location_edit_object, { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.isOpen = false
          this.$emit('saved')
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
          console.log(error)
        })
      },
      getCountries () {
        this.isLoading = true
        this.$http.get(this.$store.getters.pubsServer + '/api/common/all_countries/', { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.countries = response.body
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
        })
      },
      getStates () {
        this.isLoading = true
        this.$http.get(this.$store.getters.pubsServer + '/api/common/all_countries/' + this.country + '/', { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.states = response.body.states
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
        })
      },
      getCities () {
        this.isLoading = true
        this.$http.get(this.$store.getters.pubsServer + '/api/common/states/' + this.default_location_edit_object.state + '/', { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.cities = response.body.cities
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
        })
      },
      getVenues () {
        this.isLoading = true
        this.$http.get(this.$store.getters.pubsServer + '/api/hosts_public/location_venues/', { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.venues = response.body
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
        })
      },
        getSubCategories () {
        //this.isLoading = true
      
        // I'm HERE --- get selected category and fill sub-categories
        var category = this.default_location_edit_object.category
        this.$http.get(this.$store.getters.pubsServer + '/api/common/categories/' + category + '/', { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.sub_categories = response.body.sub_categories
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
        })
      },
        getCategories () {
        this.isLoading = true
        this.$http.get(this.$store.getters.pubsServer + '/api/common/categories/', { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.categories = response.body
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
        })
      },
    },
    created () {
      this.getCountries()
      this.getVenues()
         this.getCategories()
        
    }
  }
</script>