<template>
  <v-content>
    <loading-dialog v-model="isLoading"></loading-dialog>
    <v-dialog v-model="isOpen" max-width="600px" scrollable>
      <v-card>
        <v-card-title class="font-30"> Edit Partner  :  </v-card-title>
        <v-card-text style="height: 550px; ">
         <v-row justify="center">
           <v-form>
    <v-container>
      <v-row>
          <v-col cols="12" v-if="partner_edit_object" >
          <v-text-field
                   class="mt-2"
                   label="Name"
                    v-model="partner_edit_object.main_contact_name"
                  outlined
                   dense
                    type="text">
                      </v-text-field>
          </v-col>

        <v-col cols="12" >
            <v-text-field
                     class="mt-2"
                     label="Email"
                     v-model="partner_edit_object.main_contact_email"
                     outlined
                     dense
                     type="email">
                 </v-text-field>
        </v-col>

        <v-col cols="12" >
         <v-text-field
                    class="mt-2"
                    label="phone"
                    v-model="partner_edit_object.main_contact_phone"
                    outlined
                    dense
                     type="phone">
          </v-text-field>
        </v-col>

        <v-col cols="12">
           <v-autocomplete
                :items="categories"
                v-model="partner_edit_object.category"
                label="Category"
                @input="getSubCategories"
                class="font-14"
                prepend-inner-icon="flag"
                item-text="name"
                item-value="category_id"
                outlined
                dense
                required
              ></v-autocomplete>
               <v-autocomplete
                :items="sub_categories"
                v-model="partner_edit_object.sub_category"
                label="Sub Category"
                class="font-14"
                prepend-inner-icon="location_city"
                item-text="name"
                item-value="sub_category_id"
                outlined
                dense
              ></v-autocomplete>
        </v-col>

      </v-row>
    </v-container>
  </v-form>
           
          </v-row>
         </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
           <v-btn text  @click="editPartner">
               Save 
           </v-btn>
          <v-btn text small @click="isOpen = false">
            Cancel
          </v-btn>
         
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-content>
</template>


<script>
  export default {
    props: {
      value: Boolean,
      Partner: Object  ,
      GetPartner:Function
    },
    computed: {
      isOpen: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      },
      
    },
    watch: {
      isOpen (value) {
        if (value===null) {
          this.partner_edit_object = Object.assign({}, this.default_partner_edit_object)
        }
      },
      Partner(value){
       if (value === null) {
          this.partner_edit_object = Object.assign({}, this.default_partner_edit_object)
          return
        }
        this.partner_edit_object = Object.assign({}, {
          partner_id: value.partner_id,
          main_contact_name: value.main_contact_name,
          category: value.category,
          sub_category: value.sub_category,
          main_contact_email: value.main_contact_email,
          main_contact_phone: value.main_contact_phone,
        
        })
        this.getCategories()
        this.getSubCategories()
      }
      },
    data () {
      return {
      isLoading : false,
       partner:this.Partner,
       categories:[],
       sub_categories:[],
       partner_edit_object: {
          partner_id: null,
          main_contact_name: null,
          category: null,
          sub_category: null,
          main_contact_email: null,
         
      },
        default_partner_edit_object: {
          partner_id: null,
          main_contact_name: null,
         category: null,
          sub_category: null,
          main_contact_email: null,
         
        }
     
      }
    },
    methods:{
       getSubCategories () {
        //this.isLoading = true
             
        // I'm HERE --- get selected category and fill sub-categories
         var category = this.partner_edit_object.category
        this.$http.get(this.$store.getters.pubsServer + '/api/common/categories/' + category + '/', { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.sub_categories = response.body.sub_categories
   

        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
        })
      },
      editPartner(){
        this.isLoading=true
        this.partner = Object.assign({},this.partner)
          this.$http.put(this.$store.getters.pubsServer + '/api/publishers/partners/'+this.partner.partner_id+'/', this.partner_edit_object
          , { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.isOpen=false
          
          this.GetPartner();
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
        })
      },

     
        getCategories () {
        this.isLoading = true
        this.$http.get(this.$store.getters.pubsServer + '/api/common/categories/', { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.categories = response.body
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
        })
      },
      
    }

  }
</script>