
<template>
  <div id="map">
  <!--In the following div the HERE Map will render-->
    <div id="mapContainer" style="height:600px;width:100%" ref="hereMap"></div>
  </div>
</template>

<script>
import JSZip from 'jszip'
export default {
  name: "HereMap",
  props: {
    center: Object,
    zoom: Number,
    locations: Array,
  },
  data() {
    return {
      platform: null,
      is_drawing_mode: true,
      apikey: "gRaOarzekRoFSXgAS4GeESJPbxoDilts4t2pXpLZg4M",
      logContainer: {},
      // ---
      geofencing: null,
      poly_points: [],
      map_polygon: null,
      //map_polygon_id: null,
      fence_layer_id: "1234", //--TEMP--
      geofenceProxResults: {
        is_active: false,
        requests_sent: 0,
        responses_received: 0,
        locations: []
      },

    };
  },
  async mounted() {
    // Initialize the platform object:
    const platform = new window.H.service.Platform({
      //app_id: "CZKbLfgk8d1U2zjUaNuh",
      //app_code: "1FIn_87yoRenGJzM24Iquw",
      apikey: this.apikey
    });
    this.platform = platform;
    this.initializeHereMap();
  },
  methods: {
    initializeHereMap() { // rendering map
      let new_locations = []
      const mapContainer = this.$refs.hereMap;
      const H = window.H;
      // Obtain the default map types from the platform object
      var maptypes = this.platform.createDefaultLayers();

      // Instantiate (and display) a map object:
      var map = new H.Map(mapContainer, maptypes.vector.normal.map, {
        zoom: this.zoom,
        center: this.center
        // center object { lat: 40.730610, lng: -73.935242 }
      });

      // add UI
      const ui = H.ui.UI.createDefault(map, maptypes);

      // add markers
      this.locations.forEach(function (location, index) {
        const marker = new H.map.Marker({lat: location.latitude, lng: location.longitude})
        //console.log(location.latitude, location.longitude);
        //console.log(marker.getGeometry().lat, marker.getGeometry().lng);
        marker.setData(
          "<p>" + location.name + "</p>"
        )
        marker.addEventListener("tap", event => {
          ui.getBubbles().forEach(bub => ui.removeBubble(bub));
          const bubble = new H.ui.InfoBubble(
            event.target.getGeometry(),
            {
              content: event.target.getData()
            }
          );
          ui.addBubble(bubble);
        }, false)
        map.addObject(marker);
        // store marker.ID in new_locations
        location.marker_id = marker.getId()
        //console.log("location with marker:", location)
        new_locations.push(location)
      });
      //console.log("this.locations with marker ids:", this.locations)

      addEventListener("resize", () => map.getViewPort().resize());

      // add behavior control
      new H.mapevents.Behavior(new H.mapevents.MapEvents(map));

      // create custom logging facilities
      // --------- NOT WORKING (FIX) -----------
      this.logContainer = document.createElement('ul');
      this.logContainer.className ='log';
      this.logContainer.innerHTML = '<li class="log-entry">Try clicking on the map</li>';
      map.getElement().appendChild(this.logContainer);
      // --------- END NOT WORKING (FIX) -----------

      // add click listener (creating poly-geofence)
      this.setUpClickListener(map)

      // creating geofencing service
      this.geofencing = this.platform.getGeofencingService()

      // End rendering the initial map
    },

    setUpClickListener(map) {
      // Attach an event listener to map display
      // obtain the coordinates and display in an alert box.
      if (this.is_drawing_mode) {
        var self = this;
        map.addEventListener('tap', function (evt) {
          var coord = map.screenToGeo(evt.currentPointer.viewportX, evt.currentPointer.viewportY);
          //console.log('Clicked at ' + Math.abs(coord.lat.toFixed(4)) + ((coord.lat > 0) ? 'N' : 'S') + ' ' + 
          //  Math.abs(coord.lng.toFixed(4)) + ((coord.lng > 0) ? 'E' : 'W'));
          self.logEvent('Clicked at ' + Math.abs(coord.lat.toFixed(4)) + ((coord.lat > 0) ? 'N' : 'S') + ' ' + 
            Math.abs(coord.lng.toFixed(4)) + ((coord.lng > 0) ? 'E' : 'W'));
          self.addPointToGeofence(map, coord.lat.toFixed(6), coord.lng.toFixed(6))
        });
      }
    },
    logEvent(str) {
      // --------- NOT WORKING (FIX) -----------
      // Helper for logging events
      var entry = document.createElement('li');
      entry.className = 'log-entry';
      entry.textContent = str;
      this.logContainer.insertBefore(entry, this.logContainer.firstChild);
      // --------- NOT WORKING (FIX) -----------
    },
    addPointToGeofence(map, lat, lng) {
      const point = {latitude: lat, longitude: lng}
      this.poly_points.push(point)
      //console.log("this.poly_points:", this.poly_points)
      if (this.poly_points.length === 4) {
        const close_point = {latitude: this.poly_points[0].latitude, longitude: this.poly_points[0].longitude}
        this.poly_points.push(close_point)
        this.is_drawing_mode = false
        this.draw_geo_fence(map)
      }
    },

    draw_geo_fence(map) {
      //console.log("in draw_geo_fence()")
      const lineString = new H.geo.LineString()
      this.poly_points.forEach(function (point, index) {
        lineString.pushPoint({lat: point.latitude, lng: point.longitude})
      })
      const polygon = new H.map.Polygon(lineString)
      this.map_polygon = polygon
      //this.map_polygon_id = polygon.getId()
      //console.log('this.map_polygon_id:', this.map_polygon_id)
      this.map_polygon = map.addObject(polygon)
      //console.log('this.map_polygon:', this.map_polygon)
      // --- create geoFence ---
      const geometry = polygon.getGeometry()
      const wkt = geometry.toString()
      //const wkt = map.polygonToWKT(polygon)
      //console.log("wkt geoFencing geometry:", wkt)
      const zip = new JSZip()
      zip.file("data.wkt", "NAME\tWKT\n" + "geofence" + "\t" +  wkt)

      zip.generateAsync({ type: "blob" }).then(content => {
        var formData = new FormData()
        formData.append("zipfile", content)

        this.$http.post('https://gfe.api.here.com/2/layers/upload.json/', formData, {
          headers: {
            "content-type": "multipart/form-data"
          },
          params: {
            "app_id": "LAAWHqlXMelQmQQZr9Nk", // "CZKbLfgk8d1U2zjUaNuh", //"ElgwLzwG8eHNpNlBGCzk", //
            "app_code": "pVbT5mCjap-i6-vDajT8dA", //"1FIn_87yoRenGJzM24Iquw", //"XmcQjft4Au6Z7We-U5NK4w", //
            "apiKey": this.apikey,
            "layer_id": this.fence_layer_id
          }
        }).then(result => {
          console.log("geoFence post resutl:", result)
          // TEMP Call -- must be a button "Get Locations"
          this.getLocationsInsideFence()
        }, error => {
          console.error(error)
        })
      }, error => {
        console.error(error)
      });

    },
    getLocationsInsideFence() {
      this.reset_geofence_prox_results()
      this.geofenceProxResults.is_active = true
      var self = this
      this.locations.forEach(function (location, index) {
        //console.log("checking location:", location.latitude + "," + location.longitude)
        self.geofenceProxResults.requests_sent = self.geofenceProxResults.requests_sent + 1
        self.geofencing.request(
          H.service.extension.geofencing.Service.EntryPoint.SEARCH_PROXIMITY,
          {
              'layer_ids': [self.fence_layer_id],
              'proximity': location.latitude + "," + location.longitude,
              'key_attributes': 'NAME'
          },
          result => {
            if (result.geometries.length > 0) {
              self.geofenceProxResults.locations.push(location)
            }
            self.geofenceProxResults.responses_received = self.geofenceProxResults.responses_received + 1
            self.validate_geofence_requests_status()
          },
          error => {
            console.error(error)
            self.geofenceProxResults.responses_received = self.geofenceProxResults.responses_received + 1
            self.validate_geofence_requests_status()
          }
        )
      })
    },
    validate_geofence_requests_status() {
      if (this.geofenceProxResults.is_active && this.geofenceProxResults.responses_received >= this.geofenceProxResults.requests_sent) {
        this.geofenceProxResults.is_active = false
        console.log("Finished searching locations inside geofence, results:", this.geofenceProxResults)
        console.log("READY TO MAKE BULK ACTIONS ON SELECTED LOCATIONS")
        console.log("TODO: Create proper workflow...")
      }
    },
    reset_geofence() {
      this.reset_geofence_prox_results(false)
      map.removeObjects(this.map_polygon)
      this.map_polygon = null
      //this.map_polygon_id = null
      this.poly_points = []
    },
    reset_geofence_prox_results() {
      this.geofenceProxResults.is_active = false
      this.geofenceProxResults.requests_sent = 0
      this.geofenceProxResults.responses_received = 0
      this.geofenceProxResults.locations = []
    },

  },
};
</script>

<style scoped>
#map {
  width: 75vw;
  min-width: 360px;
  text-align: center;
  margin: 3% auto;
  background-color: #ccc;
}
</style>