<template>
  <v-container fluid class="py-0">
   <loading-dialog v-model="isLoading"></loading-dialog>
 
       <v-card-title class="card-title grey--text font-20 pa-0 pl-4"   v-if="partner">
          <v-row>
         <router-link style="color:black" to="/Partners/view"> <v-icon large >mdi-arrow-left</v-icon> </router-link>
            <v-row class=" grey--text font-weight-black text--lighten-1 pa-0 pl-6 " >
              <p  style="font-size: 30px;" > 
               Partner Name: {{partner.name}}</p>
              </v-row>   

        </v-row>
       
      </v-card-title>
     

   <v-card-title class="font-14">
    <v-row   class="py-0" v-if="partner" sm="12" xl="12" md="12" lg="6"  xs="12"  >
       <v-col   class=" py-0 my-0 " >
         <locations-details-dialog 
         v-model="locationsDetailsDialog"
     
         :Partner="partner"
         :GetPartner='getPartner'
           >
         </locations-details-dialog> 
         <gifts-payments-dialog 
         v-if="giftsPaymentsDialog"
         v-model="giftsPaymentsDialog"
         :gifts_payments='gifts_payments'
         :id=Number(partner_id)
         @input="getPartner"
         scope="Partner" 
         >

         </gifts-payments-dialog>
         <collect-payments-dialog 
         v-if="collectPaymentsDialog"
         v-model="collectPaymentsDialog"
         :collect_payments='collect_payments'
         :id=Number(partner_id)
         @input="getPartner"
         scope="Partner"         
         >

         </collect-payments-dialog>
         <balance-details-dialog
           v-model="balanceDetailsDialog"
           :Balance='partnerDashBalance'    
           :sponsor="false"      
          > 
        </balance-details-dialog>
        <v-row   >
           <v-col cols="12"  class="pt-0" >
            <v-card >
              <v-card-text class="pb-0">
               <p class="card-title font-20 text--darken-3">
                  Profile Details : 
                </p>
                <table class="table ">
                   <tbody class=".table-bordered">
                    <tr>
                      <td align="left"><div >  Name :   </div></td>
                      <td><span >{{ partner.main_contact_name }} </span></td>
                    </tr>
                    <tr>
                      <td align="left"><div >Email :</div></td>
                      <td><span >{{ partner.main_contact_email }}</span></td>
                    </tr>
                    <tr>
                      <td align="left"><div >Phone :</div></td>
                      <td><span >{{ partner.main_contact_phone }}</span></td>
                    </tr>
                      <tr>
                      <td align="left"><div >Allow Login :</div></td>
                      <td><span >
                        <v-icon small v-if="partner.allow_login"> 
                          check
                         </v-icon> 
                         <v-icon small v-else>
                           close
                         </v-icon>
                        
                        </span></td>
                    </tr>
                      <tr>
                      <td align="left"><div >Client Status :</div></td>
                      <td><span >{{getClientStatus(partner.client_status)  }}</span></td>
                    </tr>
                        <tr>
                      <td align="left"><div > Max Users :</div></td>
                      <td><span > {{ partner.max_users }}</span></td>
                    </tr>
                     <tr>
                      <td align="left"><div >Date Added :</div></td>
                      <td><span >{{ partner.date_added }}</span></td>
                    </tr>
                     <tr>
                      <td align="left"><div > Category Name :</div></td>
                      <td><span > {{ partner.category_name }}</span></td>
                    </tr>
                     <tr>
                      <td align="left"><div >Sub Category  :</div></td>
                      <td><span > {{ partner.sub_category_name }}</span></td>
                    </tr>
                   </tbody>
                </table>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="text-xs-right bold font-12"
                  text
                  color="teal darken-2"
                    @click="getLocation"
                >
                  Edit 
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
           
          <v-col cols="12">
              <v-card>
              <v-card-text class="pb-4">
                <p class="card-title font-20 text--darken-3">
                  Campaigns
                </p>
                <table class="dashboardTableSpacing body-2">
                    <tr>
                      <td align="left"><div >Total Campaigns:</div></td>
                      <td><span >{{ partner.partner_dash.campaigns.total_campaigns }}</span></td>
                    </tr>
                    <tr>
                      <td align="left"><div >Total Schedules:</div></td>
                      <td><span >{{ partner.partner_dash.campaigns.total_schedules }}</span></td>
                    </tr>
                    <tr>
                      <td align="left"><div >Active Schedules:</div></td>
                      <td><span >{{ partner.partner_dash.campaigns.active_schedules }}</span></td>
                    </tr>
                    <tr>
                      <td align="left"><div >Executed Schedules:</div></td>
                      <td  align="left" ><span>{{ partner.partner_dash.campaigns.executed_schedules }}</span></td>
                    </tr>
                </table>
              </v-card-text>
              
              <v-card-actions>
                <v-spacer></v-spacer>
         <router-link  style=" color:white;" :to="{ path: '/partners/CampaignsDashboard'+'/' , query: { id: partner.partner_id }}" append>     <v-btn color="secondary" fab x-small dark>
              <v-icon>mdi-television</v-icon>
            </v-btn></router-link>
          </v-card-actions>
            </v-card>
        
          </v-col>  
           <v-col cols="12" >
             <v-card >

              <v-card-text  class="pb-3">
                <p class="card-title font-20 text--darken-3">
                  Balance
                </p>
                <table class="dashboardTableSpacing body-2">
                    <tr>
                      <td align="left"><div >Total Balance:</div></td>
                      <td><span >${{ partner.partner_dash.balance.total_balance }}</span></td>
                      </tr>
                    <tr>
                      <td align="left"><div >Cleared Amount:</div></td>
                      <td><span >${{ partner.partner_dash.balance.cleared_amount}}</span></td>
                    </tr>
                    <tr>
                      <td align="left"><div class=" darken-3 " >Scheduled Amount:</div></td>
                      <td><span >${{ partner.partner_dash.balance.scheduled_amount }}</span></td>
                    </tr>
                    <!--tr>
                      <td align="left"><div >Executed Slots Amount:</div></td>
                      <td><span >${{ partner.partner_dash.balance.executed_slots_amount }}</span></td>
                    </tr>
                    <tr>
                      <td align="left"><div >On Hold Amount:</div></td>
                      <td><span >${{ partner.partner_dash.balance.on_hold_amount }}</span></td>
                    </tr-->
                </table>
              </v-card-text>
              <v-card-actions >
                <v-btn
                  class="text-xs-right bold font-12"
                  text
                  color="blue lighten-1"
                  @click="getGiftsPayments"
                >
                  Gifts Payments
                </v-btn>
                <v-spacer></v-spacer>
                 <v-btn
                  class="text-xs-right bold font-12"
                  text
                  color="blue lighten-1"
                  @click="getcollectPayments"
                >
                 Collect Payments
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  class="text-xs-right bold font-12"
                  text
                  color="teal darken-2"
                  @click="getBalance"
                >
                  View
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>          
        </v-row>
      </v-col>
      <v-col cols="12" md="6" order-md="12" offset-md="12" class="py-0 my-0">
        <v-row  >
      <v-col cols="12" class="pt-0" > 
              <v-card>
              <v-card-text class="pb-0">
               <p class="card-title font-20 text--darken-3">
                  Locations
                </p>
                <table class="dashboardTableSpacing body-2">
                    <tr>
                      <td align="left"><div >Total Locations:</div></td>
                      <td><span >{{ partner.partner_dash.locations.total_locations }}</span></td>
                    </tr>
                    <tr>
                      <td align="left"><div >Total Sites:</div></td>
                      <td><span >{{ partner.partner_dash.locations.total_sites }}</span></td>
                    </tr>
                    <tr>
                      <td align="left"><div >Active Sites:</div></td>
                      <td><span >{{partner.partner_dash.locations.total_active_sites }}</span></td>
                    </tr>
                </table>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
               <router-link  style=" color:white;" :to="{ path: '/locations'+'/' ,  query: { id: partner.partner_id }}" append> 
                   <v-btn color="secondary" fab x-small  dark>
              <v-icon>mdi-television</v-icon>
            </v-btn></router-link>

              </v-card-actions>
            </v-card>
          </v-col>
            
          <v-col cols="12" class="pt-0" >
            <v-card>
              <v-card-text  class="pb-0">
                <p class="card-title font-20 text--darken-3">
                  Media
                </p>
                <table class="dashboardTableSpacing body-2">
                    <tr>
                      <td align="left"><div >Total Media:</div></td>
                      <td><span >{{ partner.partner_dash.media.total_media }}</span></td>
                    </tr>
                    <tr>
                      <td align="left"><div >Total Images:</div></td>
                      <td><span >{{ partner.partner_dash.media.total_media_images }}</span></td>
                    </tr>
                    <tr>
                      <td align="left"><div >Total Videos:</div></td>
                      <td><span >{{ partner.partner_dash.media.total_media_videos }}</span></td>
                    </tr>
                    <tr>
                      <td align="left"><div >Total Audio:</div></td>
                      <td><span >{{ partner.partner_dash.media.total_media_audios }}</span></td>
                    </tr>
                    <tr>
                      <td align="left"><div >Total Media Storage:</div></td>
                      <td><span >{{ partner.partner_dash.media.media_storage_mb }} MB</span></td>
                    </tr>
                </table>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
             <router-link  style=" color:white;" :to="{ path: '/media'+'/' , query: { id: partner.partner_id }}" append>     <v-btn color="secondary" fab x-small dark>
              <v-icon>mdi-television</v-icon>
            </v-btn></router-link>
              </v-card-actions>
            </v-card>
          </v-col>
             <v-col  class=".d-md-none .d-md-flex pt-0" >
            <v-card  min-height="350px">
              <v-card-text class="pb-0"  >
               <p class="card-title font-20 text--darken-3">
                  Users
                </p>
                <v-data-table 
                hide-default-footer
                class=" darken-1 "
                v-if="users.length !==0"
                dense
                :headers="headers"
                :items="users"
            >
          <template v-slot:body="{ items }">
            <tr v-for="(item, key) in items" :key="key">
              <!-- <td align="left" style=" font-size: 12px " >{{ item.first_name }} {{item.last_name}}</td> -->
              <td align="left" style=" font-size: 12px " >{{ item.email }}</td>
              <td align="center"> 
                <v-icon v-if="!item.is_Admin"> 
                  check
                </v-icon> 
                <v-icon v-else>
                  close
                </v-icon>
            
                </td>
              <td align="center"> {{getUserStatus(item.account_status)}} </td>
              
                 <td> 
                    <v-row>
                     <v-col  v-if="(getUserStatus(item.account_status) ==='ACTIVE') ||(getUserStatus(item.account_status) ==='SUSPENDED')">
                    <v-tooltip bottom v-if="!(getUserStatus(item.account_status) ==='ACTIVE')">
                        <template v-slot:activator="{ on }">
                      <v-btn
                        class="ma-2"
                        v-on="on"
                          x-small
                          color="blue lighten-2"
                        @click="activatDeactivate(item)"
                        >
                        Activate
                       <v-icon
                         dark
                         right
                      >
                      mdi-checkbox-marked
                     </v-icon>
                     </v-btn>
                          </template>
                          <span>activate</span>
                      </v-tooltip>
                   
                        <v-tooltip bottom v-else>
                        <template v-slot:activator="{ on }">
                         <v-btn v-on="on"
                          class="ma-1"
                          x-small
                          color="red " 
                           @click="activatDeactivate(item)"
                          >
                           Deactivate
                             <v-icon x-small dark  > 
                                mdi-cancel
                             </v-icon> 
                         </v-btn>
                          </template>
                          <span>deactivate</span>
                      </v-tooltip>
                   
                     </v-col>
                   </v-row>
                </td>
            </tr>
          </template>
        </v-data-table> 
        <p v-else> Empty </p>
              </v-card-text>
             
            </v-card>
            
          </v-col>
        </v-row>
      </v-col>
    </v-row>
</v-card-title>
  </v-container>
</template>
<script>
  export default {
   
    data () {
      return {
        isLoading: false,
        isPageLoading: false,
        partner:null,
        campaignDetailsDialog:false,
        locationsDetailsDialog:false,
        balanceDetailsDialog:false,
        editpartnerdialog:false,
        giftsPaymentsDialog:false,
        collectPaymentsDialog:false,
        partner_id:null,
        campaign:[],
        location:[],
        sub_categories:[],
        host:[],
        users:[],
        gifts_payments:[],
        collect_payments:[],
        partnerDashBalance:Object.assign({},null),
         headers: [
          {
            text: 'Name',
            align: 'left',
           sortable: false,
            value: 'code',
            class: 'tableTitle'
          },
          
          { text: 'Is Admin', value: 'Is_Admin', sortable: false, align: 'left', class: 'tableTitle' },
          { text: 'AccountStatus', value: 'account_status', sortable: false, align: 'right ', class: 'tableTitle' },
          { text: 'Operations', value: 'Operations', sortable: false, align: 'center ', class: 'tableTitle' }
          
        ],
      }
      
    },
    
    methods: {
        getPartner(){
          if(this.partner_id===null){
           this.partner_id=this.$route.query.id
            
             }
            
           this.isLoading = true
           this.$http.get(this.$store.getters.pubsServer + '/api/publishers/partners/'+this.partner_id, { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.partner = response.body
          this.getUser()
      
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
        })
          this.$http.get(this.$store.getters.pubsServer + '/api/publishers/partners/hosts/?partner_id='+this.partner_id, { headers: {
             Authorization: 'Token ' + this.$store.getters.token
          }
        })
          .then(
            (res) => {
              this.isDashboardLoading = false
              this.host = res.body
            }
          )
          .catch((error) => {
            this.isDashboardLoading = false
           console.log('error')

            console.log(error)
            if (error.status === this.unauthorized) {
              this.showSessionExpired()
            }
          })
          
        },
      getUser(){
         
           this.$http.get(this.$store.getters.pubsServer + '/api/publishers/partners/users/?partner_id='+this.partner_id, { headers: {
             Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          
          this.users=response.body
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
             }
        })
          
        },
     
        getLocation(){
          this.isLoading=true;
          this.getPartner()
          this.locationsDetailsDialog=true
        },
        getBalance(){
          // this.isLoading=true;
          this.partnerDashBalance= Object.assign({},this.partner.partner_dash.balance)
          this.balanceDetailsDialog=true
        },
        activatDeactivate(User){
          this.isLoading=true
          this.$http.put(this.$store.getters.pubsServer+'/api/publishers/partners/users/activate_deactivate/'+User.id+'/',{} , { headers: {
          Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.getUser();
          this.isLoading=false
          
         
        }, error => {
          this.isLoading = false
          console.log(error)
        })
        },
        getClientStatus (status) {
        if (status === 1) {
          return 'Legal Operations'
        } else if (status === 2) {
          return 'Soft Operations'
        } else if (status === 3) {
          return 'Full Operations'
        } else if (status === 4) {
          return 'Suspended'
        } else if (status === 5) {
          return 'Deleted'
        }
      },
      getGiftsPayments(){
      this.$http.get(this.$store.getters.pubsServer + '/api/publishers/partners/gifts_payments/?partner_id='+this.partner_id, { headers: {
             Authorization: 'Token ' + this.$store.getters.token
          }
        })
          .then(
            (res) => {
            this.gifts_payments=res.body
            this.giftsPaymentsDialog=true
            }
          )
          .catch((error) => {
            this.isDashboardLoading = false
           console.log('error')

            console.log(error)
            if (error.status === this.unauthorized) {
              this.showSessionExpired()
            }
          })
        

      },
         getcollectPayments(){
      this.$http.get(this.$store.getters.pubsServer + '/api/publishers/partners/collect_payments/?partner_id='+this.partner_id, { headers: {
             Authorization: 'Token ' + this.$store.getters.token
          }
        })
          .then(
            (res) => {
            this.collect_payments=res.body
            this.collectPaymentsDialog=true
            }
          )
          .catch((error) => {
            this.isDashboardLoading = false
           console.log('error')

            console.log(error)
            if (error.status === this.unauthorized) {
              this.showSessionExpired()
            }
          })
      },
        getUserStatus (status) {
        if (status === 1) {
          return 'PENDING'
        } else if (status === 2) {
          return 'ACTIVE'
        } else if (status === 3) {
          return 'SUSPENDED'
        } else if (status === 4) {
          return 'DELETED'
        } else if (status === 5) {
          return 'BROKEN '
        }else if(status === 6){
          return 'DECLINED'
        }
      },
     
    },

      created () {
      this.getPartner()
     this.$router.push({ query: "" }) 
      }
  
  }
</script>

<style>
   td.clickable_td {
    color: #156CA8;
    cursor: pointer;
    /* text-decoration: underline; */
  }

  td.clickable_td:hover {
      color: white;
  }

  div.titleTextColor{
    color: #777;
    font-weight: bold;
    font-size: 13px;
  }

  span.secondTextColor{
    color: #616161;
  }

  .seconderyTextColor{
    color: #616161;
  }

  .dashboardTableSpacing{
    border-spacing: 10px 5px;
  }
</style>
