<template>
  <v-container >
    <v-card>
      <v-dialog  v-model="isOpen"  persistent max-width="500px" scrollable>
            <v-card>
           <v-card-title class="font-14">New User</v-card-title>
              <v-card-text style="height: 400px; ">
                <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field dense label="First Name" v-model="defaultUser.first_name" required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field dense label="Last Name" v-model="defaultUser.last_name"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field dense label="Email" type="email" v-model="defaultUser.email" required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select
                      class="font-14"
                      :items="  user_scopes "
                      v-model="defaultUser.user_scope"
                      label="User Scope"
                      dense
                      hide-details
                    ></v-select>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-checkbox dense class="compact-form" v-on="on" label="Is Admin" v-model="defaultUser.is_admin"></v-checkbox>
                        </template>
                        <span>An admin has all other roles and can also configure users</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-checkbox dense class="compact-form" v-on="on" label="Playlists Role" :disabled="defaultUser.is_admin" v-model="defaultUser.playlists_role"></v-checkbox>
                        </template>
                        <span>Anyone with playlists role can add, edit and configure playlists</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-checkbox dense class="compact-form" v-on="on" label="Config Role" :disabled="defaultUser.is_admin" v-model="defaultUser.config_role"></v-checkbox>
                        </template>
                        <span>Anyone with config role can configure hosts e.g. Update default playlist, Update Auxiliary Playlist</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-checkbox dense class="compact-form" v-on="on" label="Accounting Role" :disabled="defaultUser.is_admin" v-model="defaultUser.accounting_role"></v-checkbox>
                        </template>
                        <span>Anyone with accounting role, can view current balance, make a claim request and access accounting page</span>
                      </v-tooltip>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-checkbox dense class="compact-form" v-on="on" label="Campaigns Role" :disabled="defaultUser.is_admin" v-model="defaultUser.campaigns_role"></v-checkbox>
                        </template>
                        <span>Anyone with campaigns role, can create new campaigns and publish them</span>
                      </v-tooltip>
                    </v-col>
                 
                  </v-row>
           
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="dialogue-btn" color="blue darken-3" text @click="isOpen= false">Cancel</v-btn>
                <v-btn class="dialogue-btn" color="blue darken-3" text @click.native="saveUser">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
       </v-card>
  </v-container>
</template>

<script>
  export default{

    props: {
      value:Boolean,
      sponsor_id:Number,
      getUsers:Function
    },
     computed: {
      isOpen: {
        get () {
          return this.value
        },
        set (val) {

          this.$emit('input', val)
      
        }
      }
    },
    data () {
      return {
      
        isLoading: false,
       captcha: '',
        user_scopes_with_admin: [
          {text: 'Account Scope', value: 1},
          {text: 'Host Group Scope', value: 2},
          {text: 'Account Admin Scope', value: 3}
        ],
        user_scopes: [
          {text: 'Account Scope', value: 1},
          {text: 'Host Group Scope', value: 2}
        ],
       
        defaultUser: {
          first_name: '',
          last_name: '',
          email: '',
          user_scope: 3,
          is_admin: false,
          playlists_role: false,
          config_role: false,
          accounting_role: false,
          campaigns_role: false
        },
        kDialog: false,
        kDialogTitle: '',
        kDialogText: ''
      }
    },
   
    methods: {
     getCaptcha (response) {
        this.captcha = response
      },
   
      closeUsers () {
        this.isOpen = false
        setTimeout(() => {
          this.defaultUser = Object.assign({}, this.defaultUser)
     
        }, 300)
      },
      saveUser () {
        this.isLoading = true
          this.$http.post(this.$store.getters.pubsServer  + '/api/publishers/sponsors/users/?sponsor_id='+this.sponsor_id,
            this.defaultUser, {
              headers: {
                Authorization: 'Token ' + this.$store.getters.token
              }
            })
            .then(
              (res) => {
                this.isLoading = false
                this.getUsers()
                  this.isOpen = false
             
              }
            )
            .catch((error) => {
              this.isLoading = false
              if (error.status === this.unauthorized) {
                this.showSessionExpired()
              }
              console.log(error.response)
            })
        }
       
      },
  
    
      
   
  }
</script>

<style>

  div.titleTextColor{
    color: #777;
    font-weight: bold;
    font-size: 13px;
  }

  span.secondTextColor{
    color: #616161;
  }

  .seconderyTextColor{
    color: #616161;
  }

  .tableSpacing{
    border-spacing: 10px 0;
  }

</style>
