<template>
  <v-container fluid>
   
    <loading-dialog v-model="isLoading"></loading-dialog>
        <v-row justify="center" >
      <v-col v-if="sponserDialog">
             <sopnser-profile  :sponsor_id="sponsor_id"  v-model="sponserDialog" >
             </sopnser-profile>
                  </v-col>
                  
     <v-col cols="12" md="10" lg="9" xl="9" v-if="!sponserDialog">
       <add-new-premium @saved="getPartners" v-if="addnewpremium" v-model="addnewpremium"></add-new-premium>
        <v-toolbar  dense class="white--text font-weight-black teal darken-4">
            <v-row>
              <v-toolbar-title >
            Sponsors
          </v-toolbar-title>
            </v-row>
               <v-tooltip bottom>
            <template v-slot:activator="{ on }">
            <v-btn icon  v-on="on" right @click="getNewSponser" > 
              <v-icon > mdi-account-plus
                </v-icon> 
                </v-btn>
            </template>
            <span>Add New Sponsor </span>
               </v-tooltip>
        </v-toolbar>
        
        <v-row>
          <v-col cols="12" v-for="(partner, key) in partners" :key="key">
            <v-card color="teal darken-3 white--text" style="background-color: #fffaf8;" raised>
              <v-card-title class="font-15 pb-1"  >
                <v-row>
                  <v-col class="font-weight-medium font-16 ">
               Name :  {{ partner.name }}
                  </v-col>
                  <v-col md="5"></v-col>
                  <v-col  md="1">
            <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" @click="getSponsor(partner)">
                  <v-avatar  color=" white darken-3  " >
                  <v-icon large   color="teal darken-3">
                 mdi-account-circle
               </v-icon>
                  </v-avatar>
                </v-btn>
            
            </template>
                <span style=""> {{ partner.name }}  Profile </span>
            </v-tooltip>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-subtitle class="font-14 bold white--text font-weight-black " >
                {{ getClientStatus(partner.client_status) }}
              </v-card-subtitle>
              <div class="px-8">
                <v-simple-table dense light>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td class="bold" >Name</td>
                        <td  style="text-align:left">
                          {{ partner.main_contact_name }}
                        </td>
                      </tr>
                      <tr>
                        <td class="bold">Email</td>
                        <td  style="text-align:left">
                          {{ partner.main_contact_email }}
                        </td>
                      </tr>
                      <tr>
                        <td class="bold">Phone</td>
                        <td  style="text-align:left">
                          {{ partner.main_contact_phone }}
                        </td>
                      </tr>
                          <tr>
                        <td class="bold">Category </td>
                        <td  style="text-align:left">
                          {{ partner.category_name }}
                        </td>
                      </tr>
                          <tr>
                        <td align="left" class="bold">Sub Category </td>
                        <td  style="text-align:left">
                          {{ partner.sub_category_name }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                
              </div>
              <v-col ></v-col>
        
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
        isLoading: false,
        partners: [],
        sponsor_id:null,
        sponserDialog:false,
        isOpen:false,
        addnewpremium:false

        
      }
    },
    methods: {
      getPartners () {
        this.isLoading = true
        this.$http.get(this.$store.getters.pubsServer + '/api/publishers/sponsors/', { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.partners = response.body
          
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
        })
      },
      getClientStatus (status) {
        if (status === 1) {
          return 'Legal Operations'
        } else if (status === 2) {
          return 'Soft Operations'
        } else if (status === 3) {
          return 'Full Operations'
        } else if (status === 4) {
          return 'Suspended'
        } else if (status === 5) {
          return 'Deleted'
        }
      },
      getSponsor(Sponsor){
          this.sponsor_id=Sponsor.sponsor_id
          this.sponserDialog=true
          this.isOpen=true
      },
      getNewSponser(){
          this.addnewpremium=true
      }
    },
    
    created () {
      this.getPartners()
        this.$router.push('')
      
    }
  }
</script>

