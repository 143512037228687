<template>
  <v-navigation-drawer
    app
    dark
    floating
    persistent
    mobile-break-point="991"
    width="260"
  >
    <v-img
      height="100%"
    >
      <v-list
        class="fill-height"
        dense
      >
        <v-list-item>
          <v-list-item-content
          >
            <v-img
              :src="require('@/assets/oscreens_logo.png')"
              height="34"
              contain
            />
          </v-list-item-content>
        </v-list-item>
        <v-divider/>
        <div v-for="(link, i) in links"
            :key="i">
          <v-list-group :prepend-icon="link.icon" class="mt-1" v-if="link.is_group" :to="link.to">
            <template v-slot:activator>
              <v-list-item-title>{{ link.text }}</v-list-item-title>
            </template>
            <v-list-item
              v-for="(item, key) in link.items"
              class="mt-1"
              :key="key"
              :to="item.to"
            >
              <v-list-item-content>
                <v-list-item-title
                  class="font-13"
                  v-text="item.text"
                />
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else
            class="mt-1"
            :to="link.to"
          >
            <v-list-item-icon>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                class="font-14"
                v-text="link.text"
              />
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-img>
  </v-navigation-drawer>
</template>

<script>

export default {
  props: {
    value: Boolean
  },
  computed: {
    isOpened: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  data: () => ({
    links: [
      {
        to: '/',
        icon: 'dashboard',
        is_group: false,
        text: 'Dashboard'
      },
      {
        to: '/hosts',
        icon: 'tv',
        is_group: false,
        text: 'Hosts'
      },
      {
        to: '/media-assets',
        icon: 'image',
        is_group: false,
        text: 'Media'
      },    
      {
        to: '/review-requests',
        icon: 'rate_review',
        is_group: false,
        text: 'Media Review Requests'
      },
      {
        to: '/clients',
        icon: 'mdi-account',
        is_group: false,
        text: 'Clients'
      },
      {
        to: '/Balance',
        icon: 'mdi-currency-usd',
        is_group: false,
        text: 'Balance'
      },
      {
        to: '/locations',
        icon: 'mdi-map-marker',
        is_group: false,
        text: 'Locations'
      },
      {
        to: '/partners/',
        icon: 'mdi-handshake',
        is_group: true,
        text: 'Partners',
        items: [
          {
            to: '/partners/view',
            is_group: false,
            text: 'Partners Page'
          },
          {
            to: '/partners/registration_requests',
            is_group: false,
            text: 'Partner Registration Requests'
          }
        ]
      },
      {
        to: '/PremiumClients',
        icon: 'account_tree',
        is_group: false,
        text: 'Premium Clients'
      }
    ]
  })
}
</script>
