class BitmapRenderer {
  constructor () {
    this.canvas = document.createElement('canvas')
    this.ctx = this.canvas.getContext('2d')
  }

  render (data, ...opts) {
    this.canvas.setAttribute('width', data.width)
    this.canvas.setAttribute('height', data.height)
    this.ctx.clearRect(0, 0, data.width, data.height)

    if (data.backgroundType === 'image' && data.backgroundImage !== null) {
      var backgroundImg = data.backgroundImage
      this.ctx.filter = 'blur(' + data.backgroundBlur + '.0px)'
      this.ctx.drawImage(backgroundImg, 0, 0, data.width, data.height)
    } else {
      this.ctx.fillStyle = data.backgroundColor.hex
      this.ctx.fillRect(0, 0, data.width, data.height)
      this.isLoaded = true
    }

    for (var i = 0; i < data.foregrounds.length; i++) {
      if (data.foregrounds[i].image !== null) {
        var foreground = data.foregrounds[i]
        var img = foreground.image
        this.ctx.filter = 'none'
        this.ctx.drawImage(img, foreground.foregroundX, foreground.foregroundY, img.width * foreground.foregroundScale, img.height * foreground.foregroundScale)
      }
    }

    this.ctx.filter = 'none'
    for (var i = 0; i < data.captions.length; i++) {
      this.ctx.font = `${data.captions[i].fontWeight} ${data.captions[i].fontSize}px ${data.fontFamily}`
      this.ctx.textBaseline = 'middle'
      this.ctx.textAlign = 'center'
      this.ctx.fillStyle = data.captions[i].textColor.hex
      this.ctx.fillText(data.captions[i].text, data.captions[i].captionX, data.captions[i].captionY)
    }

    if (opts && opts.length === 1) {
      return this.canvas.toDataURL(opts[0])
    } else if (opts && opts.length === 2) {
      return this.canvas.toDataURL(opts[0], opts[1])
    } else {
      return this.canvas.toDataURL()
    }
  }

  sleep (miliseconds) {
    var currentTime = new Date().getTime()

    while (currentTime + miliseconds >= new Date().getTime()) {
      console.log('Not')
    }
  }
}

export default BitmapRenderer
