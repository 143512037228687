<template>
  <v-container fluid>
    <loading-dialog v-model="isLoading"></loading-dialog>
    <review-media-dialog 
      :review_request_id="selected_review_id"
      :reviewClosed="onReviewClosed"
      :reviewFinished="onReviewFinished">
    </review-media-dialog>
    <v-row justify="center">
      <v-col cols="12" md="11" lg="10" xl="9">
        <v-toolbar dense>
          <v-toolbar-title class="font-16">
            Media Review Requests
          </v-toolbar-title>
        </v-toolbar>
        <v-row>
          <v-col cols="12" sm="10" md="4" v-for="(request, key) in review_requests" :key="key">
            <media-review-item
              :value="request"
              :reviewSelected="onReviewSelected">
            </media-review-item>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
        isLoading: false,
        review_requests: [],
        selected_review_id: 0
      }
    },
    methods: {
      getReviewRequests () {
        this.isLoading = true
        this.$http.get(this.$store.getters.pubsServer + '/api/media_reviews/review_requests/', { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.review_requests = response.body
          console.log(response.body)
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
        })
      },
      onReviewSelected (review_request_id) {
        this.selected_review_id = review_request_id
      },
      onReviewClosed () {
        this.selected_review_id = 0
      },
      onReviewFinished () {
        this.getReviewRequests()
      }
    },
    created () {
      this.getReviewRequests()
    }
  }
</script>