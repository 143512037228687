<template>
  <v-dialog v-model="isOpen" max-width="1000px">
    <v-card class="grid">
      <v-card-title>
        <span class="headline">Select Media</span>
      </v-card-title>
      <v-container fluid>
        <media-row :scope="scope" :mediaSelectedCallback="submitMedia"></media-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'alert-dialog',
    props: {
      value: Boolean,
      scope: String,
      mediaSelectedCallback: Function
    },
    computed: {
      isOpen: {
        get () {
          return this.value
        },
        set (isOpen) {
          this.$emit('input', isOpen)
        }
      }
    },
    methods: {
      submitMedia (e) {
        if (this.mediaSelectedCallback) {
          this.mediaSelectedCallback(e)
        }
      }
    }
  }
</script>

<style>
  .singleline{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
</style>
