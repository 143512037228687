var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"py-0",attrs:{"fluid":""}},[_c('loading-dialog',{model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}}),(_vm.balance)?_c('v-card-title',{staticClass:"font-18"},[(_vm.balance !== null)?_c('v-row',{staticClass:"py-0"},[_c('v-col',{staticClass:"py-2 ps-5",attrs:{"cols":"12"}},[_c('v-card',[_c('v-app-bar',{attrs:{"color":"blue-grey darken-3"}},[_c('v-spacer'),_c('v-toolbar-title',{staticClass:"font-20  justify-center"},[_vm._v("Balance")]),_c('v-spacer')],1)],1),_c('v-container',{staticClass:"grey lighten"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"pa-3",attrs:{"cols":"6"}},[_c('v-card',{staticClass:"grey darken-3 pa-2",attrs:{"min-width":"200","elevation":"2"}},[_c('v-card-title',{staticClass:"font-20 font-weight-black  justify-center"},[_vm._v("Outstanding Balance")]),_c('v-card-text',{staticClass:"mt-3 pb-10 text-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({class:'font-25 bold ' + (_vm.balance.total_balance < 0 ? 'red--text' : 'indigo--text text--darken-1')},on),[_vm._v(" $"+_vm._s(_vm.balance.total_balance))])]}}],null,false,2046362396)},[(_vm.balance.total_balance < 0)?_c('span',[_vm._v("Your Account Balance is Negative. Please settle your due amount")]):_c('span',[_vm._v("Your Account Balance. You can use this balance to shcedule your campaings")])])],1)],1)],1),_c('v-col',{staticClass:"pa-3",attrs:{"cols":"6"}},[_c('v-card',{staticClass:"white",attrs:{"elevation":"1"}},[_c('v-card-text',{staticClass:"mt-1 pb-3 text-center"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",staticStyle:{"text-align":"right"},attrs:{"cols":"8"}},[_c('span',{staticClass:"font-14 bold grey--text text--darken-3 justify-center"},[_vm._v("Scheduled Slots Sales: ")])]),_c('v-col',{staticClass:"pa-0 ms-2",staticStyle:{"text-align":"left"},attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"font-15 bold indigo--text text--darken-1",staticStyle:{"text-align":"left"}},on),[_vm._v(" $"+_vm._s(_vm.balance.scheduled_amount)+" ")])]}}],null,false,1121609784)},[_c('span',[_vm._v("Upcoming sales revenues (not confirmed yet)")])])],1)],1),_c('v-row',[_c('v-col',{staticClass:"pa-0",staticStyle:{"text-align":"right"},attrs:{"cols":"8"}},[_c('span',{staticClass:"font-13 grey--text text--darken-3 justify-center"},[_vm._v("Online Campaigns: ")])]),_c('v-col',{staticClass:"pa-0 ms-2",staticStyle:{"text-align":"left"},attrs:{"cols":"3"}},[_c('span',{staticClass:"font-13 indigo--text text--darken-1"},[_vm._v("$"+_vm._s(_vm.balance.scheduled_online))])])],1),_c('v-row',[_c('v-col',{staticClass:"pa-0",staticStyle:{"text-align":"right"},attrs:{"cols":"8"}},[_c('span',{staticClass:"font-13 grey--text text--darken-3 justify-center"},[_vm._v("Premium Share: ")])]),_c('v-col',{staticClass:"pa-0 ms-2",staticStyle:{"text-align":"left"},attrs:{"cols":"3"}},[_c('span',{staticClass:"font-13 indigo--text text--darken-1"},[_vm._v("$"+_vm._s(_vm.balance.scheduled_share))])])],1)],1)],1),_c('v-card',{staticClass:"white",attrs:{"elevation":"1"}},[_c('v-card-text',{staticClass:"mt-1 pb-3 text-center"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",staticStyle:{"text-align":"right"},attrs:{"cols":"8"}},[_c('span',{staticClass:"font-14 bold grey--text text--darken-3 justify-center"},[_vm._v("Scheduled Purchases: ")])]),_c('v-col',{staticClass:"pa-0 ms-2",staticStyle:{"text-align":"left"},attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"font-15 bold red--text text--darken-3",staticStyle:{"text-align":"left"}},on),[_vm._v(" $"+_vm._s(_vm.balance.on_hold_amount)+" ")])]}}],null,false,3655762587)},[_c('span',[_vm._v("Upcoming scheduled purchases (amount is on-hold)")])])],1)],1)],1)],1)],1)],1)],1)],1),_c('v-col',{staticClass:"py-2 ps-5",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-app-bar',{attrs:{"dark":"","color":"blue-grey darken-3"}},[_c('v-icon',{staticClass:"flip-icon"},[_vm._v("call_received")]),_c('v-spacer'),_c('v-toolbar-title',[_vm._v("Month Period "+_vm._s(_vm.balance.month_balance.month)+"-"+_vm._s(_vm.balance.month_balance.year)+" ")]),_c('v-spacer'),_c('v-menu',{attrs:{"close-on-content-click":true,"nudge-left":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,1510716892)},[_c('v-list',[_c('v-list-item',[_c('v-date-picker',{attrs:{"type":"month","light":""},on:{"change":_vm.getMonthBalance},model:{value:(_vm.balance_month),callback:function ($$v) {_vm.balance_month=$$v},expression:"balance_month"}})],1)],1)],1)],1)],1),_c('v-container',{staticClass:"grey lighten"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"pa-2",attrs:{"cols":"6"}},[_c('v-col',{staticClass:"pa-2",attrs:{"cols":"12"}},[_c('v-card',{attrs:{"min-width":"300","color":"grey darken-3","elevation":"2"}},[_c('v-card-title',{staticClass:"font-16 font-weight-black justify-center"},[_vm._v("Month Balance")]),_c('v-card-text',{staticClass:"mt-1 pb-10 text-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({class:'font-20 bold ' + (_vm.selected_month_balance < 0 ? 'red--text' : 'blue--text text--darken-2')},on),[_vm._v(" $"+_vm._s(_vm.selected_month_balance))])]}}],null,false,1729045478)},[(_vm.balance.total_balance < 0)?_c('span',[_vm._v("Your Account Balance is Negative. Please settle your due amount")]):_c('span',[_vm._v("Your Account Balance. You can use this balance to shcedule your campaings")])])],1)],1)],1),_c('v-col',{staticClass:"pa-2 mt-0",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"white",attrs:{"elevation":"1"}},[_c('v-card-text',{staticClass:"mt-1 pb-3 text-center"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",staticStyle:{"text-align":"right"},attrs:{"cols":"8"}},[_c('span',{staticClass:"font-14 bold grey--text text--darken-3 justify-center"},[_vm._v("Slots Sales Revenue: ")])]),_c('v-col',{staticClass:"pa-0 ms-2",staticStyle:{"text-align":"left"},attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"font-15 bold indigo--text text--darken-1",staticStyle:{"text-align":"left"}},on),[_vm._v(" $"+_vm._s(_vm.balance.month_balance.cleared_amount)+" ")])]}}],null,false,1862501862)},[_c('span',[_vm._v("Total Confirmed Sales Revenue Share")])])],1)],1),_c('v-row',[_c('v-col',{staticClass:"pa-0",staticStyle:{"text-align":"right"},attrs:{"cols":"8"}},[_c('span',{staticClass:"font-13 grey--text text--darken-3 justify-center"},[_vm._v("Online Campaigns: ")])]),_c('v-col',{staticClass:"pa-0 ms-2",staticStyle:{"text-align":"left"},attrs:{"cols":"3"}},[_c('span',{staticClass:"font-13 indigo--text text--darken-1"},[_vm._v("$"+_vm._s(_vm.balance.month_balance.cleared_online))])])],1),_c('v-row',[_c('v-col',{staticClass:"pa-0",staticStyle:{"text-align":"right"},attrs:{"cols":"8"}},[_c('span',{staticClass:"font-13 grey--text text--darken-3 justify-center"},[_vm._v("Premium Share: ")])]),_c('v-col',{staticClass:"pa-0 ms-2",staticStyle:{"text-align":"left"},attrs:{"cols":"3"}},[_c('span',{staticClass:"font-13 indigo--text text--darken-1"},[_vm._v("$"+_vm._s(_vm.balance.month_balance.cleared_share))])])],1)],1)],1),_c('v-card',{staticClass:"white",attrs:{"elevation":"1"}},[_c('v-card-text',{staticClass:"mt-1 pb-3 text-center"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",staticStyle:{"text-align":"right"},attrs:{"cols":"8"}},[_c('span',{staticClass:"font-14 bold grey--text text--darken-3 justify-center"},[_vm._v("Subscriptions Revenue: ")])]),_c('v-col',{staticClass:"pa-0 ms-2",staticStyle:{"text-align":"left"},attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"font-15 bold indigo--text text--darken-1",staticStyle:{"text-align":"left"}},on),[_vm._v(" $"+_vm._s(_vm.balance.month_balance.in_invoice_payments)+" ")])]}}],null,false,4248107218)},[_c('span',[_vm._v("Total Subscription Revenue Share")])])],1)],1)],1)],1)],1)],1),_c('v-col',{staticClass:"pa-2",attrs:{"cols":"6"}},[_c('v-card',{staticClass:"white mt-1",attrs:{"flat":"","elevation":"1"}},[_c('v-card-text',{staticClass:"mt-0 pb-3 text-center"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",staticStyle:{"text-align":"right"},attrs:{"cols":"8"}},[_c('span',{staticClass:"font-14 bold grey--text text--darken-3 justify-center"},[_vm._v("Collected Payments: ")])]),_c('v-col',{staticClass:"pa-0 ms-2",staticStyle:{"text-align":"left"},attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"font-15 bold red--text text--darken-3",staticStyle:{"text-align":"left"}},on),[_vm._v(" $"+_vm._s(_vm.balance.month_balance.in_collect_payments)+" ")])]}}],null,false,108429486)},[_c('span',[_vm._v("Amounts Paid For Your Account in Balance")])])],1)],1)],1)],1),_c('v-card',{staticClass:"white mt-1",attrs:{"flat":"","elevation":"1"}},[_c('v-card-text',{staticClass:"mt-0 pb-3 text-center"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",staticStyle:{"text-align":"right"},attrs:{"cols":"8"}},[_c('span',{staticClass:"font-14 bold grey--text text--darken-3 justify-center"},[_vm._v("Granted Gifts/Vouchers: ")])]),_c('v-col',{staticClass:"pa-0 ms-2",staticStyle:{"text-align":"left"},attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"font-15 bold red--text text--darken-3",staticStyle:{"text-align":"left"}},on),[_vm._v(" $"+_vm._s(_vm.balance.month_balance.out_gift_payments)+" ")])]}}],null,false,4242773317)},[_c('span',[_vm._v("Amounts Paid For Your Account in Balance")])])],1)],1)],1)],1),_c('v-card',{staticClass:"white mt-1",attrs:{"flat":"","elevation":"1"}},[_c('v-card-text',{staticClass:"mt-0 pb-3 text-center"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",staticStyle:{"text-align":"right"},attrs:{"cols":"8"}},[_c('span',{staticClass:"font-14 bold grey--text text--darken-3 justify-center"},[_vm._v("Confirmed Purchases: ")])]),_c('v-col',{staticClass:"pa-0 ms-2",staticStyle:{"text-align":"left"},attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"font-15 bold red--text text--darken-3",staticStyle:{"text-align":"left"}},on),[_vm._v(" $"+_vm._s(_vm.balance.month_balance.executed_slots_amount)+" ")])]}}],null,false,3095584126)},[_c('span',[_vm._v("Total confirmed purchases")])])],1)],1)],1)],1),_c('v-card',{staticClass:"white mt-1",attrs:{"flat":"","elevation":"1"}},[_c('v-card-text',{staticClass:"mt-0 pb-3 text-center"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",staticStyle:{"text-align":"right"},attrs:{"cols":"8"}},[_c('span',{staticClass:"font-14 bold grey--text text--darken-3 justify-center"},[_vm._v("Paid Invoices: ")])]),_c('v-col',{staticClass:"pa-0 ms-2",staticStyle:{"text-align":"left"},attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"font-15 bold red--text text--darken-3",staticStyle:{"text-align":"left"}},on),[_vm._v(" $"+_vm._s(_vm.balance.month_balance.paid_out_invoices)+" ")])]}}],null,false,2480527438)},[_c('span',[_vm._v("Amounts Paid Out of Your Account")])])],1)],1)],1)],1),_c('v-card',{staticClass:"white mt-1",attrs:{"flat":"","elevation":"1"}},[_c('v-card-text',{staticClass:"mt-0 pb-3 text-center"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",staticStyle:{"text-align":"right"},attrs:{"cols":"8"}},[_c('span',{staticClass:"font-14 bold grey--text text--darken-3 justify-center"},[_vm._v("Paid Out Amounts: ")])]),_c('v-col',{staticClass:"pa-0 ms-2",staticStyle:{"text-align":"left"},attrs:{"cols":"3"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"font-15 bold red--text text--darken-3",staticStyle:{"text-align":"left"}},on),[_vm._v(" $"+_vm._s(_vm.balance.month_balance.paid_out_remits)+" ")])]}}],null,false,2329149656)},[_c('span',[_vm._v("Amounts Paid Out of Your Account")])])],1)],1)],1)],1)],1)],1)],1)],1),_c('v-col',{staticClass:"py-2 ps-5 pl-12",attrs:{"cols":"12"}},[_c('v-card',[_c('v-app-bar',{attrs:{"dark":""}},[_c('v-spacer'),_c('v-toolbar-title',{staticClass:"font-20  justify-center"},[_vm._v("Balance In")]),_c('v-spacer')],1)],1),_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"pa-3",attrs:{"cols":"6"}},[_c('v-card',{attrs:{"elevation":"1"}},[_c('v-card-title',{staticClass:"font-18 font-weight-black  justify-center"},[_vm._v("Confirmed Revenue")]),_c('v-card-text',{staticClass:"mt-3 pb-10 text-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"font-20 bold indigo--text text--darken-1"},on),[_vm._v(" $"+_vm._s(_vm.balance.cleared_amount)+" ")])]}}],null,false,2277117508)},[_c('span',[_vm._v("Total Confirmed Sales Revenue")])])],1),_c('hr',{attrs:{"color":" gray "}}),_c('v-card',{staticClass:" pa-3 font-12  bold "},[_c('v-row',[_c('v-col',[_vm._v(" Online ")]),_c('v-col',[_vm._v(" $"+_vm._s(_vm.balance.cleared_online)+" ")])],1),_c('v-row',[_c('v-col',[_vm._v(" premium ")]),_c('v-col',[_vm._v(" $"+_vm._s(_vm.balance.cleared_share)+" ")])],1)],1)],1)],1),_c('v-col',{staticClass:"pa-3",attrs:{"cols":"6"}},[_c('v-card',{attrs:{"elevation":"1"}},[_c('v-card-title',{staticClass:"font-18 font-weight-black  justify-center"},[_vm._v("Scheduled Revenue")]),_c('v-card-text',{staticClass:"mt-3 pb-10 text-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"font-20 bold indigo--text text--darken-1"},on),[_vm._v(" $"+_vm._s(_vm.balance.scheduled_amount)+" ")])]}}],null,false,4027731773)},[_c('span',[_vm._v("Upcoming sales revenues (not confirmed yet)")])])],1),_c('hr',{attrs:{"color":" gray "}}),_c('v-card',{staticClass:" pa-3 font-12  bold "},[_c('v-row',[_c('v-col',[_vm._v(" Online ")]),_c('v-col',[_vm._v(" $"+_vm._s(_vm.balance.scheduled_online)+" ")])],1),_c('v-row',[_c('v-col',[_vm._v(" premium ")]),_c('v-col',[_vm._v(" $"+_vm._s(_vm.balance.scheduled_share)+" ")])],1)],1)],1)],1)],1)],1)],1),_c('v-col',{staticClass:"py-2 ps-5",attrs:{"cols":"12"}},[_c('v-card',[_c('v-app-bar',{attrs:{"dark":""}},[_c('v-spacer'),_c('v-toolbar-title',{staticClass:"ms-2"},[_vm._v("Balance Out")]),_c('v-spacer')],1)],1),_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"pa-3",attrs:{"cols":"6"}},[_c('v-card',{attrs:{"flat":"","elevation":"1"}},[_c('v-card-title',{staticClass:"font-18 font-weight-black  justify-center"},[_vm._v("Confirmed Purchases")]),_c('v-card-text',{staticClass:"mt-3 pb-10 text-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"font-20 bold red--text text--darken-3"},on),[_vm._v(" $"+_vm._s(_vm.balance.executed_slots_amount)+" ")])]}}],null,false,1122330972)},[_c('span',[_vm._v("Total confirmed purchases")])])],1),_c('hr',{attrs:{"color":" gray "}}),_c('v-card',{staticClass:" pa-3 font-12  bold "},[_c('v-row',[_c('v-col',[_vm._v(" Online ")]),_c('v-col',[_vm._v(" $"+_vm._s(_vm.balance.executed_slots_online)+" ")])],1),_c('v-row',[_c('v-col',[_vm._v(" premium ")]),_c('v-col',[_vm._v(" $"+_vm._s(_vm.balance.executed_slots_share)+" ")])],1)],1)],1)],1),_c('v-col',{staticClass:"pa-3",attrs:{"cols":"6"}},[_c('v-card',{attrs:{"flat":"","elevation":"1"}},[_c('v-card-title',{staticClass:"font-18 font-weight-black  justify-center"},[_vm._v("Scheduled Purchases")]),_c('v-card-text',{staticClass:"mt-3 pb-10 text-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"font-20 bold red--text text--darken-3"},on),[_vm._v(" $"+_vm._s(_vm.balance.on_hold_amount)+" ")])]}}],null,false,646321214)},[_c('span',[_vm._v("Upcoming scheduled purchases (amount is on-hold)")])])],1)],1),_c('hr',{attrs:{"color":" gray "}}),_c('v-card',{staticClass:" pa-3 font-12  bold "},[_c('v-row',[_c('v-col',[_vm._v(" On Hold Online ")]),_c('v-col',[_vm._v(" $"+_vm._s(_vm.balance.on_hold_online)+" ")])],1),_c('v-row',[_c('v-col',[_vm._v(" On Hold Share ")]),_c('v-col',[_vm._v(" $"+_vm._s(_vm.balance.on_hold_share)+" ")])],1)],1)],1)],1)],1)],1)],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }