<template>
  <v-container fluid>
    <loading-dialog v-model="isLoading"></loading-dialog>
    <process-partner-reg-dialog
      v-model="processRegDialog"
      :registration-request="selected_registration_request"
      @done="getRegistrationRequests()">
    </process-partner-reg-dialog>
    <v-row justify="center">
      <v-col cols="12" md="11" lg="10" xl="9">
        <v-toolbar dense>
          <v-toolbar-title class="font-16">
            Partner Registration Requests
          </v-toolbar-title>
        </v-toolbar>
        <v-row>
          <v-col cols="12" v-for="(request, key) in registration_requests" :key="key">
            <v-card color="less_dark_color">
              <v-card-title class="font-14">
                {{ request.location_name }}
              </v-card-title>
              <v-card-subtitle class="font-14">
                {{ String(new Date(request.request_dt)) }}
              </v-card-subtitle>
              <v-card-text class="primary_text--text">
                <div>
                  <span class="font-14">
                    Venue Type
                  </span>
                  <span class="font-13 bold ms-5">
                    {{ request.venue_name }}
                  </span>
                </div>
                <div>
                  <span class="font-14">
                    Location
                  </span>
                  <span class="font-13 bold ms-5">
                    {{ request.country_name }} - {{ request.state_name }}{{ request.city_name !== null ? (' - '  + request.city_name) : ''}}
                  </span>
                </div>
                <div>
                  <span class="font-14">
                    Name
                  </span>
                  <span class="font-13 bold ms-5">
                    {{ request.name }}
                  </span>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text small @click="processRegistrationRequest(request)">
                  Process Request
                </v-btn>
                <v-btn text small>
                  Visit Request
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data () {
      return {
        isLoading: false,
        registration_requests: [],
        processRegDialog: false,
        selected_registration_request: null
      }
    },
    methods: {
      processRegistrationRequest (request) {
        this.selected_registration_request = Object.assign({}, request)
        this.processRegDialog = true
      },
      getRegistrationRequests () {
        this.isLoading = true
        this.$http.get(this.$store.getters.pubsServer + '/api/publishers/partners/registrations/requests/', { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.registration_requests = response.body
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
        })
      }
    },
    created () {
      this.getRegistrationRequests()
    }
  }
</script>