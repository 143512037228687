import Vue from 'vue'
import LoadingDialog from '@/components/loading-dialog'
import ErrorSnackbar from '@/components/ErrorSnackbar'
import SuccessSnackbar from '@/components/SuccessSnackbar'
import MediaReviewItem from '@/components/media-review-item'
import ReviewMediaDialog from '@/components/review-media-dialog'
import LayoutDrawer from '@/components/layout/layout-drawer'
import YesNoDialog from '@/components/YesNoDialog'
import EditLocationDialog from '@/components/edit-location-dialog'
import EditSiteDialog from '@/components/edit-site-dialog'
import ProfileLocationDialog from '@/components/profile-location-dialog'
import UploadButton from '@/components/u-btn'
import UploadLocationButton from '@/components/u-location-btn'
import ProcessPartnerRegDialog from '@/components/ProcessPartnerRegDialog'
import EditVisitorsDialog from '@/components/edit-visitors-dialog'
import NewVisitortType from '@/components/new-visitor-type'
import EditKeywordsDialog from '@/components/edit-keywords-dialog'
import locationsDetailsDialog from '@/components/locations-details-dialog'
import MediaViewer from '@/components/media-viewer.vue'
import SopnserProfile from "@/components/sponser-profile.vue"
import PremiumMedia from "@/components/premium-media.vue"
import AddPremiumUsers from "@/components/add-premium-users.vue"
import GiftsPaymentsDialog from "@/components/gifts-payments-dialog.vue"
import CollectPaymentsDialog from '@/components/collect-payments-dialog.vue'

import balanceDetailsDialog from '@/components/balance-details-dialog'
import MediaInfoDialog from '@/components/media-info-dialog'
import OnTheFlySchedule from '@/components/OnTheFlySchedule'
import SchedulesTable from '@/components/schedules-table'
import CampaignView from '@/components/CampaignView'
import CampaignsDashboardLoading from '@/components/campaigns-dashboard-loading'
import CampaignWizard from '@/components/campaign-wizard'
import ComSchedulesDialog from '@/components/schedules-plist-dialog.vue'
import MediaDetails from '@/components/media-details'
import AddNewPremium from '@/components/add-new-premium.vue'
import CampaignsDashboardSponser from "@/components/campaigns-dashboard-sponser.vue"
import CapmaignViewSponser from "@/components/campaign-view-sponser.vue"

import MediaItem from '@/components/media-item.vue'
import CreatorForeground from '@/components/creator-foreground.vue'
import PublisherPlaylist from '@/components/pub-playlists.vue'
import MediaDialog from '@/components/media-dialog.vue'


Vue.component('loading-dialog', LoadingDialog)
Vue.component('error-snackbar', ErrorSnackbar)
Vue.component('success-snackbar', SuccessSnackbar)

Vue.component('media-review-item', MediaReviewItem)
Vue.component('review-media-dialog', ReviewMediaDialog)

Vue.component('layout-drawer', LayoutDrawer)
Vue.component('yes-no-dialog', YesNoDialog)
Vue.component('edit-location-dialog', EditLocationDialog)
Vue.component('edit-site-dialog', EditSiteDialog)
Vue.component('profile-location-dialog', ProfileLocationDialog)
Vue.component('edit-visitors-dialog', EditVisitorsDialog)
Vue.component('new-visitort-type',NewVisitortType)
Vue.component('edit-keywords-dialog',EditKeywordsDialog)
Vue.component('add-new-premium',AddNewPremium)
Vue.component("premium-media",PremiumMedia)
Vue.component('add-premium-users',AddPremiumUsers)
Vue.component('gifts-payments-dialog',GiftsPaymentsDialog)
Vue.component('collect-payments-dialog',CollectPaymentsDialog)
Vue.component('capmaign-view-sponser',CapmaignViewSponser)

Vue.component('media-item', MediaItem)
Vue.component('creator-foreground', CreatorForeground)
Vue.component('pub-playlists', PublisherPlaylist)
Vue.component('media-dialog', MediaDialog)

Vue.component('locations-details-dialog',locationsDetailsDialog)
Vue.component('balance-details-dialog',balanceDetailsDialog)
Vue.component('media-info-dialog',MediaInfoDialog)
Vue.component('on-the-fly-schedule',OnTheFlySchedule)
Vue.component('schedules-table',SchedulesTable)
Vue.component('campaign-view',CampaignView)
Vue.component('campaigns-dashboard-loading',CampaignsDashboardLoading)
Vue.component('campaign-wizard',CampaignWizard)
Vue.component('schedules-plist-dialog', ComSchedulesDialog)
Vue.component('media-details',MediaDetails)
Vue.component('media-viewer', MediaViewer)
Vue.component("sopnser-profile",SopnserProfile)

Vue.component("campaigns-dashboard-sponser",CampaignsDashboardSponser)

Vue.component('u-btn', UploadButton)
Vue.component('u-location-btn', UploadLocationButton)
Vue.component('process-partner-reg-dialog', ProcessPartnerRegDialog)