<template>
  <v-content>
 <loading-dialog v-model="isLoading"></loading-dialog>
    <v-dialog v-model="isOpen"  
     persistent max-width="1000px"  
         >
         
      <v-card >
        <v-card-text  >
               <v-col >
            <v-row>      
           <v-card-title class="pa-0 blue--text text--darken--1">
            Location Name: {{LocationName}}
           </v-card-title>
            </v-row>
        
        <v-row >
         <v-col  class="pa-0"  cols="12" sm="6"  md="3">
            <v-select
            class="blue--text text--lighten-3"
           :items="genderClass"
           label="Gender-Class"
           :item-value="genderClass"
           v-model="classOfGender"
            ></v-select>
          </v-col>
        </v-row>
           <v-row  >
          <v-card  >
            <v-row>
    <v-simple-table v-if="classOfGender==='MALE'||classOfGender==='ANY'" >
        <tr>
          <th class="text-left">
           Age 
          </th>
          <th class="text-left">
           Gender class
          </th>
           <th class="text-left">
            Percentage
          </th>
           <th  class="text-left" >
            Final Percentage
          </th> 
        </tr>
        <tr>
          <td class="blue--text text--lighten-3   ">Age 13-17 (Men)</td>
          <td class="blue--text text--lighten-3">MALE</td>
        
          <td style="width: 60px; " >    
          <v-text-field
            class=" ma-0 pr-0 blue--text text--lighten-3"
            style="width: 60px; "
              v-model="VisitorPercentage.age_13_men"
               suffix="%"
                type="Number">
              </v-text-field>
           </td>
       
          <td class="blue--text text--lighten-3 pl-8   ">{{ VisitorPercentage.norm_age_13_men }} %</td>
        </tr >
           <tr  style="height:70px"  >
              
          <td class="blue--text text--lighten-3  ">Age 18-24 (Men)</td>
          <td class="blue--text text--lighten-3">MALE</td>
          <!-- <td>    
          
             <v-text-field
             class="blue--text text--lighten-3" -->
              <td style="width: 60px; " >    
          <v-text-field
            class=" ma-0 pr-0 blue--text text--lighten-3"
            style="width: 60px; "
              v-model="VisitorPercentage.age_18_men"
               suffix="%"
                type="Number">
              </v-text-field>
           
           </td>
          <td class="blue--text text--lighten-3  pl-8 ">{{VisitorPercentage.norm_age_18_men }} %</td>
        </tr>
            <tr  style="height:70px" >
          <td class="blue--text text--lighten-3  ">Age 25-44 (Men)</td>
          <td class="blue--text text--lighten-3">MALE</td>
             
            <td style="width: 60px; " >    
          <v-text-field
            class=" ma-0 pr-0 blue--text text--lighten-3"
            style="width: 60px; "
              v-model="VisitorPercentage.age_25_men"
               suffix="%"
                type="Number">
              </v-text-field>
             
           </td>
          <td class="blue--text text--lighten-3  pl-8 ">{{VisitorPercentage.norm_age_25_men }} %</td>
        </tr>      
         <tr   style="height:70px">
          <td class="blue--text text--lighten-3   ">Age 45-64 (Men)</td>
          <td class="blue--text text--lighten-3">MALE</td>
            <td style="width: 60px; " >    
          <v-text-field
            class=" ma-0 pr-0 blue--text text--lighten-3"
            style="width: 60px; "
              v-model="VisitorPercentage.age_45_men"
               suffix="%"
                type="Number">
              </v-text-field>
             
           </td>
          <td class="blue--text text--lighten-3  pl-8 ">{{VisitorPercentage.norm_age_45_men }} %</td>
        </tr>      
         <tr  style="height:70px" >
          <td class="blue--text text--lighten-3   ">Age 65-80 (Men)</td>
          <td class="blue--text text--lighten-3">MALE</td>
            <td style="width: 60px; " >    
          <v-text-field
            class=" ma-0 pr-0 blue--text text--lighten-3"
            style="width: 60px; "
              v-model="VisitorPercentage.age_65_men"
               suffix="%"
                type="Number">
              </v-text-field>
            
           </td>
          <td class="blue--text text--lighten-3  pl-8 ">{{ VisitorPercentage.norm_age_65_men }} %</td>
        </tr>      
        
          
   </v-simple-table>
   <hr v-if="classOfGender!=='FEMALE'">
    <v-simple-table dark v-if="classOfGender==='FEMALE'||classOfGender==='ANY'" >
    <tr  dark v-if="classOfGender==='FEMALE'" >
          <th class="text-left">
            Age 
          </th>
          <th class="text-left">
           Gender class
          </th>
           <th class="text-left">
            Percentage
          </th>
           <th  class="text-left" >
            Final Percentage
          </th> 
        </tr>
     
       <tr v-if="classOfGender==='ANY'"><td></td> </tr>
       
       <tr style="height:70px"  >
          <td class="purple--text text--lighten-3 ">Age 13-17 (Women)</td>
          <td class="purple--text text--lighten-3">WOMEN</td>
       <td style="width: 60px; " >    
          <v-text-field
            class=" ma-0 pr-0 purple--text text--lighten-3"
            style="width: 60px;"        
            v-model="VisitorPercentage.age_13_women"
               suffix="%"
                type="Number">
              </v-text-field>
           
           </td>
          <td class="purple--text text--lighten-3  pl-6 ">{{VisitorPercentage.norm_age_13_women }} %</td>
        </tr>
              <tr style="height:70px"  >
          <td class="purple--text text--lighten-3   ">Age 18-24 (Women)</td>
          <td class="purple--text text--lighten-3">WOMEN</td>
         <td style="width: 60px; " >    
          <v-text-field
            class=" ma-0 pr-0 purple--text text--lighten-3"
            style="width: 60px;"    
               v-model="VisitorPercentage.age_18_women"
               suffix="%"
                type="Number">
              </v-text-field>
             
           </td>
          <td  class="purple--text text--lighten-3  pl-6 ">{{VisitorPercentage.norm_age_18_women }} %</td>
        </tr> 
            <tr style="height:70px"  >
          <td class="purple--text text--lighten-3  ">Age 25-44 (Women)</td>
          <td class="purple--text text--lighten-3">WOMEN</td>
            <td style="width: 60px; " >    
          <v-text-field
            class=" ma-0 pr-0 purple--text text--lighten-3"
            style="width: 60px;"
              v-model="VisitorPercentage.age_25_women"
               suffix="%"
                type="Number">
              </v-text-field>
             
           </td>
          <td class="purple--text text--lighten-3  pl-6 ">{{ VisitorPercentage.norm_age_25_women }} %</td>
        </tr>      
         <tr >
          <td class="purple--text text--lighten-3  ">Age 45-64 (Women)</td>
          <td class="purple--text text--lighten-3">WOMEN</td>
             
              <td style="width: 60px; " >    
          <v-text-field
            class=" ma-0 pr-0 purple--text text--lighten-3"
            style="width: 60px;"
              v-model="VisitorPercentage.age_45_women"
              suffix="%"
                type="Number">
              </v-text-field>
            
           </td>
          <td class="purple--text text--lighten-3  pl-6 ">{{VisitorPercentage.norm_age_45_women }} %</td>
        </tr>      
         <tr style="height:70px"   >
          <td class="purple--text text--lighten-3  ">Age 65-80 (Women)</td>
          <td class="purple--text text--lighten-3">WOMEN</td>
            <td style="width: 60px; " >    
          <v-text-field
            class=" ma-0 pr-0 purple--text text--lighten-3"
            style="width: 60px;"
              v-model="VisitorPercentage.age_65_women"
               suffix="%"
                type="Number">
              </v-text-field>
            
           </td>
          <td class="purple--text text--lighten-3  pl-6 ">{{VisitorPercentage.norm_age_65_women }} %</td>
        </tr>      
        
        
        
  
   </v-simple-table>
   <hr v-if="classOfGender==='FEMALE'">
   </v-row>
        </v-card>
        
 <v-simple-table>
   <v-col style="  padding-left: 300px;">
      <tr> 
      <td  class="pa-4 blue--text text--darken--1 " >   <h2>Unknown  :   {{VisitorPercentage.norm_ages_unknown}} % </h2>
    </td>
    
     </tr>
   </v-col>
   </v-simple-table>
          </v-row>
      <v-col>
     </v-col>  
    </v-col>
          <v-card-actions>
          <v-spacer></v-spacer>
               <v-btn  @click="submitEdit">
            Save
          </v-btn>
          <v-btn   @click="isOpen=false">
            close
          </v-btn>
     
        </v-card-actions>
        </v-card-text> 
      </v-card>
    </v-dialog>
  </v-content>
</template>

<script>
  export default {
    props: {
      value: Boolean,
      location_id: Number,
      ProfileVistor:Function,
      VisitorPercentage:Object,
      LocationName:String
  },
    
    computed: {
      isOpen: {
        get () {
            return this.value
        },
        set (value) {
  
          this.$emit('input', value)
        }
      },
    },
    watch:{
       classOfGender (value) {
        if (!value) {
          this.classOfGender = value
        }
           if (value === 'ANY') {
          this.VisitorPercentage.gender_class=1
        } else if (value === "MALE") {
          this.VisitorPercentage.gender_class=2
     
        } else if (value ==="FEMALE") {
          
          this.VisitorPercentage.gender_class=3
        }
        
      }

    },
  
    data () {
      return {
        newvisitortDialog:false,
        isLoading: false,
        vistiorEdit:null,
        vistiorEditDialog:false,
        locationid:null,
        classOfGender:this.getGenderClass(),
         
      genderClass: ['ANY', 'MALE','FEMALE'],
    
    }
    },
    methods:{
      submitEdit(){
           this.$http.put(this.$store.getters.pubsServer + '/api/hosts/locations/visitors/'+this.location_id+'/', this.VisitorPercentage, { headers: {
              Authorization: 'Token ' + this.$store.getters.token
            }
          }).then(response => {
            this.isLoading = false
            this.ProfileVistor(this.location_id,this.LocationName)
            
          }, error => {
            this.isLoading = false
            if (error.status === this.unauthorized) {
              
            }
            console.log(error)
          })

      },
          
    getGenderClass() {
      if(this.VisitorPercentage.gender_class===1){
       return "ANY"

      }else if(this.VisitorPercentage.gender_class===2){
        return "MALE"


      }else if(this.VisitorPercentage.gender_class===3){
        return "FEMALE"

      }
    }
    },
    }
</script>
