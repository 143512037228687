import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
    pubs_server: null,
    token: null
  },
  mutations: {
    setPubsServer (state, payload) {
      state.pubs_server = payload
    },
    onSignIn (state, payload) {
      state.token = payload.auth_token
    },
    onSignOut (state, payload) {
      state.token = null
    }
  },
  actions: {
    setPubsServer ({commit}, payload) {
      commit('setPubsServer', payload)
    },
    onSignIn ({commit}, payload) {
      commit('onSignIn', payload)
    },
    onSignOut ({commit}, payload) {
      commit('onSignOut', payload)
    }
  },
  getters: {
    pubsServer (state) {
      return state.pubs_server
    },
    token (state) {
      return state.token
    }
  },
  plugins: [
    createPersistedState({
      key: 'oscreens_reviewers'
    })
  ]
})
