<template>
  <v-content>
    <loading-dialog v-model="isLoading"></loading-dialog>
    <v-dialog v-model="isOpen" max-width="500px" scrollable>
      <v-card>
        <v-card-title class="font-14">Edit Location</v-card-title>
        <v-card-text style="height: 500px;">
          <v-text-field
            class="mt-2"
            label="Name"
            v-model="location_edit_object.name"
            outlined
            dense
            type="text">
          </v-text-field>
          <v-textarea
            label="Marketing Message"
            v-model="location_edit_object.marketing_message"
            outlined
            hide-details
            type="text">
          </v-textarea>
          <v-checkbox
            v-model="location_edit_object.is_listed_location"
            label="Is Listed Location"
            dense>
          </v-checkbox>
          <v-autocomplete
            :items="countries"
            v-model="location_edit_object.country"
            label="Country"
            class="font-14"
            prepend-inner-icon="flag"
            item-text="name"
            item-value="country_id"
            disabled
            outlined
            dense
            required
          ></v-autocomplete>
          <v-autocomplete
            :items="states"
            v-model="location_edit_object.state"
            label="State"
            class="font-14"
            @input="getCities"
            prepend-inner-icon="location_city"
            item-text="name"
            item-value="state_id"
            outlined
            dense
          ></v-autocomplete>
          <v-autocomplete
            :items="cities"
            v-model="location_edit_object.city"
            label="City"
            class="font-14"
            prepend-inner-icon="location_city"
            item-text="name"
            item-value="city_id"
            outlined
            dense
          ></v-autocomplete>
          <v-text-field
            v-model="location_edit_object.address"
            label="Address"
            outlined
            dense
            type="text">
          </v-text-field>
          <v-text-field
            v-model="location_edit_object.latitude"
            label="Latitude"
            outlined
            dense
            type="number">
          </v-text-field>
          <v-text-field
            v-model="location_edit_object.longitude"
            label="Longitude"
            outlined
            dense
            type="number">
          </v-text-field>
          <v-select
            :items="venues"
            v-model="location_edit_object.venue"
            label="Venue Type"
            class="font-14"
            prepend-inner-icon="store"
            item-text="name"
            item-value="venue_id"
            outlined
            dense
          ></v-select>
          <v-select
            :items="enclosure_choices"
            v-model="location_edit_object.enclosure"
            label="Enclosure"
            class="font-14"
            outlined
            dense
          ></v-select>
          <v-select
            :items="size_choices"
            v-model="location_edit_object.size"
            label="Size"
            class="font-14"
            outlined
            dense
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small @click="isOpen = false">
            Cancel
          </v-btn>
          <v-btn text small @click="submitEditLocation">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-content>
</template>

<script>
  export default {
    props: {
      value: Boolean,
      location: Object
    },
    computed: {
      isOpen: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      }
    },
    watch: {
      location (value) {
        if (value === null) {
          this.location_edit_object = Object.assign({}, this.default_location_edit_object)
          return
        }
        this.location_edit_object = Object.assign({}, {
          name: value.name,
          marketing_message: value.marketing_message,
          is_listed_location: value.is_listed_location,
          country: value.country,
          state: value.state,
          city: value.city,
          address: value.address,
          latitude: value.latitude,
          longitude: value.longitude,
          venue: value.venue,
          enclosure: value.enclosure,
          size: value.size
        })
        this.getStates()
        this.getCities()
      },
      isOpen (value) {
        if (!value) {
          this.location_edit_object = Object.assign({}, this.default_location_edit_object)
        }
      }
    },
    data () {
      return {
        isLoading : false,
        countries: [],
        states: [],
        cities: [],
        venues: [],
        enclosure_choices: [
          {text: 'Indoor', value: 1},
          {text: 'Outdoor', value: 2},
          {text: 'Both Indoor and Outdoor', value: 3}
        ],
        size_choices: [
          {text: 'Tiny', value: 1},
          {text: 'Small', value: 2},
          {text: 'Medium', value: 3},
          {text: 'Large', value: 4},
          {text: 'Open Area', value: 5}
        ],
        location_edit_object: {
          name: null,
          marketing_message: null,
          is_listed_location: false,
          country: null,
          state: null,
          city: null,
          address: null,
          latitude: null,
          longitude: null,
          venue: null,
          enclosure: null,
          size: null
        },
        default_location_edit_object: {
          name: null,
          marketing_message: null,
          is_listed_location: false,
          country: null,
          state: null,
          city: null,
          address: null,
          latitude: null,
          longitude: null,
          venue: null,
          enclosure: null,
          size: null
        }
      }
    },
    methods: {
      submitEditLocation () {
        this.location_edit_object.cluster = this.location.location_id
        this.isLoading = true
        this.$http.put(this.$store.getters.pubsServer + '/api/hosts/locations/' + this.location.location_id + '/', this.location_edit_object, { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.isOpen = false
          this.$emit('saved')
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
          console.log(error)
        })
      },
      getCountries () {
        this.isLoading = true
        this.$http.get(this.$store.getters.pubsServer + '/api/common/all_countries/', { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.countries = response.body
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
        })
      },
      getStates () {
        this.isLoading = true
        this.$http.get(this.$store.getters.pubsServer + '/api/common/all_countries/' + this.location_edit_object.country + '/', { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.states = response.body.states
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
        })
      },
      getCities () {
        this.isLoading = true
        this.$http.get(this.$store.getters.pubsServer + '/api/common/states/' + this.location_edit_object.state + '/', { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.cities = response.body.cities
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
        })
      },
      getVenues () {
        this.isLoading = true
        this.$http.get(this.$store.getters.pubsServer + '/api/hosts_public/location_venues/', { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.venues = response.body
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
        })
      }
    },
    created () {
      this.getCountries()
      this.getVenues()
    }
  }
</script>