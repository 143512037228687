<template>
  <v-content>
    <error-snackbar v-model="errorSnackbar" :errorMessage="errorMessage"></error-snackbar>
    <loading-dialog v-model="isLoading"></loading-dialog>
    <media-info-dialog v-if="selectedCampaign !== null && selectedCampaign.media !== null"
      v-model="mediaDialog" :selectedMedia="selectedCampaign.media">
    </media-info-dialog>
 
    <div v-if="selectedCampaign" class="px-0">      
      <on-the-fly-schedule v-if="editedHostPos !== -1" scope="Partners"
        v-model="createScheduleDialog" :campaignHostId="selectedCampaign.campaign_hosts[editedHostPos].campaign_host_id"
        :reloadCampaign="reloadCampaign"></on-the-fly-schedule>
        <v-card class="px-0" >
         <v-row  >
          <v-col cols="8"  >
            <v-toolbar dark flat color="secondary darken-1"  >
   <router-link style=" color:white;"  v-if="scope === 'Partners'" :to="{ path: '/partners/CampaignsDashboard'+'/' , query: { id: partner_id }}" append>    
    <v-icon style=" color:white;">arrow_back</v-icon>
    </router-link>
    <v-btn icon v-if="scope ==='sponsors'"  @click="onBackPressed">
  <v-icon style=" color:white;">arrow_back</v-icon>
    </v-btn>
        
      
        <v-spacer></v-spacer>
        <v-toolbar-title class="white--text text--darken-2 page-title mx-0 px-0"  style="font-size: 16px !important; "><b>{{ selectedCampaign.name }}</b></v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider class="pb-5" ></v-divider>
            <v-card flat class="px-2">
         <v-col class="my-2" cols="12" style="cursor: pointer;" justify="start" 
                @click="openMedia">
                <v-img v-if="selectedCampaign.media !== null" contain :src="getPrimaryImage(selectedCampaign.media.variants).thumbnail_m" height="160"></v-img>
                <v-img v-else contain src="@/assets/no_image.jpg" height="160"></v-img>
              </v-col>
              <table class="tableSpacing body-2">
                <tr>
                  <td align="right"><div class="card-title-text">Media Approval Status : </div></td>
                  <td><span class="card-second-text">{{ getCampaignsMediaAuthorizationStatus(selectedCampaign.media_authorization_status) }}</span></td>
                </tr>
                <tr>
                  <td align="right"><div class="card-title-text">Has HHD : </div></td>
                  <td><v-icon small class="card-second-text" v-if="selectedCampaign.media.has_hhd">done</v-icon>
                  <v-icon small class="card-second-text" v-else>clear</v-icon></td>
                </tr>
                <tr v-if="selectedCampaign.media.has_hhd">
                  <td align="right"><div class="card-title-text">HHD Approved : </div></td>
                  <td><v-icon small class="card-second-text" v-if="selectedCampaign.hhd_approved">done</v-icon>
                  <v-icon small class="card-second-text" v-else>clear</v-icon></td>
                </tr>
                <tr>
                  <td align="right"><div class="card-title-text">Has VHD : </div></td>
                  <td><v-icon small class="card-second-text" v-if="selectedCampaign.media.has_vhd">done</v-icon>
                  <v-icon small class="card-second-text" v-else>clear</v-icon></td>
                </tr>
                <tr v-if="selectedCampaign.media.has_vhd">
                  <td align="right"><div class="card-title-text">VHD Approved : </div></td>
                  <td><v-icon small class="card-second-text" v-if="selectedCampaign.media.vhd_approved">done</v-icon>
                  <v-icon small class="card-second-text" v-else>clear</v-icon></td>
                </tr>
              </table>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card>
              <v-card-text class="pb-0 pl-0">
                <span class="card-sub-title blue-grey--text text--darken-3">Locations : </span>
                <span>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn fab dark x-small v-on="on" color="secondary" class="mx-auto ms-5" @click="showLocationsClick">
                        <v-icon>visibility</v-icon>
                      </v-btn>
                    </template>
                    <span>View Campaign Locations</span>
                  </v-tooltip>
               </span>
              </v-card-text>
            <table  class="tableSpacing body-2 mt-2  pl-12">
                <tr>
                  <td ><div class="card-title-text pl-5">Total Locations:</div></td>
                  <td><span class="card-second-text">{{ selectedCampaign.total_hosts }}</span></td>
                </tr>
              </table>
            </v-card>
             <v-card flat class="px-0 py-2">
              <v-card-text class="pb-1">
                <span class="card-sub-title blue-grey--text text--darken-3">
                  Schedules:
                </span>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn fab dark x-small v-on="on" color="secondary" class="mx-auto ms-3" @click="showSchedulesClick">
                        <v-icon>visibility</v-icon>
                      </v-btn>
                    </template>
                    <span>View Campaign Schedules</span>
                  </v-tooltip>
               </v-card-text>
                 <table class="tableSpacing body-2 mt-2 pl-12">
                <tr>
                  <td ><div class="card-title-text pl-7">Active Schedules:</div></td>
                  <td><span class="card-second-text">{{ selectedCampaign.active_schedules }}</span></td>
                </tr>
              </table>
            </v-card>
             <v-card flat class="px-0 py-2 pt-0">
              <v-card-text class="pb-0 ">
                <p class="card-sub-title blue-grey--text text--darken-3">
                  Account Status
                </p>
              </v-card-text>
              <table class="tableSpacing body-2 pl-12 pt-0">
                <tr>
                  <td ><div class="card-title-text pl-4 ">Executed Amount:</div></td>
                  <td><span class="card-second-text ">${{ selectedCampaign.executed_amount }}</span></td>
                </tr>
                <tr>
                  <td ><div class="card-title-text pl-4">Scheduled Amount:</div></td>
                  <td><span class="card-second-text">${{ selectedCampaign.scheduled_amount }}</span></td>
                </tr>
              </table>
            </v-card>
           </v-col>
        </v-row>
         <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="dialogue-btn mx-5" :color="showLocations ? 'blue darken-1' : 'white'" text @click.native="showLocationsClick">Locations</v-btn>
          <v-btn class="dialogue-btn mx-5" :color="showSchedules ? 'blue darken-1' : 'white'" text @click.native="showSchedulesClick">Schedules</v-btn>
          
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </div>
      <v-row class="mt-1 px-0 mx-0  ">
        <v-card flat class="">
          <v-card-actions v-show="showSchedules" v-if="schedules">
              <v-col class="px-0 mx-0 " >
                 <v-col class="px-0 mx-0 pt-0">
                <schedules-table :headers="headers"
                :schedules="schedules.active" withLocation
                :withControls="false"
                withHeader headerTitle="Active Schedules"
                emptyText="No active schedules"></schedules-table>
                <schedules-table :headers="headers"
                :schedules="schedules.history" withLocation
                :withControls="false"
                withHeader headerTitle="History"
                emptyText="No previous schedules"></schedules-table>
              </v-col>
              </v-col>
          </v-card-actions>
        </v-card>
      </v-row>
      <v-col cols="12" class="px-0 mx-0">
        <v-card flat >
          <div v-show="showLocations" v-if="selectedCampaign">
              <v-col cols="12" class="px-0 mx-0" v-for="(host, i) in selectedCampaign.campaign_hosts" :key="i">
                <v-card>
                    <v-layout class="blue-grey darken-2 white--text" style="height: 40px;" align-center>
                      <v-card-title>
                        <div class="singleline btn-apps">{{ host.location_name }} - {{ host.site_name }}</div>
                      </v-card-title>
                      <v-spacer></v-spacer>
                      <v-card-actions >
                        <v-spacer></v-spacer>
                       
                      </v-card-actions>
                    </v-layout>
                    <v-row justify="center" align="center" class="blue-grey darken-3 white--text mx-0 px-0">
                      <v-col cols="12" md="6">
                        <v-card-text class="body-2  ">
                          <table class="tableSpacing" >
                            <tr>
                              <td align="right"><div class="card-title-text">Site Status:</div></td>
                              <td><span class="card-second-text">{{ getCampaignHostStatus(host.status) }}</span></td>
                            </tr>
                            <tr>
                              <td align="right"><div class="card-title-text" title="Screen Orientation">Screen Orientation:</div></td>
                              <td><span class="card-second-text">{{ host.tilt !== null ?
                                (host.tilt === 1 ? 'Horizontal' : 'Vertical') : 'Unknown' }}</span></td>
                            </tr>
                            <tr>
                              <td align="right"><div class="card-title-text">Media Approval Status:</div></td>
                              <td><span class="card-second-text">{{ getMediaApprovalStatus(host.media_approval_status) }}</span></td>
                            </tr>
                            <tr v-if="host.media_approval_status === 3">
                              <td align="right"><div class="card-title-text">Media Issue:</div></td>
                              <td><span class="card-second-text">{{ getInvalidMediaIssue(host.invalid_media_issue) }}</span></td>
                            </tr>
                            <tr>
                              <td align="right"><div class="card-title-text">Number of Slots:</div></td>
                              <td><span class="card-second-text">{{ host.scheduled_time_units }}</span></td>
                            </tr>
                            <tr>
                              <td align="right"><div class="card-title-text">Executed Amount:</div></td>
                              <td><span class="card-second-text">${{ host.executed_amount }}</span></td>
                            </tr>
                            <tr>
                              <td align="right"><div class="card-title-text">Scheduled Amount:</div></td>
                              <td><span class="card-second-text">${{ host.scheduled_amount }}</span></td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-img v-if="host.thumbnails !== null" contain :src="host.thumbnails.thumbnail_m" height="160"></v-img>
                        <v-img v-else contain src="@/assets/no_image.jpg" height="160"></v-img>
                      </v-col>
                    </v-row>
                    <v-expansion-panels>
                      <v-expansion-panel class="blue-grey  teal--text text--darken-2">
                        <v-expansion-panel-header style="font-size: 14px; color:white;" ><b>Schedules</b></v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-row>
                            <v-col cols="12">
                              <schedules-table :headers="location_schedule_headers"
                                :schedules="host.active_schedules"
                                :host="host"
                                withControls
                                schedulesType="active"
                                :withLocation="false"
                                :cancelClicked="prepareScheduleDeletion"
                                withHeader headerTitle="Active Schedules"
                                emptyText="No active schedules for this location"></schedules-table>
                                <schedules-table :headers="location_schedule_headers"
                                :schedules="host.history_schedules"
                                :host="host"
                                withControls
                                schedulesType="history"
                                :withLocation="false"
                                :cancelClicked="prepareScheduleDeletion"
                                withHeader headerTitle="History"
                                emptyText="No history for this location"
                                ></schedules-table>
                            </v-col>
                          </v-row>
                       
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                </v-card>
              </v-col>
          </div>
        </v-card>
      </v-col>
  </v-content>
</template>
<script>
  export default {
    props: {
      value:Boolean,
      campaignId: Number,
      scope: String,
      partner_id:Number,
      onBackPressed: Function
    },
      computed: {
      isOpen: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      }
    },
    data () {
      return {
        showLocations: false,
        showSchedules: false,
        selectedhost: null,
        selectCampaignLocationDialog: false,
        selectedCampaignHost: null,
        isLoading: false,
        mediaDialog: false,
        editCampaignDialog: false,
        selectedCampaign: null,
        edited_campaign: null,
        createScheduleDialog: false,
        warningCampaignDeactivateDialog: false,
        scheduleQuote: null,
        scheduleDialog: false,
        editedHostPos: -1,
        editedSchedulePos: -1,
        editedScheduleType: null,
        warningScheduleDeletionDialog: false,
        addLocationsDialog: false,
        edited_schedule: {
          ensure_continuity: false,
          unavailable_date_action: 1,
          reserve_start_date: null,
          use_fixed_num_days: false,
          reserve_num_of_days: 0,
          use_max_end_date: false,
          reserve_end_date: null
        },
        default_schedule: {
          ensure_continuity: false,
          unavailable_date_action: 1,
          reserve_start_date: null,
          use_fixed_num_days: false,
          reserve_num_of_days: 0,
          use_max_end_date: false,
          reserve_end_date: null
        },
        headers: [
          {
            text: 'Status',
            align: 'left',
            sortable: false,
            value: 'code',
            class: 'tableTitle'
          },
          { text: 'Location', value: 'media', sortable: false, align: 'center', class: 'tableTitle' },
          { text: 'Site', value: 'runTime', sortable: false, align: 'center', class: 'tableTitle' },
          { text: 'Orientation', value: 'repeats', sortable: false, align: 'center', class: 'tableTitle' },
          { text: 'Start/End Dates', value: 'hhd', sortable: false, align: 'center', class: 'tableTitle' },
          { text: 'Num. Days', value: 'vhd', sortable: false, align: 'center', class: 'tableTitle' },
          { text: 'Quote', value: 'vhd', sortable: false, align: 'center', class: 'tableTitle' },
          { text: 'Executed Sums', value: 'vhd', sortable: false, align: 'center', class: 'tableTitle' },
          { text: 'Scheduled Sums', value: 'vhd', sortable: false, align: 'center', class: 'tableTitle' }
        ],
        location_schedule_headers: [
          {
            text: 'Status',
            align: 'left',
            sortable: false,
            value: 'code',
            class: 'tableTitle'
          },
          { text: 'Start/End Dates', value: 'hhd', sortable: false, align: 'center', class: 'tableTitle' },
          { text: 'Num. Days', value: 'vhd', sortable: false, align: 'center', class: 'tableTitle' },
          { text: 'Quote', value: 'vhd', sortable: false, align: 'center', class: 'tableTitle' },
          { text: 'Executed Sums', value: 'vhd', sortable: false, align: 'center', class: 'tableTitle' },
          { text: 'Scheduled Sums', value: 'vhd', sortable: false, align: 'center', class: 'tableTitle' },
          { text: 'Controls', value: 'vhd', sortable: false, align: 'center', class: 'tableTitle' }
        ],
        schedules: null,
        selectedTab: 0,
        errorSnackbar: false,
        errorMessage: ''
      }
    },
    methods: {
      showLocationsClick () {
        if (this.showLocations) {
          this.showLocations = false
        } else {
          this.showSchedules = false
          this.showLocations = true
        }
      },
      showSchedulesClick () {
        if (this.showSchedules) {
          this.showSchedules = false
        } else {
          this.showLocations = false
          this.showSchedules = true
        }
      },


      getCampaignHostStatus (status) {
        if (status === 1) {
          return 'Active'
        } else if (status === 2) {
          return 'Inactive'
        } else if (status === 3){
          return 'Deleted'
        }
        return 'Unknown'
      },
      getMediaApprovalStatus (status) {
        if (status === 1) {
          return 'Not Checked'
        } else if (status === 2) {
          return 'Valid'
        } else if (status === 3) {
          return 'Invalid'
        }
        return 'Unknown'
      },
      getCampaignsMediaAuthorizationStatus (status) {
        if (status === 1) {
          return 'No Status'
        } else if (status === 2) {
          return 'Pending Review'
        } else if (status === 3) {
          return 'Authorized'
        } else if (status === 4) {
          return 'Rejected'
        }
      },
      getInvalidMediaIssue (status) {
        if (status === 1) {
          return 'No Issues'
        } else if (status === 2) {
          return 'Media Tag Issue'
        } else if (status === 3) {
          return 'Media Category Issue'
        } else if (status === 4) {
          return 'Rejected'
        } else if (status === 5) {
          return 'Bad Aspect'
        } else if (status === 6) {
          return 'Media quality class is not allowed to selected site'
        } else if (status === 7) {
          return 'Client class is not allowed to selected site'
        }
        return 'Unknown'
      },
      openMedia () {
        this.mediaDialog = true
      },
      getTabStyle (pos) {
        var activePos = parseInt(this.selectedTab)
        if (pos === activePos) {
          return 'text-transform: none; color: #BF360C; font-weight: bold;'
        }
        return 'text-transform: none; color: #919191;'
      },
      getCampaignData () {
        this.isLoading = true
        this.$http.get(this.$store.getters.pubsServer + '/api/publishers/partners/campaigns/' + this.campaignId + '/', {
          headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        })
          .then(
            (res) => {
              this.isLoading = false
              this.selectedCampaign = res.data
            }
          )
          .catch((error) => {
            this.isLoading = false
            console.log(error)
            if (error.status === this.unauthorized) {
              this.showSessionExpired()
            }
          })
             this.$http.get(this.$store.getters.pubsServer + '/api/publishers/partners/campaigns/hosts/schedules/?campaign_id=' + this.campaignId , {
          headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        })
          .then(
            (res) => {
              this.isLoading = false
              this.schedules = res.data
            }
          )
          .catch((error) => {
            this.isLoading = false
            console.log(error)
         
          })
      },
      deactivateCampaign () {
        this.isLoading = true
        this.$http.delete(this.$store.getters.pubsServer+ '/api/web_client/partners/campaigns/' + this.campaignId + '/', {
          headers: {
                    Authorization: 'Token ' + this.$store.getters.token
          }
        })
          .then(
            (res) => {
              this.isLoading = false
           
         
            }
          )
          .catch((error) => {
            this.isLoading = false
            console.log(error)
            if (error.status === this.unauthorized) {
              this.showSessionExpired()
            }
            this.errorMessage = this.getErrorFromResponse(error)
            this.errorSnackbar = true
          })
      },
      getCampaignSchedules () {
        this.isLoading = false
      },
      reloadCampaign () {
        this.getCampaignData()
        this.getCampaignSchedules()
      },
    
      addSchedule (host) {
        this.editedHostPos = this.selectedCampaign.campaign_hosts.indexOf(host)
        this.editedSchedulePos = -1
        this.createScheduleDialog = true
      },
      addScheduleForSelectHostDialog () {
   
        this.editedHostPos = this.selectedCampaignHost
        this.editedSchedulePos = -1
        this.createScheduleDialog = true
        this.selectCampaignLocationDialog = false
        this.selectedCampaignHost = null
      },
      temp () {
        if (this.selectedCampaignHost !== null) {
          this.editedHostPos = this.selectedCampaign.campaign_hosts.indexOf(this.selectedCampaignHost)
          this.selectedCampaignHost = null
          this.editedSchedulePos = -1
          this.createScheduleDialog = true
        }
      },
      prepareScheduleDeletion (host, schedule, schedulesType) {
        this.editedHostPos = this.selectedCampaign.campaign_hosts.indexOf(host)
        this.editedScheduleType = schedulesType
        if (schedulesType === 'pending') {
          this.editedSchedulePos = this.selectedCampaign.campaign_hosts[this.editedHostPos].pending_schedules.indexOf(schedule)
        } else if (schedulesType === 'active') {
          this.editedSchedulePos = this.selectedCampaign.campaign_hosts[this.editedHostPos].active_schedules.indexOf(schedule)
        } else if (schedulesType === 'history') {
          this.editedSchedulePos = this.selectedCampaign.campaign_hosts[this.editedHostPos].history_schedules.indexOf(schedule)
        }
        this.edited_schedule = Object.assign({}, schedule)
        this.warningScheduleDeletionDialog = true
      },
      restoreScheduleData () {
        this.editedScheduleType = null
        this.editedSchedulePos = -1
        this.editedHostPos = -1
        this.edited_schedule = Object.assign({}, this.default_schedule)
      },
      deleteSchedule () {
        var url = this.publishersUrl + '/api/web_client/partners/campaigns/hosts/'
        var schedule = null
        if (this.editedScheduleType === 'pending') {
          schedule = this.selectedCampaign.campaign_hosts[this.editedHostPos].pending_schedules[this.editedSchedulePos].schedule_id
        } else if (this.editedScheduleType === 'active') {
          schedule = this.selectedCampaign.campaign_hosts[this.editedHostPos].active_schedules[this.editedSchedulePos].schedule_id
        } else if (this.editedScheduleType === 'history') {
          schedule = this.selectedCampaign.campaign_hosts[this.editedHostPos].history_schedules[this.editedSchedulePos].schedule_id
        }
        url += 'schedules/' + schedule + '/'
        this.isLoading = true
        this.$http.delete(url, {
          headers: {
            Authorization: 'Token ' + this.authToken
          }
        })
          .then(
            (res) => {
              this.isLoading = false
              this.restoreScheduleData()
              this.reloadCampaign()
            }
          )
          .catch((error) => {
            this.isLoading = false
            console.log(error)
            if (error.status === this.unauthorized) {
              this.showSessionExpired()
            }
            this.errorMessage = this.getErrorFromResponse(error)
            this.errorSnackbar = true
          })
      }
    },
    created () {
      if (this.campaignId !== 0 && this.campaignId !== undefined ) {
        this.getCampaignData()
        this.getCampaignSchedules()
      }
    }
  }
</script>
