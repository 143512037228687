<template>
  <v-content class="py-0">
    <loading-dialog v-model="isLoading"></loading-dialog>
    <error-snackbar v-model="isError" :errorMessage="errorMessage"></error-snackbar>
    <success-snackbar v-model="isSuccess" :message="successMsg"></success-snackbar>
    <v-dialog v-model="isOpen" max-width="500px">
      <v-card>
        <v-card-title class="font-15">
          {{ dialog_title }}
        </v-card-title>
        <v-card-text class="py-2">
          <v-select
            label="Request Status *"
            :items="status_choices"
            v-model="final_request_object.to_accept_registration"
            dense
            hide-details
            outlined
            >    
          </v-select>
          <v-checkbox
            class="mt-2"
            label="Allow trial"
            v-model="final_request_object.to_allow_trial"
            hide-details
            dense
            >    
          </v-checkbox>
          <v-text-field
            class="mt-2"
            label="Admin Email"
            v-model="final_request_object.admin_email"
            hide-details
            dense
            outlined
            >    
          </v-text-field>
          <v-text-field
            class="mt-2"
            label="Partner name"
            v-model="final_request_object.partner_name"
            hide-details
            dense
            outlined
            >    
          </v-text-field>
          <v-text-field
            class="mt-2"
            label="Partner short name"
            v-model="final_request_object.partner_short_name"
            hide-details
            dense
            outlined
            >    
          </v-text-field>
          <v-select
            class="mt-2"
            label="Location venue"
            :items="venues"
            item-text="name"
            item-value="venue_id"
            v-model="final_request_object.location_venue"
            dense
            hide-details
            outlined
            >    
          </v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small @click="isOpen = false">
            Cancel
          </v-btn>
          <v-btn text small :disabled="!form_valid" @click="processRequest">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-content>
</template>

<script>
  export default {
    props: {
      value: Boolean,
      registrationRequest: Object
    },
    watch: {
      isOpen (value) {
        if (value) {
          this.final_request_object = Object.assign({}, this.default_request_object)
          this.final_request_object.location_venue = this.registrationRequest.venue
          this.final_request_object.partner_name = this.registrationRequest.location_name
        }
      }
    },
    data () {
      return {
        isLoading: false,
        status_choices: [
          {text: 'Approved', value: true},
          {text: 'Denied', value: false}
        ],
        default_request_object: {
          to_accept_registration: false,
          to_create_admin_user: true,
          to_allow_trial: true,
          admin_email: '',
          partner_name: '',
          partner_short_name: '',
          location_venue: null,
          sub_category: null
        },
        final_request_object: {
          to_accept_registration: false,
          to_create_admin_user: true,
          to_allow_trial: true,
          admin_email: '',
          partner_name: '',
          partner_short_name: '',
          location_venue: null,
          sub_category: null
        },
        venues: [],
        isError: false,
        errorMessage: '',
        isSuccess: false,
        successMsg: ''
      }
    },
    computed: {
      isOpen: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      },
      dialog_title () {
        return 'Process Registration Request'
      },
      form_valid () {
        var isValid = this.final_request_object.location_venue !== null
        return isValid
      }
    },
    methods: {
      getVenues () {
        this.isLoading = true
        this.$http.get(this.$store.getters.pubsServer + '/api/hosts_public/location_venues/', { headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }).then(response => {
          this.isLoading = false
          this.venues = response.body
        }, error => {
          this.isLoading = false
          if (error.status === this.unauthorized) {
            
          }
        })
      },
      processRequest () {
        this.isLoading = true
        var auth_header = {
          headers: {
            Authorization: 'Token ' + this.$store.getters.token
          }
        }
        var final_data = Object.assign({}, this.final_request_object)
        if (this.final_request_object.admin_email.length === 0) {
          delete final_data.admin_email
        }
        if (this.final_request_object.partner_name.length === 0) {
          delete final_data.partner_name
        }
        if (this.final_request_object.partner_short_name.length === 0) {
          delete final_data.partner_short_name
        }
        if (this.final_request_object.sub_category === null) {
          delete final_data.sub_category
        }
        this.$http.put(this.$store.getters.pubsServer + '/api/publishers/partners/registrations/process_request/' + this.registrationRequest.id + '/', final_data, auth_header).then(response => {
          this.isLoading = false
          this.$emit('done')
          this.isOpen = false
        }, error => {
          this.isLoading = false
          this.errorMessage = 'Something wrong happened'
          this.isError = true
        })
      }
    },
    created () {
      this.getVenues()
    }
  }
</script>